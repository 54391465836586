import { dateFieldFormat, toUSD } from '../../lib/helpers';

export enum DiscoverTab {
  POPULAR = 'POPULAR',
}

export type TabProps = {
  label: string;
  key: string;
};

export const tabs: TabProps[] = [
  {
    label: 'Popular',
    key: DiscoverTab.POPULAR.toString(),
  },
];

export const columns = [
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'video',
    editable: false,
  },
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    editable: false,
  },
  {
    title: 'Listing',
    dataIndex: 'listingId',
    key: 'listingId',
    editable: false,
  },
  {
    title: 'Avail',
    dataIndex: 'availableAt',
    key: 'availableAt',
    render: (value: string) => (value ? dateFieldFormat(value) : ''),
    editable: false,
  },
  {
    title: 'Rent',
    dataIndex: 'monthlyRent',
    key: 'monthlyRent',
    render: (value: number) => toUSD(value),
    editable: false,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    editable: false,
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    editable: false,
  },
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    editable: true,
    required: true,
    message: 'Please add rank',
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
    editable: false,
    key: 'edit',
  },
];

export const DEFAULT_VIDEO_WIDTH = 150;
export const DEFAULT_VIDEO_HEIGHT = 250;
export const MAX_VIDEO_WIDTH = 200;
export const MAX_DEFAULT_VIDEO_HEIGHT = 250;
export const MIN_VIDEO_WIDTH = 100;
export const MIN_VIDEO_HEIGHT = 250;
