import { apiClient } from '../../hooks/useServices';
import { ExternalListingContactResponse } from '../models';

export type ExternalListingContactService = {
  byListingIds: (
    listingIds: string[]
  ) => Promise<ExternalListingContactResponse[]>;
};

export const externalListingContactServiceFactory = (
  apiClient: apiClient
): ExternalListingContactService => ({
  byListingIds: (
    listingIds: string[]
  ): Promise<ExternalListingContactResponse[]> =>
    apiClient<ExternalListingContactResponse[]>(
      `/atlas/external-listing-contacts/by-listing-ids?listing_ids=${listingIds.join(
        '&listing_ids='
      )}`,
      {
        method: 'GET',
      }
    ),
});
