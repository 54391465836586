import { InputNumber, Slider } from 'antd';
import React, { useState } from 'react';
import styled from '@emotion/styled';

const SliderHeight = 30;

type SliderInputProps = React.HTMLProps<HTMLInputElement> & {
  initialValue?: number;
  from: number;
  to: number;
  step: number;
  onChangeCallback: (value: number) => void;
};

const SliderInput = React.forwardRef<HTMLInputElement, SliderInputProps>(
  (
    {
      from,
      to,
      initialValue,
      step,
      onChangeCallback,
      id,
      disabled,
      required,
      ...inputProps
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(initialValue);

    const onSliderChange = (value: number) => {
      if (isNaN(value)) {
        return;
      }
      setInputValue(value);
      onChangeCallback(value);
    };

    return (
      <SliderInputWrapper>
        <SliderWrapper>
          <StyledSlider
            // See global styles
            ref={ref}
            id={id}
            tooltipPrefixCls={'ant-tooltip slider-tooltip'}
            tooltipVisible={true}
            tooltipPlacement={'bottom'}
            tipFormatter={(value) => <SliderToolTip>{value}</SliderToolTip>}
            min={from}
            max={to}
            value={typeof inputValue === 'number' ? inputValue : 0}
            step={step}
            dots={true}
            onChange={onSliderChange}
            disabled={disabled}
          />
        </SliderWrapper>
        <SliderInputNumberWrapper>
          <InputNumber
            id={id}
            className="slider-input-number"
            min={from}
            max={to}
            style={{ margin: '0 16px' }}
            step={step}
            value={inputValue}
            onChange={onSliderChange}
            required={required}
          />
        </SliderInputNumberWrapper>
      </SliderInputWrapper>
    );
  }
);

export { SliderInput };

const SliderInputWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;
const SliderInputNumberWrapper = styled.div`
  width: 60px;
  position: relative;

  .slider-input-number {
    width: 100% !important;
  }
`;
const StyledSlider = styled(Slider)`
  height: ${SliderHeight}px !important;
  width: 100%;
  padding: 4px;

  .ant-slider-rail {
    background: rgb(249, 249, 249) !important;
  }

  .ant-slider-rail,
  .ant-slider-step {
    height: ${SliderHeight / 2}px !important;
    border-radius: 5px;
    border-color: #f9f9f9;
    background-color: none !important;
  }

  .ant-slider-handle {
    border: 3px solid white !important;
    background: white !important;
    box-shadow: inset 0px 1.05882px 3.17647px rgb(92 67 193 / 14%);
    width: ${SliderHeight}px;
    height: ${SliderHeight}px;
    margin-top: -${SliderHeight / 4}px;
  }

  .ant-slider-track,
  .ant-slider-track {
    background-color: none !important;
    background-image: linear-gradient(to right, #ffffff, #05d6c9);
    height: ${SliderHeight / 2}px;
  }

  .ant-slider:hover .ant-slider-track {
    background-color: #05d6c9 !important;
  }
  // Dots
  .ant-slider-step {
    .ant-slider-dot {
      top: 0;
      bottom: 0;
      margin: auto 0;
      background: #f2f2f2;
      box-shadow: inset 0px 1.75px 1.75px rgb(92 67 193 / 14%);
      width: ${SliderHeight / 3}px;
      height: ${SliderHeight / 3}px;

      &.ant-slider-dot-active {
        border-color: transparent !important;
        display: none;
      }
    }
  }
`;

const SliderToolTip = styled.span`
  background-color: none !important;
  color: ${({ theme }) => theme.colorMap.neutral.black};
  font-size: 0.75rem;
  font-weight: 800;
`;
