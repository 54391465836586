import type { apiClient } from '../../hooks/useServices';
import { ExternalListingModel, PaginatedResponse } from '../models';

export type JobResponse = {
  status: string;
};

export type ImportExternalListingsRequest = {
  user_id: string;
  city: string;
  state_code: string;
};

export type UpdateExternalListingsRequest = {
  user_id: string;
  exclude_internally_updated_listings_in_last_hours: Long;
  close_listings_without_external_update_in_last_hours: Long;
};

export type ExternalListingFilter = {
  hasMatterport?: boolean;
  offset: number;
  limit: number;
};

export type LocationOption = {
  city: string;
  stateCode: string;
};

export type ExternalListingService = {
  importListings: (body: ImportExternalListingsRequest) => Promise<JobResponse>;
  updateListings: (body: UpdateExternalListingsRequest) => Promise<JobResponse>;
  locationOptions: () => Promise<LocationOption[]>;
  filter: (
    body: ExternalListingFilter
  ) => Promise<PaginatedResponse<ExternalListingModel>>;
};

export const externalListingServiceFactory = (
  apiClient: apiClient
): ExternalListingService => ({
  importListings: (body: ImportExternalListingsRequest): Promise<JobResponse> =>
    apiClient<JobResponse>('/atlas/external-listings/import', {
      method: 'POST',
      body,
    }),
  updateListings: (body: UpdateExternalListingsRequest): Promise<JobResponse> =>
    apiClient<JobResponse>('/atlas/external-listings/update', {
      method: 'POST',
      body,
    }),
  locationOptions: (): Promise<LocationOption[]> =>
    apiClient<LocationOption[]>('/atlas/external-listings/location-options', {
      method: 'GET',
    }),
  filter: (
    body: ExternalListingFilter
  ): Promise<PaginatedResponse<ExternalListingModel>> =>
    apiClient<PaginatedResponse<ExternalListingModel>>(
      '/atlas/external-listings/filter',
      {
        method: 'GET',
        body: {
          has_matterport: body.hasMatterport,
          offset: body.offset,
          limit: body.limit,
        },
      }
    ),
});
