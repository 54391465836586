import React, { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Navbar, Nav } from 'react-bootstrap';
import {
  SideNavBarWrapper,
  SideNavBarLogoWrapper,
  SideNavBarCloseMenu,
  SideNavBarAtlasLogo,
  SideNavBarSection,
  SideNavBarSectionLabel,
  SideNavBarLinks,
  SideNavBarLinkIcon,
  SideNavBarLinkLabel,
  SideNavBarLink,
  SideNavBarDockIcon,
  MidnightGreen,
  SideNavBarUserSection,
  UserInitialsContainer,
  UserInitialsContent,
  UserNameContent,
} from './components';
import { Icon } from '../../components/UI/Icon';
import { capitalize } from '../../lib/helpers';
import { useMediaQuery, useSideNavBar, useConfig, useAuth } from '../../hooks';
import { UserModel } from '../../lib/models';
import { navLinks } from './constants';
import { useLocation } from 'react-router';

type NavLink = {
  active: boolean;
  path: string;
  label: string;
  icon: any;
  target?: string;
};

const navBarBottomStyle = () => {
  return {
    width: '100%',
    fontSize: '0.75rem',
    zIndex: 2,
    height: '100px',
    backgroundColor: `${MidnightGreen}`,
  };
};

const navBarStyle = (width) => {
  return {
    width: width,
    zIndex: 2,
    height: '100%',
    minHeight: '100vh',
    overflow: 'scroll',
    backgroundColor: `${MidnightGreen}`,
  };
};

const navBarBrandStyle = {
  width: '100%',
  boxShadow: '0px 3px 0px rgb(255 255 255 / 11%)',
  padding: '0',
  margin: '0',
  height: '100px',
};

const userInitials = (user: UserModel) => {
  if (user.firstName && user.lastName) {
    return `${user.firstName.substring(0, 1).toUpperCase()}${user.lastName
      .substring(0, 1)
      .toUpperCase()}`;
  } else if (user.firstName) {
    return `${user.firstName.substring(0, 1).toUpperCase()}`;
  } else {
    return '';
  }
};

const LogoutIcon = Icon('logout');

const SideNav: React.FC = () => {
  const { mediaQuery } = useTheme();
  const { user } = useConfig();
  const { logout } = useAuth();
  const minWidthMediaQuery = useMediaQuery(mediaQuery.medium);
  const location = useLocation();
  const {
    isCollapsed,
    showLabel,
    collaspeMenu,
    handleShowLabel,
    sideBarWidth,
  } = useSideNavBar();

  useEffect(() => {
    handleShowLabel(minWidthMediaQuery);
  }, [minWidthMediaQuery, isCollapsed]);

  const renderLink = (link: NavLink, showDock: boolean) => {
    const LinkIcon = link.icon;
    const active = location.pathname === link.path;
    return (
      <SideNavBarLink
        href={link.path}
        key={link.label}
        target={link.target || ''}
      >
        {LinkIcon && (
          <SideNavBarLinkIcon active={active} isCollapsed={isCollapsed}>
            <LinkIcon />
          </SideNavBarLinkIcon>
        )}
        {showLabel && (
          <SideNavBarLinkLabel active={active}>
            {link.label}
          </SideNavBarLinkLabel>
        )}
        {active && showDock && <SideNavBarDockIcon />}
      </SideNavBarLink>
    );
  };

  const SideNavBarBottom = () => (
    <Navbar
      style={navBarBottomStyle()}
      fixed={'bottom'}
      variant="dark"
      className={'bottom-0 start-0 d-flex flex-column'}
    >
      {/* SideBar Nav Content */}
      <Nav
        style={{ width: '100%' }}
        className={'d-flex flex-row align-items-start justify-content-around'}
      >
        {
          /* SideBar Nav Links */
          navLinks.map((link, i) => (
            <SideNavBarSection key={`${i}`} isCollapsed={isCollapsed}>
              <SideNavBarLinks>
                {(link.subLinks as Array<NavLink>).map((link) =>
                  renderLink(link, false)
                )}
              </SideNavBarLinks>
            </SideNavBarSection>
          ))
        }
      </Nav>
    </Navbar>
  );

  const SideNavBar = () => (
    <SideNavBarWrapper>
      {/* Side NavBar */}
      <Navbar
        style={navBarStyle(sideBarWidth)}
        fixed={'top'}
        variant="dark"
        className={`top-0 start-0 d-flex flex-column align-items-start`}
      >
        {/* Atlas Brand */}
        <Navbar.Brand style={navBarBrandStyle}>
          <SideNavBarLogoWrapper>
            {!isCollapsed && <SideNavBarAtlasLogo />}
            <SideNavBarCloseMenu onClick={() => collaspeMenu(!isCollapsed)} />
          </SideNavBarLogoWrapper>
        </Navbar.Brand>
        {/* SideBar Nav Content */}
        <Nav style={{ width: '100%' }} className={`d-flex flex-column`}>
          {
            /* SideBar Nav Links */
            navLinks.map((link, i) => (
              <SideNavBarSection key={`${i}`} isCollapsed={isCollapsed}>
                {!isCollapsed && link.label !== '' ? (
                  <SideNavBarSectionLabel>{link.label}</SideNavBarSectionLabel>
                ) : (
                  ''
                )}
                <SideNavBarLinks>
                  {(link.subLinks as Array<NavLink>).map((link) =>
                    renderLink(link, true)
                  )}
                </SideNavBarLinks>
              </SideNavBarSection>
            ))
          }
        </Nav>
        <SideNavBarUserSection>
          <UserInitialsContainer>
            <UserInitialsContent>
              {user ? userInitials(user) : ''}
            </UserInitialsContent>
          </UserInitialsContainer>
          <UserNameContent>
            {capitalize(user?.firstName || '') +
              ' ' +
              capitalize(user?.lastName || '')}
          </UserNameContent>
          <div style={{ cursor: 'pointer' }}>
            <LogoutIcon onClick={logout} />
          </div>
        </SideNavBarUserSection>
      </Navbar>
    </SideNavBarWrapper>
  );

  return minWidthMediaQuery ? <SideNavBar /> : <SideNavBarBottom />;
};

export default SideNav;
