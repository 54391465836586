import { useEffect, useState } from 'react';
import { ListingDetailsModel, MessageResponseModel } from '../lib/models';

type UseMessagesHook = {
  limit: number;
  offset: number;
  messages: Array<MessageResponseModel>;
  selectedMessage: MessageResponseModel | null;
  viewedMessageIds: Set<string>;
  listing: ListingDetailsModel | null;
  handlers: {
    handleMessages;
    handleListing;
    selectMessage;
    archiveMessage;
    incrementOffset;
  };
};

type MessageSettings = {
  viewedMessages: string[];
};

const setLocalStorage = (viewedMessages: string[]) => {
  localStorage.setItem('messages', JSON.stringify({ viewedMessages }));
};

const getLocalStorage = (): MessageSettings | null => {
  const savedSettings = localStorage.getItem('messages');
  if (!savedSettings) {
    return null;
  } else {
    return JSON.parse(savedSettings);
  }
};

const useMessages = (queryParams: {
  offset?: number;
  limit?: number;
}): UseMessagesHook => {
  const [limit, setLimit] = useState<number>(queryParams.limit || 12);
  const [offset, setOffset] = useState<number>(queryParams.offset || 0);
  const [messages, setMessages] = useState<Array<MessageResponseModel>>([]);
  const [selectedMessage, setSelectedMessage] =
    useState<MessageResponseModel | null>(null);
  const [viewedMessageIds, setViewedMessageIds] = useState<Set<string>>(
    new Set(getLocalStorage()?.viewedMessages || [])
  );
  const [listing, setListing] = useState<ListingDetailsModel | null>(null);

  useEffect(() => {
    setLocalStorage(Array.from(viewedMessageIds));
  }, [viewedMessageIds.size]);

  const archiveMessage = (id) => {
    const updatedMessages = messages.filter(
      (message) => message.message.id !== id
    );
    setMessages(updatedMessages);
    setSelectedMessage(updatedMessages[0]);

    if (updatedMessages.length === 0) {
      incrementOffset();
    }
  };

  const selectMessage = (id) => {
    const message = messages.find((message) => message.message.id === id);
    if (message) {
      setSelectedMessage(message);
      setViewedMessageIds(viewedMessageIds.add(id));
    }
  };

  const handleMessages = (messages) => {
    setMessages(messages);

    if (messages.length === 0) {
      incrementOffset();
    }
  };

  const handleListing = (listing) => {
    setListing(listing);
  };

  const incrementOffset = () => setOffset(limit + offset);

  return {
    limit,
    offset,
    messages,
    selectedMessage,
    viewedMessageIds,
    listing,
    handlers: {
      handleMessages,
      handleListing,
      selectMessage,
      archiveMessage,
      incrementOffset,
    },
  };
};

export { useMessages };
