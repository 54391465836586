import styled from '@emotion/styled';

export const SettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 1.5rem;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-direction: row;
  }
`;

export const SettingsCol = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const SettingsSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 563.5px;
  height: 78px;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorMap.gray.gray4};
  margin-bottom: 2rem;
`;

export const SectionLabel = styled.div`
  color: ${({ theme }) => theme.colorMap.gray.gray2};
  font-weight: 700;
`;

export const SectionValue = styled.div`
  color: ${({ theme }) => theme.colorMap.gray.gray2};
`;

export const AuthToken = styled.div`
  width: 327px;
  height: 248px;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colorMap.gray.gray4};
  border-radius: 16px;
  position: relative;
`;
