import type { apiClient } from '../../hooks/useServices';
import {
  ListingUpdateBody,
  ListingCreateBody,
  ListingModel,
  ListingCloseBody,
  ListingApproveBody,
} from '../models';

export type ListingService = {
  approveListing: (body: ListingCloseBody) => Promise<ListingModel>;
  byUserIds: (userIds: string[]) => Promise<ListingModel>;
  closeListing: (body: ListingCloseBody) => Promise<ListingModel>;
  createListing: (body: ListingCreateBody) => Promise<ListingModel>;
  updateListing: (body: ListingUpdateBody) => Promise<ListingModel>;
  listingTypeOptions: () => Promise<string[]>;
};

export const listingServiceFactory = (
  apiClient: apiClient
): ListingService => ({
  approveListing: (body: ListingApproveBody): Promise<ListingModel> =>
    apiClient<ListingModel>(`atlas/listing/approve`, {
      method: 'PUT',
      body,
    }),
  byUserIds: (userIds: string[]): Promise<ListingModel> =>
    apiClient<ListingModel>(
      `/atlas/listing/by-user-ids?user_ids=${userIds.join('&user_ids=')}`,
      {
        method: 'GET',
      }
    ),
  closeListing: (body: ListingCloseBody): Promise<ListingModel> =>
    apiClient<ListingModel>(`atlas/listing/close`, {
      method: 'PUT',
      body,
    }),
  createListing: (body: ListingCreateBody): Promise<ListingModel> =>
    apiClient<ListingModel>('/atlas/listing/create', {
      method: 'POST',
      body,
    }),
  updateListing: (body: ListingUpdateBody): Promise<ListingModel> =>
    apiClient<ListingModel>('/atlas/listing/update', {
      method: 'PUT',
      body,
    }),
  listingTypeOptions: (): Promise<string[]> =>
    apiClient<string[]>('/atlas/listing/listing-types', {
      method: 'GET',
    }),
});
