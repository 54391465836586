import styled from '@emotion/styled/macro';
import GoogleMap, { Circle } from 'google-map-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteProps } from 'react-router';
import { Button } from '../../components/UI/Button';
import { Card } from '../../components/UI/Card';
import { useService } from '../../hooks';
import { DEFAULT_QUERY_CACHE_STALE_TIME } from '../../lib/constants';
import { queryParamsParser } from '../../lib/helpers';
import {
  ListingDetailsModel,
  PaginatedResponse,
  QrCode,
} from '../../lib/models';
import AppPage from '../AppPage';
import ListingMarker from './ListingMarker';
import MapMarker from './RadiusLayer';
import QrCodeMapMarker from './QrCodeMarker';
import { DefaultIcon } from '../../components/UI/Icon';
import { calculateMetersPerPx, filters, getDistanceMeters } from './constants';

type QrMapViewProps = {
  lat: number;
  lng: number;
  zoom: number;
};

type MapQueryParams = {
  lat?: string;
  lng?: string;
  zoom?: number;
  showQrCodes?: string;
  showListings?: string;
};

const qrCodeOffset = 0;
const qrCodeLimit = 100;
const listingOffset = 0;
const listingLimit = 70;

const MapView: React.FC<RouteProps> = ({ location }) => {
  const {
    lat = '34.025052',
    lng = '-118.478094',
    zoom = 13,
    showQrCodes: defaultShowQrCodes,
    showListings: defaultShowListings,
  } = queryParamsParser(location?.search || '') as MapQueryParams;

  const parsedLat = parseFloat(lat);
  const parsedLng = parseFloat(lng);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [zoomLevel, setZoomLevel] = useState<number>(zoom);
  const [radiusMeters, setRadiusMeters] = useState<number>(10000);
  const [metersPerPx, setMetersPerPx] = useState<number>(
    calculateMetersPerPx(parsedLat, zoom)
  );
  const [latitude, setLatitude] = useState<number>(parsedLat);
  const [longitude, setLongitude] = useState<number>(parsedLng);

  const qrCodeService = useService<'qrCode'>('qrCode');
  const listingDetailsService = useService<'listingDetails'>('listingDetails');

  const [showQrCodes, setShowQrCodes] = useState<boolean>(
    defaultShowQrCodes === 'true'
  );
  const [showListings, setShowListings] = useState<boolean>(
    defaultShowListings === 'true'
  );

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'scroll';
    };
  });

  const callbackByFilterKey = (key: string) => {
    switch (key) {
      case 'listings':
        return setShowListings(!showListings);
      case 'signs':
        return setShowQrCodes(!showQrCodes);
      default:
        return;
    }
  };

  const isSelectedByFilterKey = (key: string) => {
    switch (key) {
      case 'listings':
        return showListings;
      case 'signs':
        return showQrCodes;
      default:
        return false;
    }
  };

  const buttons: ReactElement[] = filters.map((filter) => (
    <MapFilter
      className={`
        ${filter.disabled ? 'disabled' : ''} 
        ${isSelectedByFilterKey(filter.key) ? 'selected' : ''}
        ${filter.type}
      `}
      key={filter.key}
      onClick={() => callbackByFilterKey(filter.key)}
    >
      {filter.icon && <DefaultIcon name={filter.icon} />}
      {filter.label && <FilterLabel>{filter.label}</FilterLabel>}
    </MapFilter>
  ));

  const { data: qrCodes } = useQuery<{}, {}, PaginatedResponse<QrCode>>(
    [
      `qr-codes-by-proximity-${radiusMeters}`,
      { latitude, longitude, radiusMeters },
    ],
    () =>
      qrCodeService.byProximity(
        latitude,
        longitude,
        radiusMeters,
        qrCodeOffset,
        qrCodeLimit
      ),
    {
      enabled: showQrCodes,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_CACHE_STALE_TIME,
    }
  );

  const { data: listings } = useQuery<
    {},
    {},
    PaginatedResponse<ListingDetailsModel>
  >(
    [
      `listings-by-proximity-${radiusMeters}`,
      { latitude, longitude, radiusMeters },
    ],
    () =>
      listingDetailsService.byProximity(
        latitude,
        longitude,
        radiusMeters,
        new Date().toISOString(),
        listingOffset,
        listingLimit
      ),
    {
      enabled: showListings,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_CACHE_STALE_TIME,
    }
  );

  const renderQrCodes = () => {
    return (
      qrCodes &&
      qrCodes?.results.map((qrCode) => (
        <QrCodeMapMarker
          key={qrCode.code}
          title={qrCode.code}
          lat={qrCode.location?.latitude}
          lng={qrCode.location?.longitude}
        />
      ))
    );
  };

  const renderListings = () => {
    return (
      listings &&
      listings?.results.map((listing) => (
        <ListingMarker
          key={listing.listingId}
          title={listing.address.address1}
          listingId={listing.listingId}
          lat={listing.approximateLocation?.latitude}
          lng={listing.approximateLocation?.longitude}
        />
      ))
    );
  };

  const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  return (
    <AppPage>
      <Card
        padding={'0px'}
        buttons={buttons}
        buttonAlign={'left'}
        buttonContainerSize={'100%'}
        buttonSpacing={'space-evenly'}
      >
        <MapContainer>
          <GoogleMap
            onClick={({ lat, lng }) => {
              setLatitude(lat);
              setLongitude(lng);
              setMetersPerPx(calculateMetersPerPx(lat, zoom));
            }}
            onChange={({ center, zoom, bounds }) => {
              debounce(() => {
                setLatitude(center.lat);
                setLongitude(center.lng);
                setRadiusMeters(
                  getDistanceMeters(
                    bounds.ne.lat,
                    bounds.ne.lng,
                    center.lat,
                    center.lng
                  ) / 5
                );
                if (zoom !== zoomLevel) {
                  setZoomLevel(zoom);
                }
                setIsLoading(false);
              })();
              setMetersPerPx(calculateMetersPerPx(center.lat, zoom));
            }}
            resetBoundsOnResize={true}
            center={{
              lat: parsedLat,
              lng: parsedLng,
            }}
            zoom={zoom}
            options={{
              draggable: true,
              disableDefaultUI: true,
              keyboardShortcuts: true,
            }}
          >
            {!isLoading && (
              <MapMarker
                radiusPx={radiusMeters / metersPerPx}
                lat={latitude}
                lng={longitude}
              />
            )}
            {showQrCodes && renderQrCodes()}
            {showListings && renderListings()}
          </GoogleMap>
        </MapContainer>
      </Card>
    </AppPage>
  );
};

export default MapView;

const MapContainer = styled.div`
  height: calc(100vh - 194px);
  width: 100%;
  position: relative;
`;

const StyledButton = styled(Button)`
  min-width: 120px;
`;

const MapFilter = styled.div`
  width: 80px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colorMap.gray.gray5};

  &.icon {
    padding: 1rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease;

    .svg {
      color: ${({ theme }) => theme.colorMap.neutral.black};
    }

    &:hover {
      background: ${({ theme }) => theme.colorMap.gray.gray5};
      cursor: pointer;
    }

    &.disabled {
      pointer-events: none;
      background-color: #f6f6f6;
      border-color: #f6f6f6;
      color: #afafaf;
    }

    &.selected {
      background: ${({ theme }) => theme.colorMap.neutral.black};
      color: ${({ theme }) => theme.colorMap.neutral.white};
    }
  }

  &.divider {
    width: 0px;
    padding: 0;
  }
`;

const FilterLabel = styled.span`
  margin-top: 0.5rem;
`;
