import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Card } from '../../components/UI/Card';
import { useService } from '../../hooks/useServices';
import { dateFormatFromString, groupBy } from '../../lib/helpers';
import { ListingModel, PaginatedResponse, UserModel } from '../../lib/models';
import AppPage from '../AppPage';
import { EditableTable, Table } from '../../components/UI/Table';
import { usersTableColumnHeaders } from './constants';
import Input from 'antd/lib/input/Input';
import { Form, Spin } from 'antd';
import { DefaultIcon } from '../../components/UI/Icon';
import { Button } from '../../components/UI/Button';
import { useOutsideClickEventByClassName } from '../../hooks';
import CopyDiv from '../../components/CopyDiv';

const EDITABLE_ROW_CLASS_NAME_BASE = 'editable-row';
const CACHE_KEY = 'users-by-pagination';

const Users = () => {
  const limit = 100;
  const [offset, setOffset] = useState<number>(0);
  const userService = useService<'user'>('user');
  const listingService = useService<'listing'>('listing');
  const [editableId, setEditableId] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const { data: pagintionResponse } = useQuery<
    {},
    {},
    PaginatedResponse<UserModel>
  >(
    [CACHE_KEY, { offset, limit }],
    () => userService.byPagination(offset, limit),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const userIds = pagintionResponse?.results?.map((user) => user.id) || [];

  const { data: listings } = useQuery<{}, {}, ListingModel[]>(
    [`listings-by-user-ids`, { userIds }],
    () => listingService.byUserIds(userIds),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const listingsByUserId: Map<string, ListingModel[]> = groupBy(
    listings || [],
    'userId'
  );

  const onFinish = async (values: any) => {
    setIsSubmitting(true);
    try {
      await form.validateFields();
    } catch {
      setIsSubmitting(false);
    }
    await userService.update(
      editableId,
      values.firstName,
      values.lastName,
      values.email
    );
    queryClient.invalidateQueries(CACHE_KEY);

    setTimeout(() => {
      setEditableId('');
      setIsSubmitting(false);
    }, 1000);
  };

  const EditButton: React.FC<{ user: UserModel }> = ({ user }) => {
    return (
      <div
        onClick={(evt) => {
          evt.stopPropagation();
          form.setFieldsValue({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          });
          setEditableId(user.id);
        }}
      >
        <DefaultIcon name="edit" />
      </div>
    );
  };

  const SaveButton: React.FC = ({}) => {
    const { clickedOutside } = useOutsideClickEventByClassName(
      `${EDITABLE_ROW_CLASS_NAME_BASE}-${editableId}`
    );

    useEffect(() => {
      if (clickedOutside) {
        setEditableId('');
      }
    }, [clickedOutside]);

    return (
      <UpdateButtonsContainer>
        <StyledButton
          type="submit"
          key="listing-create"
          size="small"
          variant="primary"
          color="primaryPink"
          onClick={form.submit}
        >
          Save
        </StyledButton>
      </UpdateButtonsContainer>
    );
  };

  const dataSource =
    pagintionResponse?.results?.map((user, idx) => {
      const listings: ListingModel[] = listingsByUserId[user.id] || [];
      const userListings =
        listings.length <= 0 ? (
          ''
        ) : (
          <a href={`/users/${user.id}?tab=listings`}>View</a>
        );

      const id = (
        <CopyDiv key={`copy-${user.id}`} value={user.id}>
          <a href={`/users/${user.id}?tab=details`}>{user.id.slice(0, 8)}</a>
        </CopyDiv>
      );

      const edit =
        editableId === user.id ? <SaveButton /> : <EditButton user={user} />;

      return {
        key: id,
        id: id,
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobileNumber: user.mobileNumber,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt,
        deletedAt: user.deletedAt
          ? dateFormatFromString(user.deletedAt)
          : undefined,
        listingsCount: listings.length,
        userListings: <ListingContainer>{userListings}</ListingContainer>,
        edit,
      };
    }) || [];

  const mergedColumns = usersTableColumnHeaders.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: UserModel & { userId: string }) => {
        return {
          record: record,
          inputNode: <Input />,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: editableId === record.userId,
          required: col.required,
          message: col.message,
        };
      },
    };
  });

  return (
    <AppPage>
      <Card title={'Users'}>
        <Spin className="unicorn-spinner" spinning={isSubmitting}>
          <Form onFinish={onFinish} form={form}>
            <EditableTable
              limit={limit}
              total={pagintionResponse?.total || 0}
              columns={mergedColumns}
              dataSource={dataSource}
              rowKey={'user_id'}
              rowClassName={(record) =>
                `${EDITABLE_ROW_CLASS_NAME_BASE}-${record.userId}`
              }
              onNext={() => setOffset(offset + limit)}
              onPrev={() => setOffset(offset - limit)}
            />
          </Form>
        </Spin>
      </Card>
    </AppPage>
  );
};

export default Users;

const ListingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;

const UpdateButtonsContainer = styled.div`
  display: flex;
  button {
    margin: 0 0.5rem;
  }
`;
