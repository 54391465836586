import { RouteProps } from 'react-router';
import { useEffect, useState } from 'react';
import { Button } from '../../components/UI/Button';
import { Card } from '../../components/UI/Card';
import AppPage from '../AppPage';
import {
  DashboardWrapper,
  DashboardContent,
  MetricsContainer,
  MetricContainer,
  Metric,
  MetricInfoContainer,
  MetricLabel,
  MetricIconContainer,
  MetricValue,
  Graph,
  InventorySnapshotContainer,
  InventorySnapshotTitle,
  InventoryCity,
  InventoryCityListingCount,
  ModeIfame,
} from './components';
import { useApiClient } from '../../hooks/index';
import { Icon } from '../../components/UI/Icon';

const MODE_URL = 'https://app.mode.com/rentunicorn/reports/f17065a197dc/embed';
// TODO: Replace with API Call
const defaultCitiesToTrackListings = (): string[] => {
  return [
    'Santa Monica, CA',
    'Venice, CA',
    'Los Angeles, CA',
    'Mar Vista, CA',
    'Culver City, CA',
  ];
};

enum MetricKind {
  TOTAL_USERS = 'TOTAL_USERS',
  ACTIVE_USERS_2HR = 'ACTIVE_USERS_2HR',
  NEW_USERS_24HR = 'NEW_USERS_24HR',
  TOTAL_LISTINGS = 'TOTAL_LISTINGS',
  ACTIVE_LISTINGS = 'ACTIVE_LISTINGS',
  ACTIVE_LISTINGS_PER_USER = 'ACTIVE_LISTINGS_PER_USER',
}

type MetricModel = {
  metric: MetricKind;
  label: string;
  value: string;
};

type MetricIcon = {
  wrapperColor: string;
  icon: any;
};

const iconByMetric = (metric: MetricKind): MetricIcon => {
  switch (metric.toUpperCase()) {
    case MetricKind.TOTAL_USERS:
    case MetricKind.NEW_USERS_24HR:
    case MetricKind.ACTIVE_USERS_2HR:
      return { wrapperColor: 'primaryPink', icon: Icon('profile') };
    case MetricKind.TOTAL_LISTINGS:
    case MetricKind.ACTIVE_LISTINGS:
    case MetricKind.ACTIVE_LISTINGS_PER_USER:
      return { wrapperColor: 'primaryGreen', icon: Icon('home') };
    default:
      return { wrapperColor: 'primaryPink', icon: Icon('refresh-location') };
  }
};

const Dashboard = (props: RouteProps) => {
  const [metrics, setMetrics] = useState<MetricModel[]>([]);
  const apiClient = useApiClient();

  const queryMetrics = async () => {
    const all = await apiClient(`/atlas/metrics/all`);
    setMetrics(all as MetricModel[]);
  };

  const refresh = () => queryMetrics();

  useEffect(() => {
    queryMetrics();
  }, []);

  const renderMetrics = (metrics: MetricModel[]) => {
    return (
      <MetricsContainer>
        {metrics.map((metric) => {
          const { icon: MetricIcon, wrapperColor } = iconByMetric(
            metric.metric
          );

          return (
            <MetricContainer>
              <Metric>
                <MetricIconContainer background={wrapperColor}>
                  <MetricIcon />
                </MetricIconContainer>
                <MetricInfoContainer>
                  <MetricValue>{metric.value}</MetricValue>
                  <MetricLabel>{metric.label}</MetricLabel>
                </MetricInfoContainer>
              </Metric>
            </MetricContainer>
          );
        })}
      </MetricsContainer>
    );
  };

  const renderInventoryByCity = (cities: string[]) => {
    return cities.map((city) => (
      <>
        <InventoryCity>
          {city}
          <InventoryCityListingCount>N/A</InventoryCityListingCount>
        </InventoryCity>
      </>
    ));
  };

  const renderModeIframe = () => (
    <DashboardContent>
      <p>
        Click{' '}
        <a
          css={{ fontWeight: 800 }}
          href="https://app.mode.com/rentunicorn/reports/f17065a197dc"
          target="_blank"
        >
          here
        </a>{' '}
        to view report on Mode
      </p>
      <ModeIfame
        width={'100%'}
        height={'300'}
        frameBorder={'0'}
        src={MODE_URL}
      />
    </DashboardContent>
  );

  const renderDashboardContent = () => (
    <DashboardContent>
      {metrics ? renderMetrics(metrics) : ''}
      <Graph />
      <InventorySnapshotContainer>
        <InventorySnapshotTitle>Inventory Snapshot</InventorySnapshotTitle>
        {renderInventoryByCity(defaultCitiesToTrackListings())}
      </InventorySnapshotContainer>
    </DashboardContent>
  );

  return (
    <AppPage>
      <DashboardWrapper>
        <Card style={{ textAlign: 'left' }} title="Dashboard">
          <Button
            style={{ position: 'absolute', top: '1.0rem', right: '1.0rem' }}
            size="small"
            variant="primary"
            color="primaryGreen"
            onClick={refresh}
          >
            Refresh
          </Button>
          {/* TODO - once we actually can query metriss renderDashboardContent() */}
          {/* Mode */}
          {renderModeIframe()}
        </Card>
      </DashboardWrapper>
    </AppPage>
  );
};

export default Dashboard;
