import { Button } from '../../components/UI/Button';
import {
  ListingDetailsModel,
  RentalRatingType,
  UserModel,
} from '../../lib/models';
import { useListingEditForm } from '../../hooks';
import styled from '@emotion/styled';
import {
  snakeCaseToDisplayName,
  dateFieldFormat,
  toUTC,
  dateInputFormat,
  addDays,
  capitalize,
} from '../../lib/helpers';
import { MultiSelect, Select } from '../../components/UI/Select';
import { Section, SectionRow, SectionLabel, SectionValue } from './components';

export type ListingMediaGalleryProps = {
  listing: ListingDetailsModel;
  user: UserModel;
  onSubmit: (updatedListing: ListingDetailsModel) => void;
  onCancel: () => void;
};

const ListingEdit = ({
  listing,
  user,
  onSubmit,
  onCancel,
}: ListingMediaGalleryProps) => {
  const {
    form,
    amenityOptions,
    rentalTypeOptions,
    listingTypeOptions,
    handlers,
  } = useListingEditForm(listing.listingId);
  const defaultAmenities = listing.amenities.map((a) => {
    return { label: snakeCaseToDisplayName(a.amenityKey), value: a.amenityKey };
  });

  return (
    <ListingEditWrapper>
      <Button
        onClick={onCancel}
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          zIndex: 1,
          marginRight: '3.0rem',
        }}
        size="small"
        variant="tertiary"
        color="primaryGreen"
      >
        Cancel
      </Button>
      <Button
        onClick={async (evt) => {
          evt.preventDefault();
          const listing = await handlers.submitForm();
          onSubmit(listing);
        }}
        style={{ position: 'absolute', top: '0', right: '0', zIndex: 1 }}
        size="small"
        variant="tertiary"
        color="primaryGreen"
      >
        Save
      </Button>
      {user && (
        <SectionRow>
          {/* Renter Name */}
          <Section>
            <SectionLabel>Renter</SectionLabel>
            <SectionValue>
              {user.firstName} {user.lastName}
            </SectionValue>
          </Section>
          {/* Mobile number */}
          <Section>
            <SectionLabel>Phone</SectionLabel>
            <SectionValue>{user.mobileNumber}</SectionValue>
          </Section>
        </SectionRow>
      )}
      <SectionRow>
        {/* Address */}
        <Section>
          <SectionLabel>Address</SectionLabel>
          <SectionValue>
            <SectionInputField
              name="address1"
              type="text"
              defaultValue={listing.address.address1}
              onChange={handlers.handleInputChange}
            />
            <SectionInputField
              name="address2"
              type="text"
              defaultValue={listing.address.address2}
              onChange={handlers.handleInputChange}
            />
            <SectionInputField
              name="city"
              type="text"
              defaultValue={listing.address.city}
              onChange={handlers.handleInputChange}
            />
            <SectionInputField
              name="state"
              type="text"
              defaultValue={listing.address.state}
              onChange={handlers.handleInputChange}
            />
            <SectionInputField
              name="postalCode"
              type="text"
              defaultValue={listing.address.postalCode}
              onChange={handlers.handleInputChange}
            />
          </SectionValue>
        </Section>
        {/* Title */}
        <Section>
          <SectionLabel>Title</SectionLabel>
          <SectionInputField
            name="title"
            type="text"
            defaultValue={listing.label}
            onChange={handlers.handleInputChange}
          />
        </Section>
        {/* Rental Type */}
        <Section>
          <SectionLabel>Rental Type</SectionLabel>
          <Select
            handleChange={(option) =>
              handlers.handleSelect(option, 'rentalType')
            }
            defaultValue={{
              label: capitalize(listing.rentalType),
              value: listing.rentalType,
            }}
            selectOptions={rentalTypeOptions}
          />
        </Section>
      </SectionRow>

      <SectionRow>
        {/* Monthly Rent */}
        <Section>
          <SectionLabel>Price</SectionLabel>
          <SectionInputField
            name="monthlyRent"
            type="number"
            defaultValue={listing.monthlyRent / 100}
            onChange={handlers.handleInputChange}
          />
        </Section>
        {/* Deposit */}
        <Section>
          <SectionLabel>Sqft</SectionLabel>
          <SectionInputField
            name="sqft"
            type="number"
            defaultValue={listing.sqft}
            onChange={handlers.handleInputChange}
          />
        </Section>
        {/* Listing Type */}
        <Section>
          <SectionLabel>Listing Type</SectionLabel>
          <Select
            handleChange={(option) =>
              handlers.handleSelect(option, 'listingType')
            }
            defaultValue={{
              label: capitalize(listing.listingType),
              value: listing.listingType,
            }}
            selectOptions={listingTypeOptions}
          />
        </Section>
      </SectionRow>

      <SectionRow>
        {/* Available */}
        <Section>
          <SectionLabel>Available</SectionLabel>
          <SectionInputField
            name="availableAt"
            type="date"
            defaultValue={dateInputFormat(listing.availableAt)}
            onChange={(evt) => handlers.handleAvailableAt(evt.target.value)}
          />
        </Section>
        {/* Lease Duration days */}
        <Section>
          <SectionLabel>End Date</SectionLabel>
          <SectionInputField
            name="endDate"
            type="date"
            onChange={(evt) =>
              handlers.handleLeaseDurationDays(
                form.availableAt || listing.availableAt,
                evt.target.value
              )
            }
          />
        </Section>
        {/* Description */}
        <Section>
          <SectionLabel>Description</SectionLabel>
          <SectionTextAreaField
            name="description"
            defaultValue={listing.description}
            onChange={handlers.handleInputChange}
          />
        </Section>
      </SectionRow>

      <SectionRow>
        {/* Beds */}
        <Section>
          <SectionLabel>Beds</SectionLabel>
          <SectionInputField
            name="numBedrooms"
            type="number"
            defaultValue={listing.numBedrooms}
            onChange={handlers.handleInputChange}
          />
        </Section>
        {/* Baths */}
        <Section>
          <SectionLabel>Bathrooms</SectionLabel>
          <SectionInputField
            name="numBathrooms"
            type="number"
            defaultValue={listing.numBathrooms}
            onChange={handlers.handleInputChange}
          />
        </Section>
        {/* Parking */}
        <Section>
          <SectionLabel>Parking</SectionLabel>
          <SectionInputField
            name="numParkingSpots"
            type="number"
            defaultValue={listing.numParkingSpots}
            onChange={handlers.handleInputChange}
          />
        </Section>
      </SectionRow>

      <SectionRow>
        {/* Landlord Rating */}
        <Section>
          <SectionLabel>Landlord</SectionLabel>
          <SectionInputField
            name="landlordRating"
            type="number"
            defaultValue={listing.ratings.landlordRating}
            onChange={(evt) =>
              handlers.handleRatings(
                RentalRatingType.LANDLORD,
                evt.target.value
              )
            }
          />
        </Section>
        {/* Light Rating */}
        <Section>
          <SectionLabel>Light</SectionLabel>
          <SectionInputField
            name="naturalLight"
            type="number"
            defaultValue={listing.ratings.naturalLight}
            onChange={(evt) =>
              handlers.handleRatings(
                RentalRatingType.LIGHT_LEVEL,
                evt.target.value
              )
            }
          />
        </Section>
        {/* Noise Rating */}
        <Section>
          <SectionLabel>Noise</SectionLabel>
          <SectionInputField
            name="noiseLevel"
            type="number"
            defaultValue={listing.ratings.noiseLevel}
            onChange={(evt) =>
              handlers.handleRatings(
                RentalRatingType.NOISE_LEVEL,
                evt.target.value
              )
            }
          />
        </Section>
      </SectionRow>

      <SectionRow>
        <Section>
          <SectionLabel>Amenities</SectionLabel>
          <MultiSelect
            handleChange={(amenities) =>
              handlers.handleAmenities(amenities.map((a) => a.value))
            }
            defaultValues={defaultAmenities}
            selectOptions={amenityOptions}
          />
        </Section>
      </SectionRow>
    </ListingEditWrapper>
  );
};

export default ListingEdit;

const ListingEditWrapper = styled.form`
  width: 100%;
`;

const SectionInputField = styled.input`
  width: 100%;
`;

const SectionTextAreaField = styled.textarea`
  width: 100%;
`;
