import { ListingStatus } from '../../../lib/models';
import { Tag } from 'antd';

const ListingStatusTag: React.FC<{ listingStatus: ListingStatus }> = ({
  listingStatus,
}) => {
  if (listingStatus === ListingStatus.CLOSED) {
    return <Tag color={'red'}>Closed</Tag>;
  } else if (listingStatus === ListingStatus.PENDING) {
    return <Tag color={'yellow'}>Pending</Tag>;
  } else {
    return <Tag color={'green'}>Active</Tag>;
  }
};

export default ListingStatusTag;
