import { useEffect, useState } from 'react';
import {
  ErrorMessage,
  ListingDetailsModel,
  ListingUpdateBody,
  RentalRatingType,
  SelectOption,
} from '../lib/models';
import { useService } from '../hooks';
import {
  addHours,
  daysBetween,
  stringsToSelectOptions,
  toUTC,
} from '../lib/helpers';
import { message } from 'antd';

type UseListingEditForm = {
  form;
  amenityOptions;
  rentalTypeOptions;
  listingTypeOptions;
  error;
  handlers: {
    handleInputChange;
    handleSelect;
    handleRatings;
    handleAmenities;
    handleAvailableAt;
    handleLeaseDurationDays;
    submitForm;
  };
};

const useListingEditForm = (listingId: string): UseListingEditForm => {
  const listingService = useService<'listing'>('listing');
  const listingDetailsService = useService<'listingDetails'>('listingDetails');
  const amenityService = useService<'amenity'>('amenity');
  const rentalService = useService<'rental'>('rental');

  const [form, setForm] = useState<ListingUpdateBody>({ listingId });
  const [amenityOptions, setAmenityOptions] = useState<SelectOption[]>([]);
  const [rentalTypeOptions, setRentalTypeOptions] = useState<SelectOption[]>(
    []
  );
  const [listingTypeOptions, setListingTypeOptions] = useState<SelectOption[]>(
    []
  );
  const [error, setError] = useState<ErrorMessage>();

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const change = {};
    change[name] = value;
    setForm({ ...form, ...change });
  };

  const handleSelect = (
    { _, value }: { _: string; value: string },
    name: string
  ) => {
    const change = {};
    change[name] = value;
    setForm({ ...form, ...change });
  };

  const handleRatings = (ratingType: RentalRatingType, rating: number) => {
    const ratings = form.ratings || [];
    const filteredRatings = ratings.filter(
      (rating) => rating.ratingType !== ratingType
    );
    const newRatings = {
      ratings: [...filteredRatings, { ratingType, rating }],
    };
    setForm({ ...form, ...newRatings });
  };

  const handleAmenities = (amenityKeys: string[]) => {
    const amenities = amenityKeys.map((amenityKey) => {
      return { amenityKey: amenityKey };
    });
    setForm({ ...form, ...{ amenities } });
  };

  const handleAvailableAt = (dateString: string) => {
    const dateUtc = addHours(new Date(dateString), 12).toISOString();
    setForm({ ...form, ...{ availableAt: dateUtc } });
  };

  const handleLeaseDurationDays = (
    dateString1: string,
    dateString2: string
  ) => {
    const date1Utc = toUTC(dateString1);
    const date2Utc = toUTC(dateString2);
    const days = daysBetween(new Date(date1Utc), new Date(date2Utc));
    setForm({ ...form, ...{ leaseDurationDays: days } });
  };

  const submitForm = async (): Promise<ListingDetailsModel> => {
    await listingService
      .updateListing(form)
      .catch((e) => {
        setError(e.message);
        message.error(e.message, 5, () => setError(undefined));
      })
      .then((res) => res);

    return await listingDetailsService
      .byId(form.listingId)
      .catch((e) => {
        setError(e.message);
        message.error(e.message, 5, () => setError(undefined));
        throw e;
      })
      .then((res) => res);
  };

  useEffect(() => {
    Promise.all([
      amenityService.amenityKeyOptions(),
      rentalService.rentalTypeOptions(),
      listingService.listingTypeOptions(),
    ])
      .then((all) => all.map((options) => stringsToSelectOptions(options)))
      .then(([amenityOptions, rentalTypes, listingTypes]) => {
        setAmenityOptions(amenityOptions);
        setRentalTypeOptions(rentalTypes);
        setListingTypeOptions(listingTypes);
      });
  }, [amenityService, rentalService, listingService]);

  return {
    form,
    amenityOptions,
    rentalTypeOptions,
    listingTypeOptions,
    error,
    handlers: {
      handleInputChange,
      handleSelect,
      handleRatings,
      handleAmenities,
      handleAvailableAt,
      handleLeaseDurationDays,
      submitForm,
    },
  };
};

export { useListingEditForm };
