import React, { useState } from 'react';
import { Form, Spin } from 'antd';
import { useService } from '../../hooks';
import { useQueryClient } from 'react-query';
import { QUERY_CACHE_KEY_QR_BY_PAGINATION } from './constants';
import CreateForm from './CreateForm';

const NewTab: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const qrCodeService = useService<'qrCode'>('qrCode');

  const onFinish = async (values: any) => {
    setIsSubmitting(true);

    const [latitude, longitude] = form.getFieldValue('location')
      ? form.getFieldValue('location').split(',')
      : [null, null];

    const location =
      latitude && longitude
        ? {
            latitude,
            longitude,
          }
        : undefined;

    await qrCodeService.create({
      code: form.getFieldValue('code'),
      redirectUrl: form.getFieldValue('redirectUrl'),
      location,
    });

    setTimeout(() => {
      setIsSubmitting(false);
      queryClient.invalidateQueries(QUERY_CACHE_KEY_QR_BY_PAGINATION);
    }, 1000);
  };

  return (
    <Spin spinning={isSubmitting}>
      <CreateForm form={form} onFinish={onFinish} />
    </Spin>
  );
};

export default NewTab;
