import styled from '@emotion/styled';
import { UnicornAtlas } from '../UI/Brand';
import { Icon } from '../UI/Icon';

export const MidnightGreen = '#03292F';

export const SideNavBarWrapper = styled.div`
  font-family: 'ZichtbaarRegular';
`;

export const SideNavBarLogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem;
`;

export const SideNavBarAtlasLogo = styled(UnicornAtlas)`
  margin: 0 auto;
  width: 70%;
  fill: ${({ theme }) => theme.colorMap.neutral.white};
`;

export const SideNavBarCloseMenu = styled(Icon('menu-close'))`
  margin-left: auto;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

export const SideNavBarSection = styled.div<{ isCollapsed: boolean }>`
  font-weight: 700;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1rem;
    display: block;
    width: 100%;
    padding: ${(props) => (props.isCollapsed ? '0rem 0.50rem' : '0rem 1.0rem')};

    &:first-of-type {
      margin-top: 1rem;
    }
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }
`;

export const SideNavBarSectionLabel = styled.div`
  display: none;
  opacity: 0.6;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    font-size: 1rem;
    display: flex;
    margin: 1rem 0rem;
  }
`;
export const SideNavBarLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SideNavBarLink = styled.a`
  color: ${({ theme }) => theme.colorMap.neutral.white} !important;
  font-weight: 400px !important;
  font-size: 0.85rem !important;
  line-height: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    padding: 0.25rem 0rem;
    width: 100%;
    font-size: 1rem;
  }
`;

export const SideNavBarLinkIcon = styled.div<{
  active: boolean;
  isCollapsed: boolean;
}>`
  opacity: ${(props) => (props.active ? '1.0' : '0.6')};

  @media ${({ theme }) => theme.mediaQuery.medium} {
    display: flex;
    width: ${(props) => (props.isCollapsed ? '100%' : 'auto')};
    align-items: center;
    justify-content: ${(props) =>
      props.isCollapsed ? 'center' : 'flex-start'};
  }
`;

export const SideNavBarLinkLabel = styled.span<{ active: boolean }>`
  margin-left: 1rem;
  opacity: ${(props) => (props.active ? '1.0' : '0.6')};
`;

export const SideNavBarDockIcon = styled(Icon('dock'))`
  margin-left: auto;
`;

export const SideNavBarUserSection = styled.div`
  padding: 0.5rem;
  margin: auto auto 1.6rem auto;
  width: 213px;
  height: 68px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
`;

export const UserInitialsContainer = styled.div`
  min-width: 56px;
  min-height: 56px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#8ad4ec, #ef96ff, #ff56a9, #ffaa6c);
`;

export const UserInitialsContent = styled.div`
  position: absolute;
  min-width: 52px;
  width: 52px;
  min-height: 52px;
  height: 52px;
  border-radius: 50%;
  color: ${({ theme }) => theme.color.secondaryGreen};
  background: ${({ theme }) => theme.colorMap.neutral.black};
  font-weight: 800;
  font-size: 1.375rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid none;
`;

export const UserNameContent = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorMap.neutral.white};
  margin-left: 0.5rem;
`;
