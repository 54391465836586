import React, { useRef } from 'react';
import ReactModal, { Styles } from 'react-modal';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '../../hooks';
import { ListingMedia } from '../../lib/models';
import VideoPreview from '../../components/VideoPreview';

const ModalOverlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.75)',
  backdropFilter: 'blur(5px)',
  zIndex: 1,
};

export const getModalStyles = (
  minWidthMedium: boolean,
  colorMap: Record<string, Record<string, string>>,
  styleOverrides?: {
    mediumScreen?: React.CSSProperties;
    smallScreen?: React.CSSProperties;
  }
): React.CSSProperties => {
  if (minWidthMedium) {
    return {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      margin: 'auto',
      width: '850px',
      height: '700px',
      backgroundColor: `transparent`,
      WebkitOverflowScrolling: 'touch',
      outline: 'none',
      zIndex: 2,
      border: 'none',
      overflow: 'visible',
      ...styleOverrides?.mediumScreen,
    };
  }

  return {
    position: 'fixed',
    bottom: '0',
    left: '0',
    margin: 'auto',
    width: '100%',
    height: '510px',
    inset: 'auto 0px 0px',
    backgroundColor: `transparent`,
    outline: 'none',
    padding: '0px',
    zIndex: 2,
    overflow: 'visible',
    border: 'none',
    ...styleOverrides?.smallScreen,
  };
};

type PreviewVideoModalProps = {
  isOpen: boolean;
  close: () => void;
  video: ListingMedia;
};

const PreviewVideoModal: React.FC<PreviewVideoModalProps> = ({
  isOpen,
  close,
  video,
}) => {
  const { colorMap, mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);

  const style: Styles = {
    overlay: ModalOverlayStyle,
    content: getModalStyles(minWidthMedium, colorMap),
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={close}
      contentLabel="Modal"
      style={style}
      preventScroll={true}
      appElement={document.getElementsByTagName('body')[0]}
    >
      <ModalContentWrapper>
        <VideoPreview videoUrl={video.url} />
      </ModalContentWrapper>
    </ReactModal>
  );
};

export default PreviewVideoModal;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-carousel {
    max-height: 100%;

    .slick-slider {
      max-height: 100%;

      .slick-dots {
        margin: 0;
      }
    }
  }
`;
