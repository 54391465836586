import React from 'react';
import firebase from '../../lib/firebase';

enum ACTIONS {
  SUBMIT_PHONE,
  SUBMIT_CODE,
  SET_PHONE_ERROR,
  SET_CODE_ERROR,
  SET_STEP,
  RESEND_CODE,
}

export enum STEP {
  ENTER_PHONE,
  VERIFY_PHONE,
  ENTER_CODE,
  VERIFY_CODE,
}

type LoginFlowAction = {
  type: ACTIONS;
  payload: string;
};

type LoginFlowState = {
  step: STEP;
  phone: string;
  error: string;
  codeResent: boolean;
};

type LoginFlowActionCreators = {
  submitPhone: (phone: string) => void;
  submitCode: (code: string) => void;
  resendCode: (phone: string) => void;
};

type LoginFlow = [LoginFlowState, LoginFlowActionCreators];

const reducer: React.Reducer<LoginFlowState, LoginFlowAction> = (
  state,
  action
) => {
  switch (action.type) {
    case ACTIONS.SET_STEP:
      return { ...state, step: STEP[action.payload], error: '' };
    case ACTIONS.SUBMIT_PHONE:
      return {
        ...state,
        step: STEP.VERIFY_PHONE,
        phone: action.payload,
        error: '',
      };
    case ACTIONS.SET_PHONE_ERROR:
      return { ...state, step: STEP.ENTER_PHONE, error: action.payload };
    case ACTIONS.SUBMIT_CODE:
      return { ...state, step: STEP.VERIFY_CODE, phone: action.payload };
    case ACTIONS.SET_CODE_ERROR:
      return { ...state, step: STEP.ENTER_CODE, error: action.payload };
    case ACTIONS.RESEND_CODE:
      return { ...state, codeResent: true };
  }
  return state;
};

const makeActionCreators = (
  dispatch: React.Dispatch<LoginFlowAction>
): LoginFlowActionCreators => {
  let confirmResult: firebase.auth.ConfirmationResult;
  let verifier: firebase.auth.RecaptchaVerifier;
  let recaptchaWidgetId: number | null = null;

  const setupRecaptcha = async () => {
    if (recaptchaWidgetId === null) {
      verifier = new firebase.auth.RecaptchaVerifier('auth-recaptcha', {
        size: 'invisible',
      });
      recaptchaWidgetId = await verifier.render();
    }
  };

  const performFirebaseRequest = async (payload: string) => {
    await setupRecaptcha();
    try {
      confirmResult = await firebase
        .auth()
        .signInWithPhoneNumber(`+${payload}`, verifier);
      dispatch({ type: ACTIONS.SET_STEP, payload: 'ENTER_CODE' });
    } catch (e) {
      (window as any).grecaptcha.reset(recaptchaWidgetId);
      dispatch({
        type: ACTIONS.SET_PHONE_ERROR,
        payload: 'Invalid phone number in the selected country',
      });
    }
  };

  const submitPhone = async (payload: string) => {
    if (isAdmin(payload)) {
      dispatch({ type: ACTIONS.SUBMIT_PHONE, payload });
      performFirebaseRequest(payload);
    }
  };

  const submitCode = async (payload: string) => {
    dispatch({ type: ACTIONS.SET_STEP, payload: 'VERIFY_CODE' });

    try {
      await confirmResult.confirm(payload);
    } catch (e) {
      dispatch({
        type: ACTIONS.SET_CODE_ERROR,
        payload: 'Invalid code. Please try again!',
      });
    }
  };

  const isAdmin = (phone: string) => {
    const unauthorizedError = () => {
      throw new Error('User is not an Admin.');
    };
    const admins = new Set(
      (process.env.REACT_APP_ATLAS_ADMIN_LIST || '').split(',')
    );
    const isAdmin = admins.has(`+${phone}`);

    try {
      if (!isAdmin) unauthorizedError();
      return true;
    } catch (e) {
      console.log('Exception: ', e);
      dispatch({
        type: ACTIONS.SET_PHONE_ERROR,
        payload: 'User is not an Admin!',
      });
      return false;
    }
  };

  const resendCode = (phone) => {
    dispatch({ type: ACTIONS.RESEND_CODE, payload: '' });
    (window as any).grecaptcha.reset(recaptchaWidgetId);
    performFirebaseRequest(phone);
  };

  return {
    submitPhone,
    submitCode,
    resendCode,
  };
};

const initialData: LoginFlow = [
  {
    step: STEP.ENTER_PHONE,
    phone: '',
    error: '',
    codeResent: false,
  },
  {
    submitPhone: () => {},
    submitCode: () => {},
    resendCode: () => {},
  },
];

const useLoginFlowData = (): LoginFlow => {
  const [state, dispatch] = React.useReducer(reducer, initialData[0]);

  const actionCreators = React.useMemo(
    () => makeActionCreators(dispatch),
    [dispatch]
  );

  return [state, actionCreators];
};

const LoginFlowContext = React.createContext<LoginFlow>(initialData);

const LoginFlowProvider = (props) => (
  <LoginFlowContext.Provider value={useLoginFlowData()} {...props} />
);
const useLoginFlow = () => React.useContext(LoginFlowContext);
export { LoginFlowProvider, useLoginFlow };
