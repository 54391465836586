import styled from '@emotion/styled';

export const ListingCreateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 2rem;
`;

export const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-direction: row;
  }
`;

export const FormColumn = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    flex-direction: column;
  }
`;

export const FormSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0rem;
  width: 90%;
`;

export const FormDetailInputSection = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0rem;

  .form-input {
    flex-basis: 45%;
  }
`;

export const FormSectionLabel = styled.span`
  font-size: 1.125rem;
  font-weight: 700;
`;

export const FormInput = styled.input`
  border: none;
  height: 50px;
  font-size: 1rem;
  background: ${({ theme }) => theme.colorMap.gray.gray5};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
  margin: 0.25rem 0rem;
`;

export const FormTextArea = styled.textarea`
  border: none;
  height: 200px;
  font-size: 1rem;
  background: ${({ theme }) => theme.colorMap.gray.gray5};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 0.25rem 0rem;
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    width: 100%;
  }
`;

export const MultiSelectWrapper = styled.div`
  width: 100%;
`;
