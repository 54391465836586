export interface UnicornTheme {
  color: Record<string, string>;
  size: Record<string, string>;
  mediaQuery: Record<string, string>;
  colorMap: Record<string, Record<string, string>>;
  zIndices: Record<string, number>;
}

export const colorMap = {
  neutral: {
    black: '#000000',
    white: '#ffffff',
  },
  gray: {
    gray1: '#4F4F4F',
    gray2: '#828282',
    gray3: '#BDBDBD',
    gray4: '#E0E0E0',
    gray5: '#F2F2F2',
    gray6: '#F9F9F9',
  },
  purple: {
    purple1: '#3C096C',
  },
  red: {
    red1: '#FF005E',
    red2: '#FF0000',
  },
  green: {
    // green1: '#03BFB2',
    green1: '#BEFEF2',
    green2: '#05D6C9',
    green3: '#14636E',
  },
  pink: {
    pink1: '#FF70A3',
    pink2: '#FFBBC1',
    pink3: '#E20051',
  },
};

export const color = {
  primary: colorMap.red.red1,
  secondary: colorMap.purple.purple1,
  tertiary: '#333333',
  error: colorMap.red.red2,
  disabled: colorMap.gray.gray4,
  success: colorMap.green.green1,
  primaryPink: colorMap.pink.pink1,
  secondaryPink: colorMap.pink.pink2,
  tertiaryPink: colorMap.pink.pink3,
  primaryGreen: colorMap.green.green3,
  secondaryGreen: colorMap.green.green2,
  tertiaryGreen: colorMap.green.green1,
  white: colorMap.neutral.white,
  black: colorMap.neutral.black,
};

export type MediaQuerySize =
  | 'min'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'max';

export const size = {
  min: '300px',
  xsmall: '375px',
  small: '576px',
  medium: '768px',
  large: '1024px',
  xlarge: '1200px',
  max: '1400px',
};

export const zIndices = {
  topmost: 3,
  top: 2,
  middle: 1,
  bottom: 0,
  bottommost: -1,
};

export const mediaQuery = Object.entries(size).reduce(
  (acc, [k, v]) => ({
    ...acc,
    [k]: `only screen and (min-width: ${v})`,
  }),
  {}
);

const getTheme = (overrides = {}): UnicornTheme => ({
  color,
  colorMap,
  size,
  mediaQuery,
  zIndices,
  ...overrides,
});

export default getTheme;
