import { Agent } from 'http';

export type ListingRentalRatingsModel = {
  landlordRating: number;
  naturalLight: number;
  noiseLevel: number;
};

export type ListingAddressModel = {
  address1: string;
  address2?: string;
  city: string;
  countryCode: string;
  postalCode: string;
  postalCode2?: string;
  state: string;
};

export enum RentalRatingType {
  LIGHT_LEVEL = 'LIGHT_LEVEL',
  NOISE_LEVEL = 'NOISE_LEVEL',
  LANDLORD = 'LANDLORD',
  UNKNOWN = 'UNKNOWN',
}

export type RentalRatingBody = {
  ratingType: RentalRatingType;
  rating: number;
};

export type RentalAmenityBody = {
  amenityKey: string;
};

export type ListingModel = {
  id: string;
  createdAt: string;
  updatedAt: string;
  approvedAt?: string;
  deletedAt?: string;
  closedAt?: string;
  rentalId: string;
  listingType: string;
  listingSource: string;
  monthlyRent: number;
  availableAt: string;
  createdBy: string;
  userId: string;
  title: String;
};

export type RentalModel = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  rentalType: string;
  sqft?: number;
  userId: string;
  address1: string;
  address2?: string;
  postalCode: string;
  city: string;
  state: string;
  countryCode: string;
  neighborhood?: string;
  numBathrooms: number;
  numBedrooms: number;
  numParkingSpots: number;
};

export type ListingDetailsModel = {
  label?: string;
  listingId: string;
  rentalId: string;
  userId: string;
  rentalType: string;
  listingType: string;
  monthlyRent: number;
  availableAt: string;
  approvedAt?: string;
  closedAt?: string;
  imageUrls: Array<string>;
  videoUrls: Array<string>;
  address: ListingAddressModel;
  allowsCats: boolean;
  allowsDogs: boolean;
  hasAcunit: boolean;
  hasBackyard: boolean;
  hasDishwasher: boolean;
  hasGym: boolean;
  hasHeat: boolean;
  hasPool: boolean;
  hasACUnit: boolean;
  hasHeater: boolean;
  hasBalcony: boolean;
  hasWasherDryer: boolean;
  numBathrooms: number;
  numBedrooms: number;
  numParkingSpots: number;
  sqft?: number;
  approximateLocation?: Location;
  ratings: ListingRentalRatingsModel;
  amenities: RentalAmenity[];
  leaseDurationDays?: number;
  description?: string;
  listingSource?: string;
};

export type RentalAmenity = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  rentalId: string;
  amenityKey: string;
  amenityScope: string;
  amenityType: string;
};

export type UserModel = {
  id: string;
  mobileNumber: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
};

export type MessageModel = {
  id: string;
  submittedAt: string;
  respondedAt: string;
  userId: string;
  listingId: string;
  message: string;
};

export type MessageResponseModel = {
  message: MessageModel;
  user: UserModel;
};

export enum FormKind {
  INPUT = 'input',
  FILE = 'file',
  CHECKBOX = 'checkbox',
}

export enum FormInputType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  FILE = 'file',
  BOOLEAN = 'boolean',
}

export type FormField = {
  kind: string;
  label: string;
  type: any;
  name: string;
  placeholder?: string;
  defaultValue?: any;
  value?: any;
  min?: number;
  max?: number;
  step?: number;
  accept?: string;
};

export enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export type RentalMediaModel = {
  id: string;
  bucket: string;
  objectKey: string;
  mediaFormat: string;
  rentalId: string;
  url: string;
  createdAt: Date;
  updatedAt: Date;
};

export type AddressComponentModel = {
  long_name: string;
  short_name: string;
  types: Array<string>;
};

export type ListingFormModel = {
  firstName: FormField;
  lastName: FormField;
  address: FormField;
  address1: FormField;
  address2: FormField;
  city: FormField;
  state: FormField;
  postalCode: FormField;
  postalCode2: FormField;
  countryCode: FormField;
  title: FormField;
  monthlyRent: FormField;
  rentalType: FormField;
  allowsCats: FormField;
  allowsDogs: FormField;
  hasAcunit: FormField;
  hasBackyard: FormField;
  hasBalcony: FormField;
  hasDishwasher: FormField;
  hasGym: FormField;
  hasHeater: FormField;
  hasPool: FormField;
  hasWasherDryer: FormField;
  numBathrooms: FormField;
  numBedrooms: FormField;
  numParkingSpots: FormField;
  availableAt: FormField;
  naturalLightRating: FormField;
  noiseLevelRating: FormField;
  landlordRating: FormField;
  sqft: FormField;
};

export enum MediaFormat {
  MP4 = 'MP4',
  MOV = 'MOV',
  PNG = 'PNG',
  JPG = 'JPG',
  JPEG = 'JPEG',
}

export enum UploadState {
  READY = 'ready',
  UPLOADING = 'uploading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface ListingMedia {
  id: string;
  mediaFormat: string;
  objectKey: string;
  groupId: string;
  bucket: string;
  rentalId: string;
  rank?: number;
  url: string;
  posterUrl?: string;
}

export type ListingUpdateBody = {
  listingId: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  postalCode2?: string;
  countryCode?: string;
  title?: string;
  monthlyRent?: number;
  availableAt?: string;
  rentalType?: string;
  numBathrooms?: boolean;
  numBedrooms?: boolean;
  numParkingSpots?: boolean;
  sqft?: number;
  amenities?: RentalAmenityBody[];
  ratings?: RentalRatingBody[];
};

export type ListingCreateBody = {
  createdBy: string;
  userId: string;
  firstName: string;
  lastName: string;
  address: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  postalCode2?: string;
  countryCode: string;
  title: string;
  monthlyRent: number;
  availableAt: string;
  rentalType: string;
  listingType: string;
  numBathrooms: boolean;
  numBedrooms: boolean;
  numParkingSpots: boolean;
  sqft?: number;
  amenities: RentalAmenityBody[];
  ratings: RentalRatingBody[];
  description?: string;
  leaseDurationDays?: number;
};

export type ListingCloseBody = {
  id: string;
};

export type ListingApproveBody = {
  id: string;
};

export interface SelectOption {
  value: any;
  label: string;
}

export enum ListingStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
}

export interface PaginatedResponse<T> {
  total: number;
  offset: number;
  limit: number;
  results: T[];
}

export type Location = {
  latitude: number;
  longitude: number;
};

export type VideoListingDetailsModel = {
  id: string;
  listingId: string;
  rentalType: string;
  monthlyRent: number;
  availableAt: string;
  videoUrl: string;
  posterUrl?: string;
  city: string;
  state: string;
  numBathrooms: number;
  numBedrooms: number;
  approximateLocation: Location;
};

export type PopularityRankingsModel = {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  listingId: string;
  rank: number;
};

export type QrCode = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  code: string;
  redirectUrl: string;
  location?: Location;
};

export type TabProps = {
  label: string;
  key: string;
};

export type ErrorMessage = string;

export enum RealtorContactType {
  AGENT = 'AGENT',
  OFFICE = 'OFFICE',
  UNKNOWN = 'UNKNOWN',
}

export type ExternalListingModel = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  rentalId: string;
  listingId: string;
  externalRentalId: string;
  externalListingId: string;
  externalSource: string;
  matterportUrl?: string;
};

export type ExternalListingContactResponse = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  externalListingId: string;
  contactType: string | RealtorContactType;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  email?: string;
  mobileNumber?: string;
};

export type TikTokAuth = {
  authKey: string;
  accountName: string;
  openId: string;
  scopes: string[];
  accessToken: string;
  expiresAt: string;
  refreshToken: string;
  refreshExpiresAt: string;
};

export interface RentalSocialMediaUpload {
  id: string;
  rentalId: string;
  rentalMediaId: string;
  uploadedAt: string;
  uploadedBy: string;
  socialMediaSource: string;
  socialMediaAccountName: string;
  socialMediaUserId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}
