import { UserModel } from '../../lib/models';

export enum UserTab {
  DETAILS = 'DETAILS',
  LISTINGS = 'LISTINGS',
  MESSAGES = 'MESSAGES',
}

export type TabProps = {
  label: string;
  key: string;
};

export const tabs: TabProps[] = [
  {
    label: 'Details',
    key: UserTab.DETAILS.toString(),
  },
  {
    label: 'Listings',
    key: UserTab.LISTINGS.toString(),
  },
  {
    label: 'Messages',
    key: UserTab.MESSAGES.toString(),
  },
];

export const userDisplayName = (user: UserModel) => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user.email) {
    return `${user.email}`;
  } else {
    return `${user.mobileNumber}`;
  }
};
