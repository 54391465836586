import styled from '@emotion/styled';
import { ListingStatus } from '../../lib/models';

export const ListingQueueWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media ${({ theme }) => theme.mediaQuery.large} {
    width: 100%;
    height: 100%;
  }
`;

export const Approval = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 1rem 0rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorMap.gray.gray4};

  @media ${({ theme }) => theme.mediaQuery.large} {
    flex-direction: row;
  }
`;

export const ApprovalSection = styled.div<{ widthPerc: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 0 0.5rem;

  @media ${({ theme }) => theme.mediaQuery.large} {
    flex-basis: ${(props) => props.widthPerc};
  }
`;

export const ApprovalSectionHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  font-size: 0.875rem;
`;

export const SectionRow = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQuery.large} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  margin-right: 0.25rem;
  flex: 1;
`;

export const SectionLabel = styled.div`
  color: ${({ theme }) => theme.colorMap.gray.gray2};
`;

export const SectionValue = styled.div``;

export const SectionInputField = styled.input`
  width: 100%;
`;

export const ListingStatusLink = styled.a`
  font-weight: 600;
`;
