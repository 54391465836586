import React from 'react';
import AppPage from '../AppPage';
import { RouteProps } from 'react-router';
import {
  ExternalListingContactResponse,
  ListingDetailsModel,
} from '../../lib/models';
import { Card } from '../../components/UI/Card';
import { Button } from '../../components/UI/Button';
import { useApprovals, useService } from '../../hooks/index';
import {
  deriveListingStatus,
  groupBy,
  queryParamsParser,
} from '../../lib/helpers';
import ListingMediaGallery from './ListingMediaGallery';
import ListingDetails from './ListingDetails';
import ListingEdit from './ListingEdit';
import ApprovalButtons from './ApprovalButtons';
import {
  ListingQueueWrapper,
  Approval,
  ApprovalSection,
  ApprovalSectionHeader,
  ListingStatusLink,
} from './components';
import { StyledPageHeader } from '../../components/UI/Card/Card';
import { useQuery } from 'react-query';
import ListingHeader from './ListingHeader';
import CopyDiv from '../../components/CopyDiv';

type ListingApprovalQueryParams = {
  offset?: number;
  limit?: number;
  listingId?: string;
};

const ListingQueue = (props: RouteProps) => {
  const queryParams = queryParamsParser(
    props.location?.search || ''
  ) as ListingApprovalQueryParams;

  const { listings, usersById, isEditable, handlers } = useApprovals(
    queryParams || { offset: 0, limit: 0 }
  );

  const listingUser = (userId: string) => usersById.get(userId);

  const externalListingContactService = useService<'externalListingContact'>(
    'externalListingContact'
  );

  const listingIds = listings?.map((listing) => listing.listingId) || [];

  const { data: contactsByListingId } = useQuery<
    {},
    {},
    Map<string, ExternalListingContactResponse[]>
  >(
    [`external-listing-contacts`, { listingIds: listingIds }],
    () =>
      externalListingContactService
        .byListingIds(listingIds)
        .then((contacts) => {
          const map: Map<string, ExternalListingContactResponse[]> = groupBy(
            contacts,
            'listingId'
          );
          return map;
        })
        .catch((e) => {
          return {};
        }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: listingIds.length > 0,
    }
  );

  const handleEditButton = () => {
    handlers.handleIsEditable(!isEditable);
  };

  const onEditSubmit = (updatedListing: ListingDetailsModel) => {
    const updatedListings = listings.map((listing) => {
      if (listing.listingId === updatedListing.listingId) {
        return updatedListing;
      } else {
        return listing;
      }
    });
    handlers.handleListings(updatedListings);
    handlers.handleIsEditable(!isEditable);
  };

  return (
    <AppPage>
      <Card title="">
        <ListingQueueWrapper>
          {listings.map((listing) => {
            const listingStatus = deriveListingStatus(listing);
            return (
              <React.Fragment key={listing.listingId}>
                <StyledPageHeader
                  justifyContent={'flex-start'}
                  extraWidth={'100%'}
                  alignRight={false}
                  className="card-page-header"
                  ghost={false}
                  extra={
                    <ListingHeader
                      listing={listing}
                      listingStatus={listingStatus}
                      contacts={
                        contactsByListingId
                          ? contactsByListingId[listing.listingId]
                          : undefined
                      }
                    />
                  }
                />
                <Approval key={listing.listingId}>
                  {/* Media */}
                  <ApprovalSection widthPerc="52%">
                    <ListingMediaGallery
                      key={listing.listingId}
                      listingId={listing.listingId}
                    />
                  </ApprovalSection>
                  {/* Details */}
                  <ApprovalSection widthPerc="48%">
                    <ApprovalSectionHeader>
                      <ListingStatusLink
                        target="_blank"
                        href={`https://app.rentunicorn.com/listings/${listing.listingId}`}
                      >
                        <CopyDiv value={listing.listingId}>
                          Listing Id: {listing.listingId.slice(0, 8)}
                        </CopyDiv>
                      </ListingStatusLink>
                      {!isEditable && (
                        <Button
                          style={{
                            marginLeft: 'auto',
                          }}
                          onClick={handleEditButton}
                          size="small"
                          variant="tertiary"
                          color="primaryGreen"
                        >
                          Edit
                        </Button>
                      )}
                    </ApprovalSectionHeader>
                    {isEditable ? (
                      <ListingEdit
                        listing={listing}
                        user={listingUser(listing.userId)!!}
                        onCancel={() => handlers.handleIsEditable(false)}
                        onSubmit={onEditSubmit}
                      />
                    ) : (
                      <ListingDetails
                        listing={listing}
                        user={listingUser(listing.userId)!!}
                      />
                    )}
                    {listing && (
                      <ApprovalButtons
                        listingId={listing.listingId}
                        status={listingStatus}
                        approve={handlers.approve}
                        close={handlers.close}
                      />
                    )}
                  </ApprovalSection>
                </Approval>
              </React.Fragment>
            );
          })}
          {listings.length === 0 && 'No Pending Listings'}
        </ListingQueueWrapper>
      </Card>
    </AppPage>
  );
};

export default ListingQueue;
