import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Card } from '../../components/UI/Card';
import { Button } from '../../components/UI/Button';
import { Tabs } from 'antd';
import AppPage from '../AppPage';
import { DiscoverTab, tabs } from './constants';
import { useService } from '../../hooks';
import RankDiscover from './RankDiscover';
import PopularityRankings from './PopularityRankings';
const { TabPane } = Tabs;

const Discover = () => {
  const [activeTab, setActiveTab] = useState<string>(
    DiscoverTab.POPULAR.toString()
  );
  const discoverService = useService<'discover'>('discover');
  const autoRankPopularityRankings = async () => {
    await discoverService.generateByListingPopularity({
      count: 100,
      lookbackDays: 180,
    });
    window.location.reload();
  };

  const buttons = [
    <StyledButton
      key="generate-popularity-rankings"
      size="medium"
      variant="primary"
      color="primaryGreen"
      onClick={autoRankPopularityRankings}
    >
      Auto Rank
    </StyledButton>,
  ];

  const pageByTab = (tabKey: string) => {
    if (tabKey === DiscoverTab.POPULAR.toLowerCase()) {
      return <PopularityRankings />;
    } else {
      return <RankDiscover />;
    }
  };

  return (
    <AppPage>
      <Card title={'Popular Rankings'} buttons={buttons}>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
        >
          {tabs.map((tab) => (
            <TabPane tab={tab.label} key={tab.key.toLowerCase()}>
              {pageByTab(tab.key.toLowerCase())}
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </AppPage>
  );
};

export default Discover;

const StyledButton = styled(Button)`
  min-width: 150px;
`;
