import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './Home';
import Login from './Login';

const Auth = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <Route path="/" exact component={Home} />
    <Route render={() => <Redirect to="/" />} />
  </Switch>
);

export default Auth;
