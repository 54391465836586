import styled from '@emotion/styled';
import { Badge, Card } from 'antd';
import { useConfig } from '../../hooks';
import { capitalize } from '../../lib/helpers';
import {
  ExternalListingContactResponse,
  ListingDetailsModel,
} from '../../lib/models';

const generateBody = (
  firstName: string,
  lastName: string,
  address1: string
) => {
  return encodeURIComponent(`Hello,

My name is ${firstName} ${lastName} and I'm a broker with Unicorn 🦄, a new LA-based brokerage.                                  

I have several clients interested in ${address1}. We would love to schedule a time to tour the property if it is still available.

Appreciate your time in advance.

Sincerely,
${firstName}
Rent Unicorn, Inc.
w/ rentunicorn.com
  `);
};

const titleForContact = (contact: ExternalListingContactResponse) => {
  if (contact.firstName) {
    return `${contact.firstName} ${contact.lastName}`;
  } else if (contact.lastName) {
    return `${contact.lastName}`;
  } else {
    return `${contact.companyName}`;
  }
};

const ExternalListingContact: React.FC<{
  listing: ListingDetailsModel;
  contact: ExternalListingContactResponse;
}> = ({ listing, contact }) => {
  const { user } = useConfig();

  const openSms = (mobileNumber: string, address1: string) => {
    if (user) {
      const body = generateBody(
        user.firstName || '',
        user.lastName || '',
        address1
      );
      const url = `sms:?${mobileNumber}&body=${body}`;
      window?.open(url, '_blank')?.focus();
    }
  };

  const openEmail = (email: string, address1: string) => {
    if (user) {
      const subject = encodeURI(`Tour availability for ${address1}`);
      const body = generateBody(
        user.firstName || '',
        user.lastName || '',
        address1
      );
      const url = `https://mail.google.com/a/rentunicorn.com/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
      navigator.clipboard.writeText(url);
      window?.open(url, '_blank')?.focus();
    }
  };

  const title = titleForContact(contact);

  return (
    <Badge.Ribbon text={capitalize(contact.contactType || '')}>
      <Contact title={title} key={contact.id}>
        {contact.mobileNumber && (
          <a
            onClick={() =>
              openSms(contact.mobileNumber!!, listing.address.address1)
            }
          >
            {contact.mobileNumber}
          </a>
        )}
        {contact.email && (
          <a
            onClick={() => openEmail(contact.email!!, listing.address.address1)}
          >
            {contact.email}
          </a>
        )}
      </Contact>
    </Badge.Ribbon>
  );
};

export default ExternalListingContact;

export const Contact = styled(Card)`
  font-size: 1rem;
  margin: 0 1rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 300px;
  min-height: 100px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin: 0 1rem;
  }

  .ant-card-head {
    .ant-card-head-wrapper {
      .ant-card-head-title {
        white-space: break-spaces;
        padding: 0.25rem;
        max-width: 200px;
      }
    }
  }

  .ant-card-body,
  .ant-card-head {
    padding: 0.25rem;
    min-height: 40px;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;

    @media ${({ theme }) => theme.mediaQuery.medium} {
      flex-direction: row;
    }
  }

  a {
    margin: 0.5rem;
    word-wrap: break-word;
    font-weight: 600 !important;
    color: ${({ theme }) => theme.color.primaryPink} !important;
  }
`;
