import React from 'react';
import firebase from '../lib/firebase';
import { request } from '../lib/api';

type AuthContextValues = {
  token: string;
  userId: string;
  loaded: boolean;
  logout: () => void;
};
const FirebaseAuthContext = React.createContext<AuthContextValues>({
  token: '',
  userId: '',
  loaded: false,
  logout: () => {},
});

type LoginPayload = {
  userId: string;
};

const login = (token): Promise<LoginPayload> =>
  request('/auth/login', { method: 'POST' }, { token }).catch((e) =>
    request('/auth/signup', { method: 'POST' }, { token })
  );

function AuthProvider(props) {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<string>('');
  const [userId, setUserId] = React.useState<string>('');

  React.useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (!user) {
          setToken('');
          setUserId('');
          setLoaded(true);
          return;
        }

        setLoaded(false);

        user
          .getIdToken()
          .then((token) =>
            login(token).then(({ userId }) => {
              setUserId(userId);
              setToken(token);
              setLoaded(true);
            })
          )
          .catch(() => {
            // We should never get here -- this `catch` represents the case
            // where we have a valid firebase token but NEITHER `/auth/login`
            // or `/auth/signup` worked. This is probably a network error.
            // Rather than retry let's just logout of firebase and let the user
            // hope for better Internet weather on their next try.
            firebase.auth().signOut();
          });
      }),
    []
  );

  const logout = React.useCallback(() => firebase.auth().signOut(), []);

  return (
    <FirebaseAuthContext.Provider
      value={{ token, userId, logout, loaded }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(FirebaseAuthContext);

const NotLoggedIn = ({ children }) => {
  const { token, loaded } = useAuth();
  return !token && loaded ? children : null;
};

const LoggedIn = ({ children }) => {
  const { token } = useAuth();
  return token ? children : null;
};

export { AuthProvider, NotLoggedIn, LoggedIn, useAuth };
