import React, { useState } from 'react';
import { ListingModel } from '../../../lib/models';
import { useService } from '../../../hooks/index';
import { useQuery } from 'react-query';
import { listingQueueByUserColumns } from './constants';
import { Table } from '../../../components/UI/Table';
import { deriveListingStatusForListing } from '../../../lib/helpers';
import CopyDiv from '../../../components/CopyDiv';

type UserQueryParams = {
  userId?: string;
};

export type ListingByUserProps = {
  userId: string;
};

const ListingsByUser: React.FC<ListingByUserProps> = ({ userId }) => {
  const limit = 100;
  const [offset, setOffset] = useState<number>(0);

  const listingService = useService<'listing'>('listing');

  const { data: listings } = useQuery<{}, {}, ListingModel[]>(
    [`listings-by-user-ids`],
    () => listingService.byUserIds([userId || '']),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const dataSource = listings?.map((listing) => {
    const id = (
      <CopyDiv value={listing.id}>
        <a href={`/approvals?listingId=${listing.id}`}>
          {listing.id.slice(0, 8)}
        </a>
      </CopyDiv>
    );

    const status = deriveListingStatusForListing(listing);
    const copyRentalId = (
      <CopyDiv value={listing.rentalId}>{listing.rentalId.slice(0, 8)}</CopyDiv>
    );
    return { ...listing, id, status, rentalId: copyRentalId };
  });

  return (
    <Table
      total={listings?.length}
      columns={listingQueueByUserColumns}
      dataSource={dataSource || []}
      rowKey={'id'}
      onNext={() => setOffset(offset + limit)}
      onPrev={() => setOffset(offset - limit)}
    />
  );
};

export default ListingsByUser;
