/* @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Table as AntdTable, TablePaginationConfig } from 'antd';
import { EditableCell } from './EditableTable';

export type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export type TableProps = {
  columns: any[];
  dataSource: any[];
  onNext?: () => void;
  onPrev?: () => void;
  rowClassName?: (record: any, index: any) => string;
  limit?: number;
  total?: number;
  key?: string;
  rowKey?: string;
  showPagination?: boolean;
};

export const Table = ({
  columns,
  dataSource,
  onNext,
  onPrev,
  rowClassName,
  limit = 100,
  total = 0,
  key = 'table-key',
  rowKey = 'id',
  showPagination = true,
}: TableProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onChange = (pagination: TablePaginationConfig) => {
    if (!pagination.current) return;

    if (pagination.current < currentPage) {
      if (onPrev) onPrev();
      setCurrentPage(pagination.current);
    }
    if (pagination.current > currentPage) {
      if (onNext) onNext();
      setCurrentPage(pagination.current);
    }
  };

  return (
    <AntdTable
      key={key}
      showSorterTooltip={true}
      onChange={onChange}
      rowClassName={rowClassName}
      rowKey={rowKey}
      pagination={
        showPagination
          ? {
              pageSize: limit,
              total: total,
              hideOnSinglePage: false,
              showPrevNextJumpers: true,
              position: ['topRight', 'bottomRight'],
            }
          : false
      }
      columns={columns}
      dataSource={dataSource}
    />
  );
};

export const EditableTable = ({
  columns,
  dataSource,
  onNext,
  onPrev,
  rowClassName,
  limit = 100,
  total = 0,
  key = 'table-key',
  rowKey = 'id',
}: TableProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onChange = (pagination: TablePaginationConfig) => {
    if (!pagination.current) return;

    if (pagination.current < currentPage) {
      if (onPrev) onPrev();
      setCurrentPage(pagination.current);
    }
    if (pagination.current > currentPage) {
      if (onNext) onNext();
      setCurrentPage(pagination.current);
    }
  };

  return (
    <AntdTable
      key={key}
      showSorterTooltip={true}
      onChange={onChange}
      rowClassName={rowClassName}
      rowKey={rowKey}
      pagination={{
        pageSize: limit,
        total: total,
        hideOnSinglePage: false,
        showPrevNextJumpers: true,
        position: ['topRight', 'bottomRight'],
      }}
      columns={columns}
      dataSource={dataSource}
      components={{
        body: {
          cell: EditableCell,
        },
      }}
    />
  );
};
