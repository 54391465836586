import { IconName } from '../../components/UI/Icon';

export const getDistanceMeters = (lat1, lon1, lat2, lon2) => {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(lat2 - lat1);
  const dLong = rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(lat1)) *
      Math.cos(rad(lat2)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
};

const rad = function (x) {
  return (x * Math.PI) / 180;
};

export const calculateMetersPerPx = (lat: number, zoom: number) =>
  (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom);

export const filters: {
  label?: string;
  icon?: IconName;
  key: string;
  type: 'icon' | 'divider';
  disabled: boolean;
}[] = [
  {
    label: 'Listings',
    icon: 'listing-house',
    key: 'listings',
    disabled: false,
    type: 'icon',
  },
  {
    label: 'Popular',
    icon: 'new',
    key: 'popular',
    disabled: true,
    type: 'icon',
  },
  {
    label: 'Video',
    icon: 'video',
    key: 'video',
    disabled: true,
    type: 'icon',
  },
  {
    label: 'Polaris',
    icon: 'polaris',
    key: 'polaris',
    disabled: true,
    type: 'icon',
  },
  {
    key: 'divider-1',
    disabled: true,
    type: 'divider',
  },
  {
    label: 'Renters',
    icon: 'profile',
    key: 'renters',
    disabled: true,
    type: 'icon',
  },
  {
    label: 'Agents',
    icon: 'landlord',
    key: 'agents',
    disabled: true,
    type: 'icon',
  },
  {
    label: 'Scouts',
    icon: 'scout',
    key: 'scouts',
    disabled: true,
    type: 'icon',
  },
  {
    key: 'divider-2',
    disabled: true,
    type: 'divider',
  },
  {
    label: 'Bounties',
    icon: 'bounty',
    key: 'bounties',
    disabled: true,
    type: 'icon',
  },
  {
    label: 'Signs',
    icon: 'qr-code',
    key: 'signs',
    disabled: false,
    type: 'icon',
  },
  {
    label: 'Locks',
    icon: 'lock',
    key: 'locks',
    disabled: true,
    type: 'icon',
  },
  {
    key: 'divider-3',
    disabled: true,
    type: 'divider',
  },
  {
    label: 'Applications',
    icon: 'application',
    key: 'applications',
    disabled: true,
    type: 'icon',
  },
  {
    label: 'Deals',
    icon: 'deal',
    key: 'deals',
    disabled: true,
    type: 'icon',
  },
];
