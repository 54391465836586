import { Form, Modal } from 'antd';
import React, { useState } from 'react';
import { Button } from '../../components/UI/Button';
import styled from '@emotion/styled';
import CreateForm from './CreateForm';
import { useQueryClient } from 'react-query';
import { useService } from '../../hooks';
import { QUERY_CACHE_KEY_QR_BY_PAGINATION } from './constants';

const CreateModal: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const qrCodeService = useService<'qrCode'>('qrCode');

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    const [latitude, longitude] = form.getFieldValue('location')
      ? form.getFieldValue('location').split(',')
      : [null, null];

    const location =
      latitude && longitude
        ? {
            latitude,
            longitude,
          }
        : undefined;

    await qrCodeService.create({
      code: form.getFieldValue('code'),
      redirectUrl: form.getFieldValue('redirectUrl'),
      location,
    });
    setTimeout(() => {
      queryClient.invalidateQueries(QUERY_CACHE_KEY_QR_BY_PAGINATION);
      setVisible(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <StyledButton
        key="listing-create"
        size="small"
        variant="secondary"
        color="primaryGreen"
        onClick={showModal}
      >
        Add QR Code
      </StyledButton>
      <Modal
        title="Add QR Code"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <CreateForm form={form} showButton={false} />
      </Modal>
    </>
  );
};

export default CreateModal;

const StyledButton = styled(Button)`
  min-width: 150px;
`;
