import { Icon } from '../UI/Icon';

export const navLinks = [
  {
    label: '',
    subLinks: [
      {
        active: false,
        path: '/dashboard',
        label: 'Dashboard',
        icon: Icon('dashboard'),
      },
    ],
  },
  {
    label: '',
    subLinks: [
      {
        active: false,
        path: '/map',
        label: 'Maps',
        icon: Icon('pin'),
      },
    ],
  },
  {
    label: '',
    subLinks: [
      {
        active: false,
        path: '/users',
        label: 'Users',
        icon: Icon('profile'),
      },
    ],
  },
  {
    label: '',
    subLinks: [
      {
        active: false,
        path: '/listings',
        label: 'Listings',
        icon: Icon('home'),
      },
    ],
  },
  {
    label: '',
    subLinks: [
      {
        active: false,
        path: '/qr-codes',
        label: 'QR links',
        icon: Icon('qr-code'),
      },
    ],
  },
  {
    label: 'TASKS',
    subLinks: [
      {
        active: false,
        path: '/messages/open',
        label: 'Message Center',
        icon: Icon('message'),
      },
      {
        active: false,
        path: '/approvals',
        label: 'Listing Queue',
        icon: Icon('new'),
      },
      {
        active: false,
        path: '/discover',
        label: 'Popular Rankings',
        icon: Icon('discover'),
      },
    ],
  },
  {
    label: 'SYSTEM',
    subLinks: [
      {
        active: false,
        path: '/jobs',
        label: 'Jobs',
        icon: Icon('nucleus'),
      },
      {
        active: false,
        path: 'https://rentunicorn.slack.com/archives/C02SENHATCJ',
        label: 'Help',
        target: '_blank',
        icon: Icon('info-circle'),
      },
      {
        active: false,
        path: '/settings',
        label: 'Settings',
        icon: Icon('settings'),
      },
    ],
  },
];
