import React, { useRef } from 'react';
import ReactModal, { Styles } from 'react-modal';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '../../hooks';
import { ListingMedia } from '../../lib/models';
import { Carousel } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { CarouselRef } from 'antd/lib/carousel';

const ModalOverlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(130, 130, 130, 0.35)',
  backdropFilter: 'blur(5px)',
  zIndex: 1,
};

export const getModalStyles = (
  minWidthMedium: boolean,
  colorMap: Record<string, Record<string, string>>,
  styleOverrides?: {
    mediumScreen?: React.CSSProperties;
    smallScreen?: React.CSSProperties;
  }
): React.CSSProperties => {
  if (minWidthMedium) {
    return {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      margin: 'auto',
      width: '800px',
      height: '631px',
      backgroundColor: `${colorMap.neutral.white}`,
      WebkitOverflowScrolling: 'touch',
      outline: 'none',
      padding: '20px',
      borderRadius: '20px 20px 20px 20px',
      zIndex: 2,
      overflow: 'visible',
      ...styleOverrides?.mediumScreen,
    };
  }

  return {
    position: 'fixed',
    bottom: '0',
    left: '0',
    margin: 'auto',
    width: '100%',
    height: '510px',
    inset: 'auto 0px 0px',
    backgroundColor: `${colorMap.neutral.white}`,
    outline: 'none',
    padding: '0px',
    borderRadius: '32px 32px 0px 0px',
    zIndex: 2,
    overflow: 'visible',
    ...styleOverrides?.smallScreen,
  };
};

type PreviewImagesModalProps = {
  isOpen: boolean;
  close: () => void;
  media: ListingMedia[];
};

const PreviewImagesModal: React.FC<PreviewImagesModalProps> = ({
  isOpen,
  close,
  media,
}) => {
  const { colorMap, mediaQuery } = useTheme();
  // const [currentSlideIdx, setCurrentSlideIdx] = useState<number>(0);
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const carouselRef = useRef<CarouselRef>(null);

  const style: Styles = {
    overlay: ModalOverlayStyle,
    content: getModalStyles(minWidthMedium, colorMap),
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={close}
      contentLabel="Modal"
      style={style}
      preventScroll={true}
      appElement={document.getElementsByTagName('body')[0]}
    >
      <ModalContentWrapper>
        <Carousel ref={carouselRef}>
          {media.map((m) => (
            <ModalPhotoContainer>
              <ModalPhotoPreview
                key={`preview-${m.id}`}
                src={m.url}
                alt="Image Preview"
              />
              <IconSection>
                <LeftOutlined onClick={() => carouselRef.current?.prev()} />
                <RightOutlined onClick={() => carouselRef.current?.next()} />
              </IconSection>
            </ModalPhotoContainer>
          ))}
        </Carousel>
      </ModalContentWrapper>
    </ReactModal>
  );
};

export default PreviewImagesModal;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-carousel {
    max-height: 100%;

    .slick-slider {
      max-height: 100%;

      .slick-dots {
        margin: 0;
      }
    }
  }
`;

const ModalPhotoContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
`;

const ModalPhotoPreview = styled.img`
  width: auto;
  margin: 0 auto;
  height: 100%;
  max-height: calc(631px - 40px);
  object-fit: cover;
`;

const IconSection = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1;
  height: 50px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;

  span svg {
    color: white;
    width: 36px !important;
    height: 36px !important;
    cursor: pointer;
  }
`;
