import styled from '@emotion/styled';
import { MessageModel, UserModel } from '../../lib/models';
import { Button } from '../../components/UI/Button';
import { timeFormatFromString, dateFormatFromString } from '../../lib/helpers';

type SelectedMessageProps = {
  message: MessageModel;
  user: UserModel;
  markAsResponded: (messageId: string) => void;
};

const SelectedMessage = ({
  message,
  user,
  markAsResponded,
}: SelectedMessageProps) => {
  const firstInitial = () => {
    if (user.firstName && user.firstName.length > 1) {
      return user.firstName.substring(0, 1).toUpperCase();
    } else {
      return '';
    }
  };
  return (
    <SelectedMessageWrapper>
      <MessageContent>
        <MessageDate>{dateFormatFromString(message.submittedAt)}</MessageDate>
        <UserNameIcon>{firstInitial()}</UserNameIcon>
        <Message>
          <UserNameAndTimeStamp>
            <UserName>{user.firstName}</UserName>
            <Timestamp>{timeFormatFromString(message.submittedAt)}</Timestamp>
          </UserNameAndTimeStamp>
          <Text>{message.message}</Text>
        </Message>
      </MessageContent>
      <ButtonContainer>
        <Button
          onClick={() => markAsResponded(message.id)}
          size="large"
          variant="primary"
          color="primaryGreen"
        >
          Mark as Responded
        </Button>
      </ButtonContainer>
    </SelectedMessageWrapper>
  );
};

export default SelectedMessage;

const SelectedMessageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 0.5rem;
`;

const MessageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const MessageDate = styled.div`
  flex: 1 0 100%;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colorMap.gray.gray2};
  font-weight: 600;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const UserNameAndTimeStamp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-weight: 800;
  }
`;

const UserName = styled.div`
  font-weight: 800;
  font-size: 0.9rem;
`;

const Timestamp = styled.span`
  color: ${({ theme }) => theme.colorMap.gray.gray2};
  font-size: 0.75rem;
  margin-left: 0.25rem;
`;

const Text = styled.div`
  font-size: 1rem;
`;

const UserNameIcon = styled.div`
  background: ${({ theme }) => theme.colorMap.neutral.black};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: ${({ theme }) => theme.color.secondaryGreen};
  font-weight: 800;
  line-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
