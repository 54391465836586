import { Button } from '../../components/UI/Button';
import { useAuth, useConfig, useService } from '../../hooks';
import { Card } from '../../components/UI/Card';
import { Icon } from '../../components/UI/Icon';
import { UserModel } from '../../lib/models';
import { useEffect, useState } from 'react';
import AppPage from '../AppPage';
import { SettingsWrapper, SettingsCol, AuthToken } from './components';
import DetailsSection from './DetailsSection';
import FormSection from './FormSection';

const Settings = () => {
  const { token } = useAuth();
  const { user: userConfig } = useConfig();
  const userService = useService<'user'>('user');
  const [user, setUser] = useState<UserModel>();
  const [editableField, setEditableField] = useState<string | null>(null);
  const [showCopy, setShowCopy] = useState<boolean>(false);

  const getUser = async () => {
    if (userConfig?.id) {
      const users = await userService.byIds([userConfig.id]);
      if (users.length > 0) setUser(users[0]);
    }
  };

  const copyToken = () => {
    setShowCopy(true);
    navigator.clipboard.writeText(`Bearer ${token}`);
    setTimeout(() => setShowCopy(false), 1000);
  };

  useEffect(() => {
    getUser();
  }, [editableField]);

  const ControlledAccessIcon = Icon('controlled-access');

  return (
    <AppPage>
      <Card title="Settings">
        <SettingsWrapper>
          {/* User */}
          <SettingsCol>
            {/* User Id */}
            <DetailsSection label="User Id" value={user?.id} editable={false} />
            {/* First Name */}
            {user && editableField === 'firstName' ? (
              <FormSection
                userId={user.id}
                label="First Name"
                value={user?.firstName}
                name="firstName"
                toggleEditable={() => setEditableField(null)}
              />
            ) : (
              <DetailsSection
                label="First Name"
                value={user?.firstName}
                editable={true}
                editField={() => setEditableField('firstName')}
              />
            )}
            {/* Last Name */}
            {user && editableField === 'lastName' ? (
              <FormSection
                userId={user.id}
                label="Last Name"
                value={user?.lastName}
                name="lastName"
                toggleEditable={() => setEditableField(null)}
              />
            ) : (
              <DetailsSection
                label="Last Name"
                value={user?.lastName}
                editable={true}
                editField={() => setEditableField('lastName')}
              />
            )}
            {/* Email */}
            {user && editableField === 'email' ? (
              <FormSection
                userId={user.id}
                label="Email"
                value={user?.email}
                name="email"
                toggleEditable={() => setEditableField(null)}
              />
            ) : (
              <DetailsSection
                label="Email"
                value={user?.email}
                editable={true}
                editField={() => setEditableField('email')}
              />
            )}
            {/* Mobile Number */}
            <DetailsSection
              label="Mobile Number"
              value={user?.mobileNumber}
              editable={false}
            />
          </SettingsCol>
          {/* Token */}
          <SettingsCol>
            <AuthToken>
              <ControlledAccessIcon />
              <span>JWT Token</span>
              <span>Bearer token for api authentication.</span>
              <Button
                style={{ marginTop: 'auto' }}
                onClick={copyToken}
                size="medium"
                variant="primary"
                color="black"
              >
                {' '}
                Copy Token
              </Button>
              {showCopy && (
                <span
                  style={{
                    position: 'absolute',
                    bottom: '2.0rem',
                    right: '2.0rem',
                  }}
                >
                  Copied token
                </span>
              )}
            </AuthToken>
          </SettingsCol>
        </SettingsWrapper>
      </Card>
    </AppPage>
  );
};

export default Settings;
