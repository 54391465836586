import { MessageResponseModel, PaginatedResponse, UserModel } from '../models';

export type MessagingService = {
  byUserId: (userId: string) => Promise<MessageResponseModel[]>;
  byListingId: (listingId: string) => Promise<MessageResponseModel[]>;
};

export const messagingServiceFactory = (apiClient: any): MessagingService => ({
  byUserId: async (userId: string): Promise<MessageResponseModel[]> => {
    return await apiClient(`atlas/messages/by-user-id`, {
      method: 'GET',
      body: { userId },
    });
  },
  byListingId: async (listingId: string): Promise<MessageResponseModel[]> => {
    return await apiClient(`atlas/messages/by-listing-id`, {
      method: 'GET',
      body: { listingId },
    });
  },
});
