import styled from '@emotion/styled';
import {
  ExternalListingContactResponse,
  ListingDetailsModel,
} from '../../lib/models';
import { Button } from '../../components/UI/Button';
import { toUSD, capitalize, dateFormatFromString } from '../../lib/helpers';
import ExternalListingContact from '../../components/ExternalListingContact';

const ListingDetails: React.FC<{
  listing: ListingDetailsModel;
  contacts?: ExternalListingContactResponse[];
}> = ({ listing, contacts }) => {
  const imageUrl = listing.imageUrls[0];

  const viewListing = (listingId: string) => {
    window.open(`/approvals?listingId=${listingId}`, '_blank');
  };

  return (
    <ListingDetailsWrapper>
      <RentalImage src={`${imageUrl}?width=300&height=300`} />
      <Details>
        <DetailsField>
          <span>Title:</span> {listing.label || 'N/A'}
        </DetailsField>
        <DetailsField>
          <span>Address 1:</span> {listing.address.address1 || 'N/A'}
        </DetailsField>
        <DetailsField>
          <span>Address 2:</span> {listing.address.address2 || 'N/A'}
        </DetailsField>
        <DetailsField>
          <span>City:</span> {listing.address.city}
        </DetailsField>
        <DetailsField>
          <span>Rental Type:</span> {capitalize(listing.rentalType)}
        </DetailsField>
        <DetailsField>
          <span>Rent:</span>
          {toUSD(listing.monthlyRent)}/mo
        </DetailsField>
        <DetailsField>
          <span>Avail:</span>
          {dateFormatFromString(listing.availableAt)}
        </DetailsField>
        <DetailsField>
          <span>Beds:</span> {listing.numBedrooms}
        </DetailsField>
        <DetailsField>
          <span>Baths:</span> {listing.numBathrooms}
        </DetailsField>
        <DetailsField>
          <span>Parking:</span>
          {listing.numParkingSpots}
        </DetailsField>
        <Button
          style={{ margin: '1.0rem auto' }}
          onClick={() => viewListing(listing.listingId)}
          size="large"
          variant="primary"
          color="primaryGreen"
        >
          View Listing
        </Button>
      </Details>
      <Details>
        <Divider />
        {contacts &&
          contacts.map((contact) => (
            <ExternalListingContact listing={listing} contact={contact} />
          ))}
      </Details>
    </ListingDetailsWrapper>
  );
};

export default ListingDetails;

export const ListingDetailsWrapper = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  padding: 1rem;

  span {
    font-weight: 600;
    margin-right: 0.25rem;
  }
`;

export const DetailsField = styled.div`
  margin-right: 1rem;
`;

export const RentalImage = styled.img`
  border-radius: 15px;
  min-width: 250x;
  min-height: 250px;
  width: 250px;
  object-fit: fill;
  margin: 0 auto;
`;

export const AmenitiesTitle = styled.div`
  text-align: start;
  font-size: 1.2rem;
  padding: 1rem;
  text-align: center;
  font-weight: 700;
  border-top: 1px solid #828282;

  @media ${({ theme }) => theme.mediaQuery.large} {
    font-size: 1.6rem;
  }
`;

export const ListingDetailsAmenitiesListItem = styled.li`
  font-size: 0.75rem;
`;
export const Divider = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 1rem;
  height: 1px;
  border-bottom: 1px dotted ${({ theme }) => theme.colorMap.gray.gray5};
`;
