import moment from 'moment';
import React, { useEffect } from 'react';
import { useIndexedDB } from 'react-indexed-db';
import { useService } from '.';
import { AtlasNamespace, tikTokAuthKeyByAccount } from '../lib/indexed-db';
import { TikTokAuth } from '../lib/models';

type TiktokAuthContextValues = {
  login: (data: TikTokAuth) => Promise<boolean>;
  authByAccountName: (accountName: string) => Promise<TikTokAuth | undefined>;
};

const TikTokAuthContext = React.createContext<TiktokAuthContextValues>({
  login: (data: TikTokAuth) => Promise.resolve(false),
  authByAccountName: (accountName: string) => Promise.resolve(undefined),
});

function TikTokAuthProvider(props) {
  const tiktokService = useService<'tiktok'>('tiktok');
  const db = useIndexedDB(AtlasNamespace.TIKTOK);

  const authByAccountName = async (
    accountName: string
  ): Promise<TikTokAuth | undefined> => {
    const auth = await db.getByIndex('accountName', accountName);
    if (auth) {
      return isExpired(auth.expiresAt)
        ? await tiktokService.accessToken(auth.refreshToken)
        : Promise.resolve(auth);
    } else {
      return Promise.resolve(undefined);
    }
  };

  const login = async (data: TikTokAuth): Promise<boolean> => {
    return await db.add(data).then(
      (event) => {
        console.log(event);
        return true;
      },
      (error) => {
        console.error(error);
        return false;
      }
    );
  };

  const isExpired = (expiresAt: string): boolean => {
    const expiresAtDate = moment(expiresAt);
    return !moment().isBefore(expiresAt);
  };

  const refreshAccessToken = async (
    accountName: string,
    refreshToken: string
  ) => {
    const key = tikTokAuthKeyByAccount(accountName);
    const currentValue = await db.getByIndex('accountName', accountName);
    const updatedToken = await tiktokService.accessToken(refreshToken);
    const newTokenValue = {
      accessToken: updatedToken.accessToken,
      expiresAt: updatedToken.expiresAt,
    };
    const updatedValue = { ...currentValue, ...newTokenValue };
    return await db.update(updatedValue);
  };

  const handleExpiredTokens = async () => {
    const accounts: TikTokAuth[] = await db.getAll();

    accounts.map(async (acc) => {
      if (isExpired(acc.expiresAt)) {
        await refreshAccessToken(acc.accountName, acc.refreshToken);
        console.log(`refreshed token for account: [${acc.accountName}]`);
      }
      if (isExpired(acc.refreshExpiresAt)) {
        db.deleteRecord(tikTokAuthKeyByAccount(acc.accountName));
        console.log(`deleted account record: [${acc.accountName}]`);
      }
    });
  };

  useEffect(() => {
    handleExpiredTokens();
  }, [handleExpiredTokens]);

  return (
    <TikTokAuthContext.Provider
      value={{ login, authByAccountName }}
      {...props}
    />
  );
}

const useTikTokAuth = () => React.useContext(TikTokAuthContext);

export { TikTokAuthProvider, useTikTokAuth };
