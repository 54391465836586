import React, { useState } from 'react';
import {
  ListingDetailsModel,
  ListingModel,
  MessageModel,
  UserModel,
} from '../../../lib/models';
import { useService } from '../../../hooks/index';
import { useQuery } from 'react-query';
import { messageColumns } from './constants';
import { Table } from '../../../components/UI/Table';
import { deriveListingStatusForListing } from '../../../lib/helpers';
import CopyDiv from '../../../components/CopyDiv';
import { message } from 'antd';

export type MessagesByUserProps = {
  userId: string;
};

const MessagesByUser: React.FC<MessagesByUserProps> = ({ userId }) => {
  const limit = 100;
  const [offset, setOffset] = useState<number>(0);

  const messagingService = useService<'messaging'>('messaging');
  const listingDetailsService = useService<'listingDetails'>('listingDetails');

  const { data: messages } = useQuery<{}, {}, MessageModel[]>(
    [`messages-by-user-id`],
    () => messagingService.byUserId(userId),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: listings } = useQuery<{}, {}, ListingDetailsModel[]>(
    [`listings-by-listing-id`],
    () =>
      listingDetailsService.byIds(
        messages?.map((message) => message.listingId) || []
      ),
    {
      enabled: messages != undefined && messages?.length > 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const dataSource = messages?.map((message) => {
    const listing = listings?.find(
      ({ listingId }) => message.listingId === listingId
    );
    const id = (
      <CopyDiv key={`copy-${message.id}`} value={message.id}>
        {message.id.slice(0, 8)}
      </CopyDiv>
    );
    const listingId = (
      <CopyDiv key={`copy-${message.listingId}`} value={message.listingId}>
        <a href={`/approvals?listingId=${listing?.listingId}`}>
          {listing?.listingId.slice(0, 8)}
        </a>
      </CopyDiv>
    );
    return { ...message, ...listing, id, listingId };
  });

  return (
    <Table
      total={dataSource?.length}
      columns={messageColumns}
      dataSource={dataSource || []}
      rowKey={'id'}
      onNext={() => setOffset(offset + limit)}
      onPrev={() => setOffset(offset - limit)}
    />
  );
};

export default MessagesByUser;
