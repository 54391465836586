import { useService } from './useServices';
import { UserModel } from '../lib/models';
import { useForm } from 'react-hook-form';

type UserEditForm = {
  handlers: {
    handleInputChange;
    submitForm;
    setValue;
    getValues;
    register;
    trigger;
  };
};

const useUserEditForm = (userId: string): UserEditForm => {
  const userService = useService<'user'>('user');

  const { register, setValue, getValues, trigger } = useForm({
    mode: 'onChange',
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setValue(name, value);
  };

  const submitForm = async (): Promise<UserModel> => {
    const values = getValues();
    return await userService.update(
      userId,
      values.firstName,
      values.lastName,
      values.email
    );
  };

  return {
    handlers: {
      handleInputChange,
      submitForm,
      setValue,
      getValues,
      register,
      trigger,
    },
  };
};

export { useUserEditForm };
