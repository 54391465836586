import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Card } from '../../components/UI/Card';
import { Button } from '../../components/UI/Button';
import { Tabs } from 'antd';
import AppPage from '../AppPage';
import { useHistory } from 'react-router';
import { tabs, ListingsTab, tabToListingStatus } from './constants';
import ListingsTable from './ListingsTable';
import ListingSearch from './ListingSearch';
import ExternalListingsTable from './ExternalListingTable';
const { TabPane } = Tabs;

const Listings = () => {
  const [activeTab, setActiveTab] = useState<string>(
    ListingsTab.SEARCH.toString()
  );
  const history = useHistory();

  const buttons = [
    <StyledButton
      key="listing-create"
      size="small"
      variant="primary"
      color="primaryGreen"
      onClick={() => history.push('/listings/add')}
    >
      New Listing
    </StyledButton>,
  ];

  const tabByStatus = (tab: string) => {
    if (tab.toLowerCase() === ListingsTab.SEARCH.toLowerCase()) {
      return <ListingSearch />;
    } else if (
      tab.toLowerCase() === ListingsTab.EXTERNAL_LISTINGS.toLowerCase()
    ) {
      return <ExternalListingsTable />;
    } else {
      return <ListingsTable status={tabToListingStatus(tab)} />;
    }
  };

  return (
    <AppPage>
      <Card title={'Listings'} buttons={buttons}>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
        >
          {tabs.map((tab) => (
            <TabPane tab={tab.label} key={tab.key.toLowerCase()}>
              {tabByStatus(tab.key)}
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </AppPage>
  );
};

export default Listings;

const StyledButton = styled(Button)`
  min-width: 150px;
`;
