import styled from '@emotion/styled';
import { useState } from 'react';
import { Button } from '../../components/UI/Button';
import { DefaultIcon } from '../../components/UI/Icon';
import { Select } from '../../components/UI/Select';
import {
  useConfig,
  useUpdateExternalListingsForm,
  useImportExternalListingsForm,
} from '../../hooks';
import { JobKind } from './constants';

export type JobFormProps = {
  kind: JobKind;
};

const JobForm: React.FC<JobFormProps> = ({ kind }) => {
  return kind === JobKind.IMPORT_EXTERNAL_LISTINGS ? (
    <ImportExternalListingsForm />
  ) : (
    <UpdateExternalListingsForm />
  );
};

export default JobForm;

const ImportExternalListingsForm: React.FC = ({}) => {
  const { locationOptions, handlers } = useImportExternalListingsForm();
  const { user } = useConfig();
  const [success, setSuccess] = useState<boolean>();

  return (
    <Form
      onSubmit={async (evt) => {
        const { status } = await handlers.submitForm(evt);
        setSuccess(status === 'OK');
      }}
    >
      <input type="hidden" value={user?.id} {...handlers.register('user_id')} />
      <Select
        handleChange={({ _, value }) => {
          if (value) {
            setSuccess(false);
            handlers.setValue('city', value.city);
            handlers.setValue('stateCode', value.stateCode);
          }
        }}
        selectOptions={locationOptions}
        defaultValue={locationOptions[0]}
      />
      <Button
        style={{
          position: 'relative',
          marginTop: '1.25rem',
          background: success ? 'rgb(224, 224, 224)' : 'black',
        }}
        disabled={success}
        size="medium"
        variant="secondary"
        color="white"
        type="submit"
      >
        Start Job
        {success ? (
          <SuccessIcon>
            <DefaultIcon name={'verified'} />
          </SuccessIcon>
        ) : (
          ''
        )}
      </Button>
    </Form>
  );
};

const UpdateExternalListingsForm: React.FC = ({}) => {
  const { handlers } = useUpdateExternalListingsForm();
  const { user } = useConfig();
  const [success, setSuccess] = useState<boolean>();

  return (
    <Form
      onSubmit={async (evt) => {
        const { status } = await handlers.submitForm(evt);
        setSuccess(status === 'OK');
      }}
    >
      <input type="hidden" value={user?.id} {...handlers.register('user_id')} />
      <Section>
        <Label htmlFor="excludeInternallyUpdatedListingsInLastHours">
          Exclude Listings In Past (Hours)
        </Label>
        <Input
          defaultValue={336}
          placeholder={'hours'}
          {...handlers.register('excludeInternallyUpdatedListingsInLastHours')}
        />
      </Section>
      <Section>
        <Label htmlFor="closeListingsWithoutExternalUpdateInLastHours">
          Close Listings In Past (Hours)
        </Label>
        <Input
          defaultValue={336}
          placeholder={'hours'}
          {...handlers.register(
            'closeListingsWithoutExternalUpdateInLastHours'
          )}
        />
      </Section>
      <Button
        style={{
          position: 'relative',
          marginTop: '1.25rem',
          background: success ? 'rgb(224, 224, 224)' : 'black',
        }}
        disabled={success}
        size="medium"
        variant="secondary"
        color="white"
        type="submit"
      >
        Start Job
        {success ? (
          <SuccessIcon>
            <DefaultIcon name={'verified'} />
          </SuccessIcon>
        ) : (
          ''
        )}
      </Button>
    </Form>
  );
};

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  position: relative;
`;

const Section = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Label = styled.label``;

const Input = styled.input`
  width: 100px;
  text-align: center;
`;

const SuccessIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: green;
  border-radius: 100%;
  transform: scale(0.75);
`;
