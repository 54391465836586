import styled from '@emotion/styled';
import { Button } from '../../components/UI/Button';
import { useAuth, useConfig, useService } from '../../hooks';
import { Card } from '../../components/UI/Card';
import { Icon, IconName } from '../../components/UI/Icon';
import { UserModel } from '../../lib/models';
import { useEffect, useState } from 'react';
import { jobConfigurations } from './constants';
import Job from './Job';
import AppPage from '../AppPage';

const Jobs = () => {
  return (
    <AppPage>
      <Card title="Jobs">
        <JobsWrapper>
          {jobConfigurations.map((job) => (
            <Job job={job}>Hey</Job>
          ))}
        </JobsWrapper>
      </Card>
    </AppPage>
  );
};

export default Jobs;

const JobsWrapper = styled.div`
  width: 100%;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
