import { RedoOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Alert, Form, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useService, useTikTokAuth } from '../../hooks';
import { DEFAULT_QUERY_CACHE_STALE_TIME } from '../../lib/constants';
import { associateBy, dateFormatFromString } from '../../lib/helpers';
import {
  ListingMedia,
  MediaType,
  RentalSocialMediaUpload,
  TikTokAuth,
} from '../../lib/models';
import { Button } from '../UI/Button';
import { ReactComponent as TikTokButtonBlack } from './Button_Black.svg';
import { TikTokAccountNames } from './constants';
import { ReactComponent as Logo } from './Logo.svg';
const { Option } = Select;

const TikTokUploadVideo: React.FC<{ videoUrl: string }> = ({ videoUrl }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [accountName, setAccountName] = useState<string>(TikTokAccountNames[0]);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { authByAccountName } = useTikTokAuth();
  const tiktokService = useService<'tiktok'>('tiktok');
  const listingMediaService = useService<'listingMedia'>('listingMedia');

  const { data: tiktokAuth } = useQuery<{}, {}, TikTokAuth | undefined>(
    [`tiktok-auth-by-account-name-${accountName}`],
    () => authByAccountName(accountName),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_CACHE_STALE_TIME,
      enabled: !!accountName,
    }
  );

  const { data: video } = useQuery<{}, {}, ListingMedia>(
    [`video-by-url`, { videoUrl }],
    () => listingMediaService.byUrl(MediaType.VIDEO, videoUrl),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_CACHE_STALE_TIME,
    }
  );

  const { data: socialMediaUploadsByAccountName } = useQuery<
    {},
    {},
    Map<string, RentalSocialMediaUpload>
  >(
    [`video-social-media-uploads`, { videoId: video?.id }],
    () =>
      listingMediaService
        .socialMediaUploadsById(MediaType.VIDEO, video!!.id)
        .then((res) => associateBy(res, 'socialMediaAccountName')),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_CACHE_STALE_TIME,
      enabled: !!video,
    }
  );

  const handleOk = async () => {
    const auth = await authByAccountName(accountName);

    if (!auth) return Promise.reject('Please signin to TikTok');

    setConfirmLoading(true);

    await tiktokService.uploadVideo(
      accountName,
      auth?.openId!!,
      auth?.accessToken!!,
      video?.id!!
    );

    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const oauth = async (accountName: string) => {
    const baseUrl = `${process.env.REACT_APP_UNICORN_ATLAS_HOST}`;
    const redirectUrl = `${baseUrl}/tiktok/login?account_name=${accountName}`;
    const response = await tiktokService.oauth(redirectUrl);
    window.open(response.redirectUrl, '_blank');
  };

  const refreshPage = (accountName: string) => {
    authByAccountName(accountName).then((auth) =>
      auth
        ? queryClient.invalidateQueries(
            `tiktok-auth-by-account-name-${accountName}`
          )
        : Promise.resolve()
    );
  };

  const formatDate = (date: string) => dateFormatFromString(date);

  return (
    <React.Fragment>
      <StyledTikTokButton
        onClick={() => setVisible(true)}
        size="medium"
        variant="primary"
        color="black"
      >
        <Logo />
      </StyledTikTokButton>

      <Modal
        title="Upload Video"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <StyledForm form={form}>
          <Form.Item label="Account name" name="accountName">
            <Select
              showSearch
              placeholder="Account name"
              optionFilterProp="children"
              defaultValue={accountName}
              onChange={(value) => setAccountName(value)}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {TikTokAccountNames.map((name) => (
                <Option key={name} value={name}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {accountName &&
            socialMediaUploadsByAccountName &&
            socialMediaUploadsByAccountName[accountName] && (
              <Alert
                message={`Warning: this video was previously uploaded on ${formatDate(
                  socialMediaUploadsByAccountName[accountName].uploadedAt
                )}`}
                type="warning"
              />
            )}
          {accountName && !tiktokAuth && (
            <Form.Item name="signIn">
              <StyledTikTokButtonBlack onClick={() => oauth(accountName)} />
              <StyledRedoOutlined onClick={() => refreshPage(accountName)} />
            </Form.Item>
          )}
        </StyledForm>
      </Modal>
    </React.Fragment>
  );
};

export default TikTokUploadVideo;

const StyledTikTokButtonBlack = styled(TikTokButtonBlack)`
  width: 327px;
  cursor: pointer;
`;

const StyledRedoOutlined = styled(RedoOutlined)`
  margin-left: 1rem;
  cursor: pointer;
  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    display: flex;
    justify-content: flex-start;

    .ant-form-item-label {
      width: 100px;
      display: flex;
    }

    .ant-form-item-control {
      max-width: 400px;
    }
  }
`;

const StyledTikTokButton = styled(Button)`
  svg {
    width: 100%;
    height: 30px;
  }
`;
