import type { apiClient } from '../../hooks/useServices';
import { ListingDetailsModel, PaginatedResponse } from '../models';

export type ListingDetailsService = {
  active: (
    offset: number,
    limit: number
  ) => Promise<PaginatedResponse<ListingDetailsModel>>;
  byId: (id: string) => Promise<ListingDetailsModel>;
  byIds: (ids: string[]) => Promise<ListingDetailsModel[]>;
  byProximity: (
    latitude: number,
    longitude: number,
    proximity: number,
    availableAt: string,
    offset: number,
    limit: number
  ) => Promise<PaginatedResponse<ListingDetailsModel>>;
  byRentalIds: (rentalIds: string[]) => Promise<ListingDetailsModel[]>;
  closed: (
    offset: number,
    limit: number
  ) => Promise<PaginatedResponse<ListingDetailsModel>>;
  deleted: (
    offset: number,
    limit: number
  ) => Promise<PaginatedResponse<ListingDetailsModel>>;
  pending: (
    offset: number,
    limit: number
  ) => Promise<PaginatedResponse<ListingDetailsModel>>;
};

export const listingDetailsServiceFactory = (
  apiClient: apiClient
): ListingDetailsService => ({
  active: (
    offset: number,
    limit: number
  ): Promise<PaginatedResponse<ListingDetailsModel>> =>
    apiClient<PaginatedResponse<ListingDetailsModel>>(
      `/atlas/listing-details/active`,
      {
        method: 'GET',
        body: {
          offset,
          limit,
        },
      }
    ),
  byId: (id: string): Promise<ListingDetailsModel> =>
    apiClient<ListingDetailsModel>(`/atlas/listing-details/by-id?id=${id}`, {
      method: 'GET',
    }),
  byIds: (ids: string[]): Promise<ListingDetailsModel[]> =>
    apiClient<ListingDetailsModel[]>(
      `/atlas/listing-details/by-ids?ids=${ids.join('&ids=')}`,
      {
        method: 'GET',
      }
    ),
  byProximity: (
    latitude: number,
    longitude: number,
    proximity: number,
    availableAt: string,
    offset: number,
    limit: number
  ): Promise<PaginatedResponse<ListingDetailsModel>> =>
    apiClient<PaginatedResponse<ListingDetailsModel>>(
      `atlas/listing-details/by-proximity`,
      {
        method: 'GET',
        body: {
          lat: latitude,
          lon: longitude,
          availableAt,
          proximity,
          offset,
          limit,
        },
      }
    ),
  byRentalIds: (rentalIds: string[]): Promise<ListingDetailsModel[]> =>
    apiClient<ListingDetailsModel[]>(
      `/atlas/listing-details/by-rental-ids?rental_ids=${rentalIds.join(
        '&rental_ids='
      )}`,
      {
        method: 'GET',
      }
    ),
  closed: (
    offset: number,
    limit: number
  ): Promise<PaginatedResponse<ListingDetailsModel>> =>
    apiClient<PaginatedResponse<ListingDetailsModel>>(
      `/atlas/listing-details/closed`,
      {
        method: 'GET',
        body: {
          offset,
          limit,
        },
      }
    ),
  deleted: (
    offset: number,
    limit: number
  ): Promise<PaginatedResponse<ListingDetailsModel>> =>
    apiClient<PaginatedResponse<ListingDetailsModel>>(
      `/atlas/listing-details/deleted`,
      {
        method: 'GET',
        body: {
          offset,
          limit,
        },
      }
    ),
  pending: (
    offset: number,
    limit: number
  ): Promise<PaginatedResponse<ListingDetailsModel>> =>
    apiClient<PaginatedResponse<ListingDetailsModel>>(
      `/atlas/listing-details/pending`,
      {
        method: 'GET',
        body: {
          offset,
          limit,
        },
      }
    ),
});
