/* @jsxImportSource @emotion/react */
import React from 'react';
import { ReactComponent as LogoSVG } from './logo.svg';
import { ReactComponent as LockupSVG } from './lockup.svg';
import { ReactComponent as SpinnerSVG } from './spinner.svg';
import { ReactComponent as WordmarkSVG } from './wordmark.svg';
import { ReactComponent as AtlasLogoSVG } from './atlas-logo.svg';
import { ReactComponent as AtlasSVG } from './atlas.svg';
import { ReactComponent as AtlasWordmarkSVG } from './atlas-wordmark.svg';
import { ReactComponent as UnicornAtlasSVG } from './unicorn-atlas.svg';

export const Logo = (props) => (
  <div css={{ color: 'black', width: '44px' }} {...props}>
    <LogoSVG css={{ width: '100%' }} />
  </div>
);

export const Lockup = (props) => (
  <div css={{ color: 'black', width: '99px' }} {...props}>
    <LockupSVG style={{ width: '100%' }} />
  </div>
);

export const Spinner = (props) => (
  <div {...props}>
    <SpinnerSVG style={{ width: '100%' }} />
  </div>
);

export const Wordmark = (props) => (
  <div css={{ width: '99px' }} {...props}>
    <WordmarkSVG style={{ width: '100%', fill: 'inherit' }} />
  </div>
);

export const Atlas = (props) => (
  <AtlasSVG style={{ height: '100%' }} {...props} />
);

export const AtlasLogo = (props) => <AtlasLogoSVG {...props} />;

export const AtlasWordmark = (props) => <AtlasWordmarkSVG {...props} />;

export const UnicornAtlas = (props) => <UnicornAtlasSVG {...props} />;
