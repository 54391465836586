import React, {
  useRef,
  useEffect,
  MutableRefObject,
  useState,
  RefObject,
} from 'react';

export type UseOutsideRefClickEvent = {
  clickedOutside: boolean;
};

const useOutsideClickEventByClassName = (
  className: string
): UseOutsideRefClickEvent => {
  const [clickedOutside, setClickedOutside] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (evt: Event) => {
      const isParentClicked = (evt.target as HTMLElement).closest(
        `.${className}`
      );
      if (!isParentClicked) {
        setClickedOutside(true);
      } else {
        setClickedOutside(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [className]);

  return {
    clickedOutside,
  };
};

export { useOutsideClickEventByClassName };
