import { ColumnType } from 'antd/lib/list';
import {
  AddressComponentModel,
  ListingDetailsModel,
  ListingModel,
  ListingStatus,
  MediaFormat,
  SelectOption,
} from './models';

export const queryParamsParser = (search: string): {} => {
  const pairs = search
    .slice(1, search.length)
    .split('&')
    .map((string) => string.split('='));
  let queryParams = {};
  pairs.forEach(([key, value]) => (queryParams[key] = value));
  return queryParams;
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const toDollars = (cents: number) => Math.trunc(cents / 100);

export const toUSD = (cents: number) =>
  currencyFormatter.format(toDollars(cents)).split('.')[0];

export const capitalize = (string: string) => {
  if (string.length <= 1) {
    return string.toString();
  } else {
    return (
      string[0].toUpperCase() + string.substring(1, string.length).toLowerCase()
    );
  }
};

export const hasEveryFieldWithValue = (obj: {}) =>
  Object.values(obj).every((value) => value);

const extractAddressData =
  (components: Array<AddressComponentModel>) =>
  (type: string, version = 'long_name'): string =>
    components.find(({ types }) => types.includes(type))?.[version] || '';

export const extractFieldValues = (geocodeResult) => {
  const extract = extractAddressData(geocodeResult[0].address_components);

  return {
    address1: `${extract('street_number')} ${extract('route')}`,
    city: extract('locality'),
    state: extract('administrative_area_level_1'),
    postalCode: extract('postal_code'),
    postalCode2: extract('postal_code_suffix'),
    countryCode: extract('country', 'short_name'),
  };
};

export const toUTC = (dateString: string) => new Date(dateString).toISOString();

export const dateInputFormat = (dateString: string): string => {
  const date = new Date(new Date(dateString).toLocaleDateString());
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString();
  const dt = date.getDate().toString();

  const formattedDt = dt.length <= 1 ? `0${dt}` : dt;
  const formattedMonth = month.length <= 1 ? `0${month}` : month;
  return `${year}-${formattedMonth}-${formattedDt}`;
};

export const dateFieldFormat = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString();
  const dt = date.getDate().toString();

  const formattedDt = dt.length <= 1 ? `0${dt}` : dt;
  const formattedMonth = month.length <= 1 ? `0${month}` : month;
  return `${formattedMonth}/${formattedDt}/${year.slice(2, 4)}`;
};

export const dateFormatFromString = (dateString: string): string => {
  const date = new Date(dateString);
  const month = date.toLocaleString('default', { month: 'long' });
  const dayOfMonth = date.getDate();
  const year = date.getFullYear();
  return `${month} ${dayOfMonth}, ${year}`;
};

export const timeFormatFromString = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleTimeString();
};

export const daysBetween = (date1: Date, date2: Date) => {
  const dayMilli = 1000 * 60 * 60 * 24;
  const timeDiff = date2.getTime() - date1.getTime();
  return Math.round(timeDiff / dayMilli);
};

export const addDays = (date: Date, numDays: number): Date => {
  date.setDate(date.getDate() + numDays);
  return date;
};

export const addHours = (date: Date, hours: number = 12): Date => {
  date.setHours(date.getHours() + hours);
  return date;
};

export class Range {
  start: number;
  end: number;

  constructor(start: number, end: number) {
    this.start = start;
    this.end = end;
  }

  map(func: (currentValue, index, arr) => any) {
    const keys = new Array(this.end + 1 - this.start).keys();
    return [...Array.from(keys)].map((_, i) => this.start + i).map(func);
  }
}

export const mimeTypeToMediaFormat = (mimeType) => {
  switch (mimeType) {
    case 'video/mp4':
      return MediaFormat.MP4;
    case 'video/quicktime':
      return MediaFormat.MOV;
    case 'image/jpg':
      return MediaFormat.JPG;
    case 'image/jpeg':
      return MediaFormat.JPEG;
    case 'image/png':
      return MediaFormat.PNG;
  }
};

String.prototype.capitalize = function (): string {
  if (this.length <= 1) {
    return this.toString();
  } else {
    return this[0].toUpperCase() + this.substring(1, this.length);
  }
};

export const snakeCaseToDisplayName = (str: string) => {
  return str
    .split('_')
    .map((word) => word.capitalize())
    .join(' ');
};

export const deriveListingStatus = (
  listing: ListingDetailsModel
): ListingStatus => {
  if (!listing.approvedAt && !listing.closedAt) {
    return ListingStatus.PENDING;
  } else if (listing.closedAt) {
    return ListingStatus.CLOSED;
  } else {
    return ListingStatus.ACTIVE;
  }
};

export const deriveListingStatusForListing = (
  listing: ListingModel
): ListingStatus => {
  if (listing.closedAt) {
    return ListingStatus.CLOSED;
  } else if (!listing.approvedAt) {
    return ListingStatus.PENDING;
  } else {
    return ListingStatus.ACTIVE;
  }
};

export const groupBy = <T, K>(items: T[], key: string): Map<string, T[]> => {
  const map: Map<string, T[]> = new Map();

  items.forEach((item) => {
    const mapKey = item[key];
    map[mapKey] = map[mapKey] ? [...map[mapKey], item] : [item];
  });
  return map;
};

export const associateBy = <T, K>(items: T[], key: string): Map<string, T> => {
  const map: Map<string, T> = new Map();

  items.forEach((item) => {
    const mapKey = item[key];
    map[mapKey] = map[mapKey] || item;
  });
  return map;
};

export const withoutColumns = (columns: any[], keysToRemove) => {
  const filteredColumns = columns.filter((obj) => {
    let columnWithKeyToRemove = Object.keys(obj).find(
      (key) => keysToRemove.indexOf(obj[key]) >= 0
    );
    return columnWithKeyToRemove ? false : true;
  });
  return filteredColumns;
};

export const sort = (a: any, b: any): number => {
  return a < b ? -1 : 1;
};

export const truncatedTextAtEndOfSentenceOrWord = (
  str: string,
  maxCharLimit: number = 350
) => {
  const truncated = str.substring(0, maxCharLimit);
  const nearestSentenceIdx = truncated.lastIndexOf('.');
  const nearestWordIdx = truncated.lastIndexOf(' ');
  const cutOffIndex =
    nearestSentenceIdx < nearestWordIdx ? nearestWordIdx : nearestSentenceIdx;

  return truncated.substring(0, cutOffIndex) + '...';
};

export const stringsToSelectOptions = (strings: string[]): SelectOption[] => {
  return strings.map((str) => {
    return { label: snakeCaseToDisplayName(str), value: str };
  });
};
