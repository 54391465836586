import { useEffect, useState } from 'react';
import { ListingMedia, MediaType } from '../lib/models';
import { useService } from './useServices';
import { useDropzone } from 'react-dropzone';
import { useConfig } from './useConfig';

type UseMediaGallery = {
  images: ListingMedia[];
  video?: ListingMedia;
  handlers: {
    deleteVideo;
    deleteImage;
    updateImageRanks;
    openFileDialog;
    getInputProps;
  };
};

export const sortByRank = (
  a: ListingMedia,
  b: ListingMedia,
  length: number
): number => (a.rank || length + 1) - (b.rank || length + 1);

const imageDeleteConfirmMsg = `Are you sure you want to delete this image? 
This action is final and cannot be undone.`;

const videoDeleteConfirmMsg = `Are you sure you want to delete this video? 
This action is final and cannot be undone.`;

const useMediaGallery = (listingId: string): UseMediaGallery => {
  const [images, setImages] = useState<ListingMedia[]>([]);
  const [video, setVideo] = useState<ListingMedia>();
  const listingMediaService = useService<'listingMedia'>('listingMedia');
  const { user } = useConfig();

  const handleUpload = (files) => {
    const videoFiles = files.filter((file) => file.type.match('video'));
    const imageFiles = files.filter((file) => file.type.match('image'));
    handleImageUpload(imageFiles);
    handleVideoUpload(videoFiles);
  };

  const { getInputProps, open: openFileDialog } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg, video/mp4, video/quicktime',
    onDrop: handleUpload,
    maxFiles: 12,
  });

  const handleImages = (images: ListingMedia[]) => setImages(images);

  const handleVideo = (video: ListingMedia) => setVideo(video);

  const deleteImage = async (groupId: string) => {
    if (window.confirm(imageDeleteConfirmMsg)) {
      await listingMediaService.deleteByGroupId(MediaType.IMAGE, groupId);
      setImages(images.filter((img) => img.groupId !== groupId));
    }
  };

  const deleteVideo = async (groupId: string) => {
    if (window.confirm(videoDeleteConfirmMsg)) {
      await listingMediaService.deleteByGroupId(MediaType.VIDEO, groupId);
      setVideo(undefined);
    }
  };

  const handleVideoUpload = async (files) => {
    const media = await listingMediaService.createMultiple(
      files,
      listingId,
      user?.id!!
    );
    setVideo(media[0]);
  };

  const handleImageUpload = async (files) => {
    const media = await listingMediaService.createMultiple(
      files,
      listingId,
      user?.id!!
    );
    setImages(images.concat([...media]));
  };

  const updateImageRanks = async (
    imageRanks: { imageId: string; rank: number }[]
  ) => {
    const updatedImages = await Promise.all(
      imageRanks.map(
        async (imageRank) =>
          await listingMediaService.updateRank(
            MediaType.IMAGE,
            imageRank.imageId,
            imageRank.rank
          )
      )
    );
    setImages(
      updatedImages.sort((a: ListingMedia, b: ListingMedia) =>
        sortByRank(a, b, images.length)
      )
    );
  };

  useEffect(() => {
    const setImages = async () => {
      const res = await listingMediaService.byListingId(
        MediaType.IMAGE,
        listingId
      );
      handleImages(
        res.sort((a: ListingMedia, b: ListingMedia) =>
          sortByRank(a, b, images.length)
        )
      );
    };
    const setVideo = async () => {
      const res = await listingMediaService.byListingId(
        MediaType.VIDEO,
        listingId
      );
      handleVideo(res[0]);
    };
    setImages();
    setVideo();
  }, [listingId]);

  return {
    images,
    video,
    handlers: {
      deleteImage,
      deleteVideo,
      updateImageRanks,
      openFileDialog,
      getInputProps,
    },
  };
};

export { useMediaGallery };
