import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { TOP_NAV_BAR_HEIGHT } from '../../lib/constants';

export const StyledNavBar = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.11);
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 2rem;
  padding-left: 280px;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    top: 0;
    bottom: auto;
    display: flex;
    height: ${TOP_NAV_BAR_HEIGHT}px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 10px;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #4f4f4f;
  font-size: 0.625rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MenuList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuItem = styled.li`
  margin-left: auto;
  transition: all 0.75 ease;

  &:hover {
    transform: scale(1.1, 1.1);
  }

  @media ${({ theme }) => theme.mediaQuery.medium} {
    width: 40px;
    height: 40px;
    border-radius: 48px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MenuItemText = styled.p`
  text-transform: uppercase;
  line-height: 1.125rem;
  margin-bottom: 0;
  margin-top: 0;

  &.discover-text {
    background: ${({ theme }) =>
      `-webkit-linear-gradient(270deg, ${theme.color.primary} 27.65%, ${theme.color.secondary} 100%)`};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
