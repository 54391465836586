import styled from '@emotion/styled';

export const inputWidth = '327px';

const AuthPage = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.colorMap.neutral.black};
  background-color: ${({ theme }) => theme.colorMap.neutral.white};
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 1rem 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media ${({ theme }) => theme.mediaQuery.large} {
    text-align: center;
    padding: calc(var(--vh, 1vh) * 15) 20vw;
  }

  .react-tel-input {
    font-size: 1em;
    max-width: ${inputWidth};
    margin: calc(var(--vh, 1vh) * 2) auto;

    .form-control {
      appearance: none;
      width: 100%;
      border-color: transparent;
      border-radius: 10px;
      padding: 18.5px 14px 18.5px 60px;
      box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.15);
      transition: box-shadow ease 0.25s, border-color ease 0.25s;
      color: #495057;
      font-size: 1.5em;
      font-weight: 500;
      letter-spacing: 0.18px;

      &.invalid-number {
        color: ${({ theme }) => theme.color.error};
      }

      &:disabled {
        background-color: #f8f8f8;
        color: lightgrey;
      }

      ::placeholder {
        color: #e0e0e0;
      }
    }

    .form-control--code {
      padding: 18.5px 60px 18.5px 65px;
      letter-spacing: 5px;
      text-align: center;
    }

    .form-control:focus {
      outline: 0;
      box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.15);

      &.invalid-number {
        box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.15);
      }
    }

    .country-list {
      width: ${inputWidth};
    }
  }
`;

export default AuthPage;
