import styled from '@emotion/styled';
import { useRef, useState } from 'react';
import { VideoListingDetailsModel } from '../../lib/models';

type VideoPreviewProps = {
  videoUrl: string;
};

const VideoPreview: React.FC<VideoPreviewProps> = ({ videoUrl }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [orientation, setOrientation] = useState<
    'portrait' | 'landscape' | undefined
  >();

  const onLoad = () => {
    if (videoRef && videoRef.current) {
      const orientation =
        videoRef.current.videoWidth > videoRef.current.videoHeight
          ? 'landscape'
          : 'portrait';
      setOrientation(orientation);
    }
  };

  return (
    <VideoContainer>
      <Video
        className={`${orientation || ''}`}
        onLoadedData={onLoad}
        controls
        ref={videoRef}
        src={videoUrl}
      />
    </VideoContainer>
  );
};

export default VideoPreview;

const VideoContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
`;

const Video = styled.video`
  height: auto;
  width: auto;

  &.landscape {
    width: 100%;
    height: auto;
  }

  &.portrait {
    height: 100%;
    width: auto;
    margin: 0 auto;
  }
`;
