import { IconName } from '../../components/UI/Icon';

export enum JobKind {
  IMPORT_EXTERNAL_LISTINGS = 'IMPORT_EXTERNAL_LISTINGS',
  UPDATE_EXTERNAL_LISTINGS = 'UPDATE_EXTERNAL_LISTINGS',
}

export interface JobConfiguration {
  icon: IconName;
  kind: JobKind;
  title: string;
  description: string[];
}

export const jobConfigurations: JobConfiguration[] = [
  {
    icon: 'vaulted-ceilings',
    kind: JobKind.IMPORT_EXTERNAL_LISTINGS,
    title: 'Pull External Listings',
    description: [
      'This creates an API pull request to update the listing queue with new listings for the selected city below.',
      'Please be mindful of making too many requests in a short period of time. All listings will be pushed to the listing queue.',
    ],
  },
  {
    icon: 'refresh-location',
    kind: JobKind.UPDATE_EXTERNAL_LISTINGS,
    title: 'Update External Listings',
    description: [
      'This creates an API pull request to check if an external listing, that is already on the Unicorn platform, is still available.',
      'This request is configurable and will close listings without an update in the last xx hrs.',
    ],
  },
];
