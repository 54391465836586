import { useState } from 'react';
import { useQuery } from 'react-query';
import CopyDiv from '../../components/CopyDiv';
import { Table } from '../../components/UI/Table';
import { useService } from '../../hooks';
import { withoutColumns } from '../../lib/helpers';
import {
  ListingDetailsModel,
  ListingStatus,
  PaginatedResponse,
} from '../../lib/models';
import { columns, listingDetailsResponseToDataSource } from './constants';

export type ListingsTableProps = {
  status: ListingStatus;
};

const ListingsTable: React.FC<ListingsTableProps> = ({ status }) => {
  const limit = 50;
  const [offset, setOffset] = useState<number>(0);
  const listingDetailsService = useService<'listingDetails'>('listingDetails');

  const listingsByStatus = async () => {
    if (status === ListingStatus.ACTIVE) {
      return listingDetailsService.active(offset, limit);
    } else if (status === ListingStatus.PENDING) {
      return listingDetailsService.pending(offset, limit);
    } else if (status === ListingStatus.CLOSED) {
      return listingDetailsService.closed(offset, limit);
    } else if (status === ListingStatus.DELETED) {
      return listingDetailsService.deleted(offset, limit);
    }
  };

  const columnsByStatus = () => {
    if (status === ListingStatus.ACTIVE) {
      return withoutColumns(columns, ['closedAt', 'deletedAt']);
    } else if (status === ListingStatus.PENDING) {
      return withoutColumns(columns, ['approvedAt', 'closedAt', 'deletedAt']);
    } else if (status === ListingStatus.CLOSED) {
      return withoutColumns(columns, ['approvedAt', 'deletedAt']);
    } else if (status === ListingStatus.DELETED) {
      return withoutColumns(columns, ['approvedAt', 'closedAt']);
    } else {
      return columns;
    }
  };

  const { data: paginatedResponse } = useQuery<
    {},
    {},
    PaginatedResponse<ListingDetailsModel>
  >(
    [`listings-by-pagination-${status}`, { offset, limit, status }],
    () => listingsByStatus(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const dataSource = listingDetailsResponseToDataSource(
    paginatedResponse?.results || []
  );

  return (
    <Table
      limit={limit}
      total={paginatedResponse?.total || 0}
      columns={columnsByStatus()}
      dataSource={dataSource}
      rowKey={'listingId'}
      onNext={() => setOffset(offset + limit)}
      onPrev={() => setOffset(offset - limit)}
    />
  );
};

export default ListingsTable;
