import type { apiClient } from '../../hooks/useServices';
import { VideoListingDetailsModel, PopularityRankingsModel } from '../models';

export type PopularityRankingsCreateBody = {
  listingId: string;
  rank: number;
};

export type UpsertPopularityRankingsRequestBody = {
  rankings: PopularityRankingsCreateBody[];
};

export type GenerateListingPopularityRequestBody = {
  count: number;
  lookbackDays: number;
};

export type DiscoverService = {
  getPopularListings: (
    offset: number,
    limit: number
  ) => Promise<VideoListingDetailsModel[]>;
  generateByListingPopularity: (
    body: GenerateListingPopularityRequestBody
  ) => Promise<PopularityRankingsModel[]>;
  upsertPopularityRankings: (
    body: UpsertPopularityRankingsRequestBody
  ) => Promise<PopularityRankingsModel[]>;
};

export const discoverServiceFactory = (
  apiClient: apiClient
): DiscoverService => ({
  getPopularListings: (
    offset: number,
    limit: number
  ): Promise<VideoListingDetailsModel[]> =>
    apiClient<VideoListingDetailsModel[]>(
      `atlas/discover/get-popular-listings`,
      {
        method: 'GET',
        body: {
          offset,
          limit,
        },
      }
    ),
  generateByListingPopularity: (
    body: GenerateListingPopularityRequestBody
  ): Promise<PopularityRankingsModel[]> =>
    apiClient<PopularityRankingsModel[]>(
      `atlas/discover/generate-by-listing-popularity`,
      {
        method: 'PUT',
        body,
      }
    ),
  upsertPopularityRankings: (
    body: UpsertPopularityRankingsRequestBody
  ): Promise<PopularityRankingsModel[]> =>
    apiClient<PopularityRankingsModel[]>(
      `atlas/popularity-rankings/upsert-rankings`,
      {
        method: 'PUT',
        body,
      }
    ),
});
