import { useService } from './useServices';
import { SelectOption } from '../lib/models';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { JobResponse } from '../lib/services/externalListing';

type UseImportExternalListingsForm = {
  locationOptions: SelectOption[];
  handlers: {
    submitForm;
    setValue;
    getValues;
    register;
    trigger;
  };
};

const useImportExternalListingsForm = (): UseImportExternalListingsForm => {
  const [locationOptions, setLocationOptions] = useState<SelectOption[]>([]);
  const externalListingService =
    useService<'externalListing'>('externalListing');

  const { register, setValue, getValues, trigger } = useForm({
    mode: 'onChange',
  });

  const submitForm = async (evt): Promise<JobResponse> => {
    evt.preventDefault();
    const values = getValues();
    const body = {
      city: values.city,
      state_code: values.stateCode,
      user_id: values.userId,
    };
    return await externalListingService.importListings(body);
  };

  useEffect(() => {
    const options = async () => {
      const opts = await externalListingService.locationOptions();
      setLocationOptions(
        opts.map((opt): SelectOption => {
          return { label: opt.city, value: opt };
        })
      );
    };
    if (locationOptions.length === 0) options();
  }, locationOptions);

  return {
    locationOptions,
    handlers: {
      submitForm,
      setValue,
      getValues,
      register,
      trigger,
    },
  };
};

export { useImportExternalListingsForm };
