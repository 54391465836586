export class ApiUnauthorizedError extends Error {}
export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type FetchOptions = {
  method?: HTTPMethod;
  body?: Record<string, any>;
  headers?: Record<string, string>;
};

type ApiRequestOptions = {
  token: string;
  userId?: string;
};

export const snakeCase = (string) =>
  string.replace(/([A-Z])/g, '_$1').toLowerCase();

export const isObject = (obj) => {
  return (
    obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
  );
};

export const camelCaseToSnakeCase = (obj: any) => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      n[snakeCase(k)] = camelCaseToSnakeCase(obj[k]);
    });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return camelCaseToSnakeCase(i);
    });
  }

  return obj;
};

export const snakeCaseToCamelCase = (data) => {
  // base case: pass through primitve values
  switch (typeof data) {
    case 'string':
    case 'boolean':
    case 'number':
      return data;
    case 'object':
      if (data === null) return data;
  }

  // for arrays, map over values in order to convert objects
  if (Array.isArray(data)) return data.map(snakeCaseToCamelCase);

  return Object.entries(data)
    .map(([k, v]: [string, any]): [string, any] => [
      k.replace(/_(.)/g, (_, $1) => $1.toUpperCase()),
      snakeCaseToCamelCase(v),
    ])
    .reduce((acc, [k, v]) => Object.assign(acc, { [k]: v }), {});
};

// Safari does not support lookbehind assertion in regex,
// @see https://caniuse.com/js-regexp-lookbehind
// so this function takes some extra steps to deal with slashes in URLs.
// Admittedly this may be over-engineered, but it's worth it so that
// devs can `request('path/to/resource')` and `request('/path/to/resource')`.
const buildUrl = (path: string): string =>
  (process.env.REACT_APP_UNICORN_API_HOST || '').replace(/\/$/g, '') +
  `/${path}`.replace(/\/{2,}/, '/');

function request(
  path: string,
  { method = 'GET', body, headers, ...options }: FetchOptions = {},
  { token, userId }: ApiRequestOptions
) {
  const formattedBody = body ? camelCaseToSnakeCase(body) : body;
  const isGetRequest = method.toLowerCase() === 'get';

  const query: string =
    isGetRequest && formattedBody
      ? '?' +
        Object.entries(formattedBody)
          .map((pair) => pair.join('='))
          .join('&')
      : '';

  return fetch(buildUrl(path + query), {
    method,
    body: isGetRequest ? null : JSON.stringify(formattedBody),
    ...options,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      ...(userId ? { 'X-Unicorn-Id': userId } : null),
    },
  })
    .then((response) => {
      if (!response.ok) {
        if (false && response.status === 401) {
          throw new ApiUnauthorizedError();
        }
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(snakeCaseToCamelCase);
}

export { request };
