import { ColumnTypes } from '../../components/UI/Table';
import { dateFormatFromString } from '../../lib/helpers';

const alphabeticalOrderSort = (a, b) => {
  const nonEmptyStringA = !a ? 'zzzzzz' : a;
  const nonEmptyStringB = !b ? 'zzzzzz' : b;
  return nonEmptyStringA < nonEmptyStringB ? -1 : 1;
};

export const usersTableColumnHeaders: (ColumnTypes[number] & {
  editable?: boolean;
  dataIndex: string;
})[] = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    editable: false,
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: (a, b) =>
      alphabeticalOrderSort(
        a.firstName?.toLowerCase(),
        b.firstName?.toLowerCase()
      ),
    sortDirections: ['descend', 'ascend'],
    editable: true,
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: (a, b) =>
      alphabeticalOrderSort(
        a.lastName?.toLowerCase(),
        b.lastName?.toLowerCase()
      ),
    sortDirections: ['descend', 'ascend'],
    editable: true,
  },
  {
    title: 'Phone',
    dataIndex: 'mobileNumber',
    key: 'mobileNumber',
    editable: false,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    editable: true,
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value: string) => dateFormatFromString(value),
    sorter: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
    sortDirections: ['descend', 'ascend'],
    editable: false,
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    render: (value: string) => dateFormatFromString(value),
    key: 'updatedAt',
    editable: false,
  },
  {
    title: 'Deleted At',
    dataIndex: 'deletedAt',
    render: (value: string) => (value ? dateFormatFromString(value) : ''),
    key: 'deletedAt',
    editable: false,
  },
  {
    title: 'Listing Count',
    dataIndex: 'listingsCount',
    key: 'listingsCount',
    sorter: (a, b) => (a.listings_count < b.listings_count ? -1 : 1),
    sortDirections: ['descend', 'ascend'],
    editable: false,
  },
  {
    title: 'Listings',
    dataIndex: 'userListings',
    key: 'userListings',
    editable: false,
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
    key: 'edit',
    editable: false,
  },
];
