import styled from '@emotion/styled';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Table } from '../../components/UI/Table';
import { useService } from '../../hooks';
import { associateBy } from '../../lib/helpers';
import {
  ExternalListingModel,
  ListingDetailsModel,
  PaginatedResponse,
} from '../../lib/models';
import {
  externalListingColumns,
  externalListingToDataSource,
} from './constants';

const ExternalListingsTable: React.FC = () => {
  const limit = 50;
  const [offset, setOffset] = useState<number>(0);
  const [hasMatterport, setHasMatterport] = useState<boolean>(false);
  const externalListingService =
    useService<'externalListing'>('externalListing');
  const listingDetailsService = useService<'listingDetails'>('listingDetails');

  const { data: paginatedResponse } = useQuery<
    {},
    {},
    PaginatedResponse<ExternalListingModel>
  >(
    [`external-listings-filter`, { hasMatterport, offset, limit }],
    () =>
      externalListingService.filter({
        hasMatterport: hasMatterport,
        offset: offset,
        limit: limit,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const listingIds =
    paginatedResponse?.results.map((res) => res.listingId) || [];

  const { data: listingsWithVideoById } = useQuery<
    {},
    {},
    Map<string, ListingDetailsModel>
  >(
    [`listings-with-videos`, { listingIds: listingIds }],
    () =>
      listingDetailsService.byIds(listingIds).then((listings) => {
        const filtered = listings.filter(
          (listing) => listing.videoUrls.length > 0
        );
        return associateBy(filtered, 'listingId');
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const dataSource = externalListingToDataSource(
    paginatedResponse?.results || [],
    listingsWithVideoById || ({} as Map<string, ListingDetailsModel>)
  );

  return (
    <React.Fragment>
      <Filters>
        <p>Filters</p>
        <Checkbox onChange={() => setHasMatterport(!hasMatterport)}>
          Matterport
        </Checkbox>
      </Filters>
      <Table
        limit={limit}
        key={'id'}
        total={paginatedResponse?.total || 0}
        columns={externalListingColumns}
        dataSource={dataSource}
        rowKey={'id'}
        onNext={() => setOffset(offset + limit)}
        onPrev={() => setOffset(offset - limit)}
      />
    </React.Fragment>
  );
};

export default ExternalListingsTable;

const Filters = styled.div`
  p {
    font-size: 1rem;
    font-weight: 700;
  }
`;
