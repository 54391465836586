import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Tooltip } from 'antd';
import React from 'react';
import { DefaultIcon } from '../../components/UI/Icon';
import { MapMarkerProps } from './RadiusLayer';

export type ListingMarkerProps = MapMarkerProps & {
  title: string;
  listingId: string;
};

const ListingMarker: React.FC<ListingMarkerProps> = ({
  lat,
  lng,
  title,
  listingId,
}) => {
  const { color, colorMap } = useTheme();

  const titleNode = (
    <span>
      <a
        style={{ color: colorMap.neutral.white }}
        href={`/approvals?listingId=${listingId}`}
      >
        {title}
      </a>
    </span>
  );

  return (
    <div
      key={title}
      style={{ position: 'absolute', transform: 'translate(-50% -50%)' }}
    >
      <Tooltip
        arrowPointAtCenter
        color={color.primaryPink}
        placement="top"
        trigger={['click']}
        title={titleNode}
      >
        <IconRadius className={'icon'}>
          <IconWrapper>
            <DefaultIcon name="home" />
          </IconWrapper>
        </IconRadius>
      </Tooltip>
    </div>
  );
};

export default ListingMarker;

const IconRadius = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 112, 163, 0.24);
  border-radius: 50% !important;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(109.73deg, #ff70a3 -8.89%, #ff2d78 91.92%);
  color: white;
`;
