import { useEffect } from 'react';
import { useMediaGallery } from '../../hooks';
import { Button } from '../../components/UI/Button';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';

type ListingMediaProps = {
  listingId: string;
};

const ListingMedia = ({ listingId }: ListingMediaProps) => {
  const history = useHistory();
  const { images, video, handlers } = useMediaGallery(listingId);

  useEffect(() => {
    if (images.length > 0 || video) {
      history.push(`/approvals?listingId=${listingId}`);
    }
  }, [images, video]);

  return (
    <ListingMediaWrapper>
      {!images.length && !video ? (
        <MediaUpload>
          <MediaUploadDescription>
            Drop media here to upload...
          </MediaUploadDescription>
          <Button
            onClick={handlers.openFileDialog}
            size="large"
            variant="primary"
            color="primaryGreen"
          >
            Browse
          </Button>
          <input type="hidden" {...handlers.getInputProps()} />
        </MediaUpload>
      ) : (
        ''
      )}
    </ListingMediaWrapper>
  );
};

export default ListingMedia;

export const ListingMediaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const MediaUpload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 780px;
  height: 300px;
  background: rgba(20, 99, 110, 0.12);
  border-radius: 16px;
`;

export const MediaUploadDescription = styled.p`
  font-size: 1.125rem;
  font-weight: 700;
  color: ${({ theme }) => theme.color.primaryGreen};
`;
