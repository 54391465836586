import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useOutsideClickEventByClassName, useService } from '../../hooks';
import { PaginatedResponse, QrCode } from '../../lib/models';
import {
  columns,
  QR_CODE_BASE_URL,
  QUERY_CACHE_KEY_QR_BY_PAGINATION,
} from './constants';
import { EditableTable } from '../../components/UI/Table';
import { QRCodeSVG } from 'qrcode.react';
import { DefaultIcon } from '../../components/UI/Icon';
import { Button } from '../../components/UI/Button';
import { Form, Spin, Popconfirm, message, Input } from 'antd';
import { QrCodeUpdateBody } from '../../lib/services/qrCode';
import { DEFAULT_QUERY_CACHE_STALE_TIME } from '../../lib/constants';
// Variables
const limit = 50;
const editableRowClassNameBase = 'editable-row';
// Component
const ActiveTab: React.FC = () => {
  const [offset, setOffset] = useState<number>(0);
  const [editableCode, setEditableCode] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const qrCodeService = useService<'qrCode'>('qrCode');

  const { data: paginatedResponse } = useQuery<
    {},
    {},
    PaginatedResponse<QrCode>
  >(
    [QUERY_CACHE_KEY_QR_BY_PAGINATION, { offset, limit }],
    () => qrCodeService.byPagination(offset, limit),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_CACHE_STALE_TIME,
    }
  );

  const EditButton: React.FC<{ qrCode: QrCode }> = ({
    qrCode: { redirectUrl, location, code },
  }) => {
    return (
      <div
        onClick={(evt) => {
          evt.stopPropagation();
          form.setFieldsValue({
            redirectUrl,
            location: `${location?.latitude}, ${location?.longitude}`,
            code,
          });
          setEditableCode(code);
        }}
      >
        <DefaultIcon name="edit" />
      </div>
    );
  };

  const SaveAndDeleteButtons: React.FC<{ qrCode: QrCode }> = ({
    qrCode: { id, code },
  }) => {
    const { clickedOutside } = useOutsideClickEventByClassName(
      `${editableRowClassNameBase}-${code}`
    );

    useEffect(() => {
      if (clickedOutside) {
        setEditableCode('');
      }
    }, [clickedOutside]);

    return (
      <UpdateButtonsContainer>
        <StyledButton
          type="submit"
          key="listing-create"
          size="small"
          variant="primary"
          color="primaryPink"
          onClick={form.submit}
        >
          Save
        </StyledButton>
        <Popconfirm
          title="Are you sure to delete this task?"
          onConfirm={async () => {
            await qrCodeService.delete({ id });
            queryClient.invalidateQueries(QUERY_CACHE_KEY_QR_BY_PAGINATION);

            message.success(`Deleted ${code}`);
          }}
          okText="Yes"
          cancelText="No"
        >
          <StyledButton
            type="submit"
            key="listing-create"
            size="small"
            variant="secondary"
            color="primaryPink"
          >
            Delete
          </StyledButton>
        </Popconfirm>
      </UpdateButtonsContainer>
    );
  };

  const onFinish = async (values: any) => {
    setIsSubmitting(true);
    try {
      await form.validateFields();
    } catch {
      setIsSubmitting(false);
    }
    const [latitude, longitude] = values?.location.split(',');
    const body: QrCodeUpdateBody = {
      code: editableCode,
      redirectUrl: values.redirectUrl,
      location: { latitude, longitude },
    };
    await qrCodeService.updateRedirectUrlByCode(body);
    queryClient.invalidateQueries(QUERY_CACHE_KEY_QR_BY_PAGINATION);

    setTimeout(() => {
      setEditableCode('');
      setIsSubmitting(false);
    }, 1000);
  };

  const dataSource =
    paginatedResponse?.results
      ?.sort((a, b) => (a.code < b.code ? -1 : 1))
      ?.map((item) => {
        const qr = (
          <StyledQrCodeSVG value={`${QR_CODE_BASE_URL}/qr/${item.code}`} />
        );
        const edit =
          editableCode === item.code ? (
            <SaveAndDeleteButtons qrCode={item} />
          ) : (
            <EditButton qrCode={item} />
          );
        const redirectUrl =
          editableCode !== item.code ? (
            <a href={item.redirectUrl} target="_blank">
              {item.redirectUrl}
            </a>
          ) : (
            item.redirectUrl
          );

        const location =
          editableCode !== item.code ? (
            <a
              href={`/map?lat=${item.location?.latitude}&lng=${item.location?.longitude}&showQrCodes=true`}
            >
              {item.location?.latitude}, {item.location?.longitude}
            </a>
          ) : (
            item.location
          );

        return { ...item, qr, edit, redirectUrl, location };
      }) || [];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: QrCode) => ({
        record: record,
        inputNode: <Input placeholder={col.placeholder} />,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: editableCode === record.code,
        required: col.required,
        message: col.message,
      }),
    };
  });

  return (
    <Spin className="unicorn-spinner" spinning={isSubmitting}>
      <Form onFinish={onFinish} form={form}>
        <EditableTable
          total={dataSource.length}
          limit={limit}
          columns={mergedColumns}
          dataSource={dataSource}
          rowKey={'code'}
          rowClassName={(record) =>
            `${editableRowClassNameBase}-${record.code}`
          }
          onNext={() => setOffset(offset + limit)}
          onPrev={() => setOffset(offset - limit)}
        />
      </Form>
    </Spin>
  );
};

export default ActiveTab;

const StyledQrCodeSVG = styled(QRCodeSVG)`
  width: 75px;
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;

const UpdateButtonsContainer = styled.div`
  display: flex;
  button {
    margin: 0 0.5rem;
  }
`;
