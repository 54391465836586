import styled from '@emotion/styled';
import { Icon } from '../../components/UI/Icon';
import { JobConfiguration } from './constants';
import JobForm from './JobForm';

type JobProps = {
  job: JobConfiguration;
};

const Job: React.FC<JobProps> = ({ job }) => {
  const JobIcon = Icon(job.icon);
  return (
    <JobWrapper>
      <IconWrapper>
        <JobIcon />
      </IconWrapper>
      <Title>{job.title}</Title>
      {job.description.map((content) => (
        <Description>{content}</Description>
      ))}
      <JobForm kind={job.kind} />
    </JobWrapper>
  );
};

export default Job;

const JobWrapper = styled.div`
  padding: 1.25rem;
  width: 327px;
  height: 436px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${({ theme }) => theme.colorMap.neutral.white};
  border: 1px solid ${({ theme }) => theme.colorMap.gray.gray3};
  box-sizing: border-box;
  border-radius: 16px;
  margin-right: 1.25rem;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 1.25rem 0rem;
`;

const Description = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 0.5rem 0rem;
`;

const IconWrapper = styled.div`
  background: #ffbbc1;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
