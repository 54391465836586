import { SliderInput } from '../../components/UI/SliderInput';
import { useConfig } from '../../hooks/index';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import {
  Form,
  FormColumn,
  FormSection,
  FormSectionLabel,
  FormDetailInputSection,
  FormInput,
  MultiSelectWrapper,
  SelectWrapper,
  FormContent,
  FormTextArea,
} from './components';
import { MultiSelect, Select } from '../../components/UI/Select';
import { useListingCreateForm } from '../../hooks/useListingCreateForm';
import { Button } from '../../components/UI/Button';
import React, { useEffect } from 'react';
import { extractFieldValues } from '../../lib/helpers';

type ListingCreateFormProps = {
  onSubmitCallback: (listingId: string) => void;
};

const ListingCreateForm: React.FC<ListingCreateFormProps> = ({
  onSubmitCallback,
}) => {
  const { user } = useConfig();
  const {
    amenityOptions,
    rentalTypeOptions,
    listingTypeOptions,
    defaultAmenities,
    isValid,
    handlers,
  } = useListingCreateForm();

  const setAddressValues = (input) => {
    Object.entries(extractFieldValues(input)).forEach(([field, value]) =>
      handlers.setValue(field, value)
    );
    (async () => {
      await handlers.trigger('address1');
    })();
  };

  const onSubmit = async (evt) => {
    evt.preventDefault();
    const listingDetails = await handlers.submitForm();
    onSubmitCallback(listingDetails.listingId);
  };

  useEffect(() => {
    if (defaultAmenities) {
      handlers.handleMultiSelect(
        'amenities',
        defaultAmenities.map((option) => option.value)
      );
    }
  }, [defaultAmenities]);

  const setDefaultRatings = () => {
    handlers.setValue('landlordRating', 3);
    handlers.setValue('noiseLevel', 3);
    handlers.setValue('naturalLight', 3);
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormContent>
        {/* Details */}
        <FormColumn>
          <FormSection className={'details'}>
            <FormSectionLabel>Rental Details</FormSectionLabel>
            <AddressAutocomplete onChange={setAddressValues} value={null} />
            <input
              type="hidden"
              {...handlers.register('address1', { required: true })}
            />
            <input type="hidden" {...handlers.register('city')} />
            <input type="hidden" {...handlers.register('state')} />
            <input type="hidden" {...handlers.register('postalCode')} />
            <input type="hidden" {...handlers.register('countryCode')} />
            <input
              type="hidden"
              value={user?.id}
              {...handlers.register('createdBy')}
            />
            <input
              type="hidden"
              value={user?.id}
              {...handlers.register('userId')}
            />
            <FormDetailInputSection>
              <FormInput
                onChange={handlers.handleInputChange}
                className="form-input"
                type="text"
                placeholder="Apt./Suite"
                {...handlers.register('address2')}
              />
              <FormInput
                onChange={handlers.handleInputChange}
                className="form-input"
                type="number"
                placeholder="Monthly Rent"
                {...handlers.register('monthlyRent')}
              />
            </FormDetailInputSection>
          </FormSection>

          <FormSection>
            <FormSectionLabel>Lease Start</FormSectionLabel>
            <FormInput
              onChange={handlers.handleInputChange}
              className="form-input"
              type="date"
              placeholder="Available Date"
              {...handlers.register('availableAt', { required: true })}
            />
          </FormSection>
          <FormSection>
            <FormSectionLabel>Lease End</FormSectionLabel>
            <FormInput
              onChange={(evt) => {
                const availableAt = handlers.getValues().availableAt;
                if (availableAt) {
                  handlers.handleLeaseDurationDays(
                    availableAt,
                    evt.target.value
                  );
                }
              }}
              className="form-input"
              type="date"
              placeholder="Lease End"
              {...handlers.register('leaseEnd')}
            />
          </FormSection>

          <FormSection>
            <FormSectionLabel>Rental Type</FormSectionLabel>
            <SelectWrapper className="form-input">
              <Select
                handleChange={(option) =>
                  handlers.setValue('rentalType', option.value)
                }
                selectOptions={rentalTypeOptions}
                {...handlers.register('rentalType', { required: true })}
              />
            </SelectWrapper>
          </FormSection>
          <FormSection>
            <FormSectionLabel>Listing Type</FormSectionLabel>
            <SelectWrapper className="form-input">
              <Select
                handleChange={(option) =>
                  handlers.setValue('listingType', option.value)
                }
                selectOptions={listingTypeOptions}
                {...handlers.register('listingType', { required: true })}
              />
            </SelectWrapper>
          </FormSection>
        </FormColumn>

        {/* Ratings */}
        <FormColumn>
          <FormSection>
            <FormSectionLabel>Bedrooms</FormSectionLabel>
            <SliderInput
              id="numBedrooms"
              initialValue={0}
              from={0}
              to={8}
              onChangeCallback={(value) =>
                handlers.setValue('numBedrooms', value)
              }
              {...handlers.register('numBedrooms', { required: true, min: 0 })}
            />
          </FormSection>
          <FormSection>
            <FormSectionLabel>Bathrooms</FormSectionLabel>
            <SliderInput
              id="numBathrooms"
              initialValue={0}
              from={0}
              to={5}
              step={0.5}
              onChangeCallback={(value) =>
                handlers.setValue('numBathrooms', value)
              }
              {...handlers.register('numBathrooms', { required: true, min: 0 })}
            />
          </FormSection>
          <FormSection>
            <FormSectionLabel>Parking Spots</FormSectionLabel>
            <SliderInput
              id="numParkingSpots"
              initialValue={0}
              from={0}
              to={5}
              step={1}
              onChangeCallback={(value) =>
                handlers.setValue('numParkingSpots', value)
              }
              {...handlers.register('numParkingSpots', {
                required: true,
                min: 0,
              })}
            />
          </FormSection>
          <FormSection>
            <FormSectionLabel>Natural Light</FormSectionLabel>
            <SliderInput
              id="naturalLight"
              initialValue={3}
              from={1}
              to={5}
              step={1}
              onChangeCallback={(value) =>
                handlers.setValue('naturalLight', value)
              }
              {...handlers.register('naturalLight', { required: true, min: 1 })}
            />
          </FormSection>
          <FormSection>
            <FormSectionLabel>Noise Level</FormSectionLabel>
            <SliderInput
              id="noiseLevel"
              initialValue={3}
              from={1}
              to={5}
              step={1}
              onChangeCallback={(value) =>
                handlers.setValue('noiseLevel', value)
              }
              {...handlers.register('noiseLevel', { required: true, min: 1 })}
            />
          </FormSection>
          <FormSection>
            <FormSectionLabel>Landlord Rating</FormSectionLabel>
            <SliderInput
              id="landlordRating"
              initialValue={3}
              from={1}
              to={5}
              step={1}
              onChangeCallback={(value) =>
                handlers.setValue('landlordRating', value)
              }
              {...handlers.register('landlordRating', {
                required: true,
                min: 1,
              })}
            />
          </FormSection>
          {
            // Required to register default ratings
            setDefaultRatings()
          }
        </FormColumn>

        {/* Amenities & Descrption */}
        <FormColumn>
          <FormSection>
            <FormSectionLabel>Amenities</FormSectionLabel>
            <MultiSelectWrapper>
              {amenityOptions ? (
                <MultiSelect
                  defaultValues={defaultAmenities}
                  handleChange={(options) =>
                    handlers.handleMultiSelect(
                      'amenities',
                      options.map((option) => option.value)
                    )
                  }
                  selectOptions={amenityOptions}
                  {...handlers.register('amenities')}
                />
              ) : (
                ''
              )}
            </MultiSelectWrapper>
          </FormSection>
          <FormSection>
            <FormSectionLabel>Description</FormSectionLabel>
            <FormTextArea
              onChange={handlers.handleInputChange}
              className="form-input"
              {...handlers.register('description', { required: false })}
            />
          </FormSection>
        </FormColumn>
      </FormContent>
      <Button
        type="submit"
        style={{ height: '50px', width: '100%', margin: '2.0rem auto 6.0rem' }}
        size="large"
        variant="primary"
        color="primaryGreen"
        disabled={!isValid}
      >
        Submit
      </Button>
    </Form>
  );
};

export default ListingCreateForm;
