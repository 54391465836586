/* @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import {
  HomeLogo,
  HomeCta,
  HomeActions,
  HomeButton,
  AuthHomeWrapper,
  AuthHomeSection,
  AuthVideoPoster,
  AuthVideo,
} from './components';
import { useMediaQuery } from '../../hooks';
import videoSmall from './dreamscape_low.mp4';
import videoLarge from './dreamscape.mp4';
import { Link } from 'react-router-dom';
import poster from './dreamscape_poster.jpg';

const Home = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const onPlay = () => setIsLoaded(true);
  const { mediaQuery } = useTheme();
  const minWidthMedium = useMediaQuery(mediaQuery.medium);
  const video = minWidthMedium ? videoLarge : videoSmall;

  return (
    <AuthHomeWrapper>
      <AuthVideo
        autoPlay
        onPlaying={onPlay}
        poster={poster}
        muted
        playsInline
        loop
        src={video}
      />
      {!isLoaded && <AuthVideoPoster src={poster} />}
      <AuthHomeSection>
        <HomeCta>
          <HomeLogo />
        </HomeCta>
        <HomeActions>
          <Link to="/login" className="primary" component={HomeButton}>
            Sign In
          </Link>
        </HomeActions>
      </AuthHomeSection>
    </AuthHomeWrapper>
  );
};

export default Home;
