import { useService } from './useServices';
import { useForm } from 'react-hook-form';
import { JobResponse } from '../lib/services/externalListing';

type UseUpdateExternalListingsForm = {
  handlers: {
    submitForm;
    setValue;
    getValues;
    register;
    trigger;
  };
};

const useUpdateExternalListingsForm = (): UseUpdateExternalListingsForm => {
  const externalListingService =
    useService<'externalListing'>('externalListing');

  const { register, setValue, getValues, trigger } = useForm({
    mode: 'onChange',
  });

  const submitForm = async (evt): Promise<JobResponse> => {
    evt.preventDefault();
    const values = getValues();
    const body = {
      user_id: values.userId,
      exclude_internally_updated_listings_in_last_hours:
        values.excludeInternallyUpdatedListingsInLastHours,
      close_listings_without_external_update_in_last_hours:
        values.closeListingsWithoutExternalUpdateInLastHours,
    };
    return await externalListingService.updateListings(body);
  };

  return {
    handlers: {
      submitForm,
      setValue,
      getValues,
      register,
      trigger,
    },
  };
};

export { useUpdateExternalListingsForm };
