import { useEffect, useState } from 'react';
import { ListingDetailsModel, UserModel } from '../lib/models';
import { useService } from './index';

type UseApprovalsHook = {
  limit: number;
  offset: number;
  listings: Array<ListingDetailsModel>;
  usersById: Map<string, UserModel>;
  isEditable: boolean;
  handlers: {
    handleListings;
    handleUsers;
    handleIsEditable;
    close;
    approve;
    incrementOffset;
  };
};

const useApprovals = (queryParams: {
  offset?: number;
  limit?: number;
  listingId?: string;
}): UseApprovalsHook => {
  const limit = queryParams.limit || 1;
  const [offset, setOffset] = useState<number>(queryParams.offset || 0);
  const [listings, setListings] = useState<Array<ListingDetailsModel>>([]);
  const [usersById, setUsersById] = useState<Map<string, UserModel>>(new Map());
  const [isEditable, setIsEditable] = useState<boolean>(true);
  const listingService = useService<'listing'>('listing');
  const listingDetailsService = useService<'listingDetails'>('listingDetails');
  const userService = useService<'user'>('user');

  const handleListings = (listings: Array<ListingDetailsModel>) =>
    setListings(listings);

  const handleIsEditable = (isEditable) => setIsEditable(isEditable);

  const handleUsers = (users: Array<UserModel>) => {
    let usersById: Map<string, UserModel> = new Map();
    users.forEach((user) => usersById.set(user.id, user));
    setUsersById(usersById);
  };

  const remove = (listingId: string) =>
    setListings(listings?.filter((listing) => listing.listingId !== listingId));

  const incrementOffset = () => setOffset(limit + offset);

  const approve = async (listingId: string) => {
    const listing = await listingService.approveListing({ id: listingId });
    remove(listing.id);
  };

  const close = async (listingId: string) => {
    const listing = await listingService.closeListing({ id: listingId });
    remove(listing.id);
  };

  const queryPendingListings = async () => {
    const response = await listingDetailsService.pending(offset, limit);
    const userIds = response.results.map((listing) => listing.userId);
    const users = await userService.byIds(userIds);
    handleListings(response.results);
    handleUsers(users);
  };

  const queryPendingListingById = async (listingId: string) => {
    const listing = await listingDetailsService.byId(listingId);
    const users = await userService.byIds([listing.userId]);
    handleListings([listing]);
    handleUsers(users);
  };

  useEffect(() => {
    if (listings.length === 0) {
      queryParams.listingId
        ? queryPendingListingById(queryParams.listingId)
        : queryPendingListings();
    }
  }, [listings]);

  return {
    limit,
    offset,
    listings,
    usersById,
    isEditable,
    handlers: {
      handleListings,
      handleUsers,
      handleIsEditable,
      close,
      approve,
      incrementOffset,
    },
  };
};

export { useApprovals };
