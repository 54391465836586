/* @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { PageHeader } from 'antd';

export enum Size {
  LARGE = 'large',
}

const CardWrapper = styled.div<{ padding: string }>`
  position: relative;
  height: 100%;
  overflow: scroll;
  width: 100%;
  padding: ${(props) => props.padding};

  .card-page-header {
    padding: 0.5rem 0;
  }
`;

export const CardContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const StyledPageHeader = styled(PageHeader)<{
  extraWidth: string;
  alignRight: boolean;
  justifyContent: string;
}>`
  .ant-page-header-heading {
    .ant-page-header-heading-extra {
      display: flex;
      width: ${(props) => props.extraWidth};
      margin-right: ${(props) => (props.alignRight ? '0' : 'auto')};

      .ant-space {
        // flex: 1;
        width: ${(props) => props.extraWidth};
        display: flex;
        justify-content: ${(props) => props.justifyContent} !important;
        flex-wrap: wrap;
      }
    }
  }
`;

export type CardProps = React.HTMLProps<HTMLElement> & {
  title?: string;
  padding?: string;
  buttons?: ReactElement[];
  buttonAlign?: 'left' | 'right';
  buttonContainerSize?: string;
  buttonSpacing?:
    | 'center'
    | 'space-evenly'
    | 'space-around'
    | 'space-between'
    | 'flex-start'
    | 'flex-end';
};

export const Card = ({
  title = '',
  padding = '1.0rem 1.0rem',
  buttons = [],
  buttonAlign = 'right',
  buttonContainerSize = 'auto',
  buttonSpacing = 'flex-start',
  children,
}: CardProps) => {
  return (
    <CardWrapper padding={padding}>
      <StyledPageHeader
        justifyContent={buttonSpacing}
        extraWidth={buttonContainerSize}
        alignRight={buttonAlign === 'right'}
        className="card-page-header"
        ghost={false}
        title={title}
        extra={buttons}
      />
      <CardContainer>{children}</CardContainer>
    </CardWrapper>
  );
};
