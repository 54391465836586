import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import CopyDiv from '../../components/CopyDiv';
import { EditableTable } from '../../components/UI/Table';
import { useOutsideClickEventByClassName, useService } from '../../hooks';
import { columns } from './constants';
import {
  PopularityRankingsModel,
  VideoListingDetailsModel,
} from '../../lib/models';
import VideoPreview from './VideoPreview';
import { Button } from '../../components/UI/Button';
import styled from '@emotion/styled';
import Form from 'antd/lib/form';
import { DefaultIcon } from '../../components/UI/Icon';
import { Input, Spin } from 'antd';

export type PopularityRankingsProps = {};

const EDITABLE_ROW_CLASS_NAME_BASE = 'editable-row';
const GET_POPULAR_LISTINGS_QUERY_CACHE_KEY = 'get-popular-listings';

const PopularityRankings: React.FC<PopularityRankingsProps> = ({}) => {
  const limit = 50;
  const [offset, setOffset] = useState<number>(0);
  const [editableListingId, setEditableListingId] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const discoverService = useService<'discover'>('discover');
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const EditButton: React.FC<{
    video: VideoListingDetailsModel;
    rank: number;
  }> = ({ video, rank }) => {
    return (
      <div
        onClick={(evt) => {
          evt.stopPropagation();
          form.setFieldsValue({
            listingId: video.listingId,
            rank,
          });
          setEditableListingId(video.listingId);
        }}
      >
        <DefaultIcon name="edit" />
      </div>
    );
  };

  const SaveButton: React.FC<{ video: VideoListingDetailsModel }> = ({
    video,
  }) => {
    const { clickedOutside } = useOutsideClickEventByClassName(
      `${EDITABLE_ROW_CLASS_NAME_BASE}-${video.listingId}`
    );

    useEffect(() => {
      if (clickedOutside) {
        setEditableListingId('');
      }
    }, [clickedOutside]);

    return (
      <UpdateButtonsContainer>
        <StyledButton
          type="submit"
          key="listing-create"
          size="small"
          variant="primary"
          color="primaryPink"
          onClick={form.submit}
        >
          Save
        </StyledButton>
      </UpdateButtonsContainer>
    );
  };

  const { data: videoListingDetails } = useQuery<
    {},
    {},
    VideoListingDetailsModel[]
  >(
    [`get-popular-listings`, { offset, limit }],
    () => discoverService.getPopularListings(offset, limit),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    setIsSubmitting(true);
    try {
      await form.validateFields();
    } catch {
      setIsSubmitting(false);
    }
    const formValues = values as PopularityRankingsModel;
    const updatedRanking = {
      listingId: editableListingId,
      rank: Math.min(formValues.rank, (videoListingDetails?.length || 0) - 1),
    };
    const existingRank = (videoListingDetails || [])
      .filter((video) => {
        return video.listingId !== updatedRanking.listingId;
      })
      .map((video, idx) => {
        return { listingId: video.listingId, rank: idx };
      })
      .sort((a, b) => (a.rank < b.rank ? -1 : 1));

    const rankings = existingRank
      .slice(0, updatedRanking.rank)
      .concat([
        { listingId: updatedRanking.listingId, rank: updatedRanking.rank },
      ])
      .concat(existingRank.slice(updatedRanking.rank, existingRank.length))
      .map((video, i) => {
        return { listingId: video.listingId, rank: i };
      });

    const result = await discoverService.upsertPopularityRankings({ rankings });
    queryClient.invalidateQueries(GET_POPULAR_LISTINGS_QUERY_CACHE_KEY);

    setTimeout(() => {
      setEditableListingId('');
      setIsSubmitting(false);
    }, 1000);
  };

  const dataSource =
    videoListingDetails?.map((video, idx) => {
      const videoPreview = <VideoPreview video={video} />;
      const listingId =
        editableListingId !== video.listingId ? (
          <CopyDiv value={video.listingId}>
            <a href={`/approvals?listingId=${video.listingId}`}>
              {video.listingId.slice(0, 8)}
            </a>
          </CopyDiv>
        ) : (
          video.listingId
        );

      const rank = idx;

      const edit =
        editableListingId === video.listingId ? (
          <SaveButton video={video} />
        ) : (
          <EditButton video={video} rank={rank} />
        );

      return { ...video, ...{ video: videoPreview, listingId, rank, edit } };
    }) || [];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: VideoListingDetailsModel) => {
        return {
          record: record,
          inputNode: <Input type="number" />,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: editableListingId === record.listingId,
          required: col.required,
          message: col.message,
        };
      },
    };
  });

  return (
    <Spin className="unicorn-spinner" spinning={isSubmitting}>
      <Form onFinish={onFinish} form={form}>
        <EditableTable
          columns={mergedColumns}
          dataSource={dataSource}
          rowKey={'id'}
          rowClassName={(record) =>
            `${EDITABLE_ROW_CLASS_NAME_BASE}-${record.listingId}`
          }
          onNext={() => setOffset(offset + limit)}
          onPrev={() => setOffset(offset - limit)}
        />
        <input hidden name="listingId" />
      </Form>
    </Spin>
  );
};

export default PopularityRankings;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;

const UpdateButtonsContainer = styled.div`
  display: flex;
  button {
    margin: 0 0.5rem;
  }
`;
