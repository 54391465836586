import type { apiClient } from '../../hooks/useServices';
import { Location, PaginatedResponse, QrCode } from '../models';

export type QrCodeCreateBody = {
  code: string;
  redirectUrl: string;
  location?: Location;
};

export type QrCodeUpdateBody = {
  code: string;
  redirectUrl?: string;
  location?: Location;
};

export type QrCodeDeleteBody = {
  id: string;
};

export type QrCodeService = {
  byPagination: (
    offset: number,
    limit: number
  ) => Promise<PaginatedResponse<QrCode>>;
  byProximity: (
    latitude: number,
    longitude: number,
    proximity: number,
    offset: number,
    limit: number
  ) => Promise<PaginatedResponse<QrCode>>;
  create: (body: QrCodeCreateBody) => Promise<QrCode>;
  delete: (body: QrCodeDeleteBody) => Promise<QrCode>;
  updateRedirectUrlByCode: (body: QrCodeUpdateBody) => Promise<QrCode>;
};

export const qrCodeServiceFactory = (apiClient: apiClient): QrCodeService => ({
  byPagination: (
    offset: number,
    limit: number
  ): Promise<PaginatedResponse<QrCode>> =>
    apiClient<PaginatedResponse<QrCode>>(`atlas/qr-code/by-pagination`, {
      method: 'GET',
      body: {
        offset,
        limit,
      },
    }),
  byProximity: (
    latitude: number,
    longitude: number,
    proximity: number = 500,
    offset: number,
    limit: number
  ): Promise<PaginatedResponse<QrCode>> =>
    apiClient<PaginatedResponse<QrCode>>(`atlas/qr-code/by-proximity`, {
      method: 'GET',
      body: {
        lat: latitude,
        lon: longitude,
        proximity,
        offset,
        limit,
      },
    }),
  create: (body: QrCodeCreateBody): Promise<QrCode> =>
    apiClient<QrCode>(`atlas/qr-code/create`, {
      method: 'POST',
      body,
    }),
  delete: (body: QrCodeDeleteBody): Promise<QrCode> =>
    apiClient<QrCode>(`atlas/qr-code/delete`, {
      method: 'DELETE',
      body,
    }),
  updateRedirectUrlByCode: (body: QrCodeUpdateBody): Promise<QrCode> =>
    apiClient<QrCode>(`atlas/qr-code/update-redirect-url-by-code`, {
      method: 'PUT',
      body,
    }),
});
