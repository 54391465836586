import React, { useEffect, useState } from 'react';

type SideBarContextValues = {
  isCollapsed: boolean;
  showLabel: boolean;
  collaspeMenu: (boolean) => void;
  handleShowLabel: (boolean) => void;
  sideBarWidth: string;
};

const SideBarContext = React.createContext<SideBarContextValues>({
  isCollapsed: false,
  showLabel: true,
  collaspeMenu: () => {},
  handleShowLabel: () => {},
  sideBarWidth: '253px',
});

const useSideNavBar = () => React.useContext(SideBarContext);

const sideNavBarWidth = (isCollapsed): string => {
  if (isCollapsed) {
    return '64px';
  } else {
    return '253px';
  }
};

export type SideNavSettings = {
  isCollapsed: boolean;
};

const setLocalStorage = (isCollapsed: boolean) => {
  localStorage.setItem('side-nav', JSON.stringify({ isCollapsed }));
};

const getLocalStorage = (): SideNavSettings | null => {
  const savedSettings = localStorage.getItem('side-nav');
  if (!savedSettings) {
    return null;
  } else {
    return JSON.parse(savedSettings);
  }
};

function SideBarProvider(props) {
  const [isCollapsed, setCollapse] = useState(
    getLocalStorage()?.isCollapsed || false
  );
  const [showLabel, setShowLabel] = useState<boolean>();
  const [sideBarWidth, setSideBarWidth] = useState(
    sideNavBarWidth(isCollapsed)
  );

  const collaspeMenu = (isOpen: boolean) => {
    setCollapse(isOpen);
    setLocalStorage(isOpen);
  };

  const handleShowLabel = (minWidthMedium: boolean) => {
    setShowLabel(!isCollapsed && minWidthMedium);
  };

  useEffect(() => {
    setSideBarWidth(sideNavBarWidth(isCollapsed));
  }, [isCollapsed]);

  return (
    <SideBarContext.Provider
      value={{
        isCollapsed,
        showLabel,
        collaspeMenu,
        handleShowLabel,
        sideBarWidth,
      }}
      {...props}
    />
  );
}

export { SideBarProvider, useSideNavBar };
