/* @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react';
import { Button } from '../../components/UI/Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import AuthPage from './AuthPage';
import {
  AuthError,
  AuthDescription,
  AuthHeadline,
  AuthText,
} from './components';
import { LoginFlowProvider, useLoginFlow, STEP } from './useLoginFlow';

type LoginProps = {
  flow?: string;
};

const PhoneNumberForm = () => {
  const [{ phone: phoneState, error, step }, { submitPhone }] = useLoginFlow();
  const [phone, setPhone] = useState(phoneState);
  const disabled = step === STEP.VERIFY_PHONE;

  const handleSubmit = (
    e:
      | React.SyntheticEvent<HTMLFormElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    submitPhone(phone);
  };

  useEffect(() => {
    if (!/^1\d{10}$/.test(phone)) return;
    let timeout = setTimeout(() => submitPhone(phone), 1000);
    return () => clearTimeout(timeout);
  }, [phone, submitPhone]);

  return (
    <form onSubmit={handleSubmit}>
      <PhoneInput
        country="us"
        onlyCountries={['us']}
        value={phone}
        onChange={setPhone}
        isValid={() => !error}
        onEnterKeyPress={handleSubmit}
        disabled={disabled}
        inputProps={{
          autoFocus: true,
          'aria-describedby': 'login-description',
        }}
      />
      {error && <AuthError>{error}</AuthError>}
      <input className="visually-hidden" type="submit" />
    </form>
  );
};

const CodeForm = () => {
  const [state, { submitCode, resendCode }] = useLoginFlow();

  const [code, setCode] = useState('');
  const handleChange = (value) =>
    setCode(value.replace(/[^0-9]+/g, '').substr(0, 6));
  return (
    <>
      <form
        style={{ marginTop: '2.0rem' }}
        onSubmit={(e) => {
          e.preventDefault();
          submitCode(code);
        }}
      >
        <div className="react-tel-input">
          <input
            className="form-control form-control--code"
            type="text"
            placeholder="123456"
            value={code}
            onChange={(e) => handleChange(e.target.value)}
            disabled={state.step === STEP.VERIFY_CODE}
            aria-describedby="code-description"
            autoFocus
          />
        </div>
        {state.error && <AuthError>{state.error}</AuthError>}
        <input className="visually-hidden" type="submit" />
      </form>

      <Button
        size="large"
        variant="primary"
        color="primaryPink"
        disabled={code.length !== 6}
        onClick={() => submitCode(code)}
      >
        Submit
      </Button>
    </>
  );
};

const AuthDescriptionPhone = () => {
  return (
    <AuthDescription id="login-description">
      <AuthHeadline>
        <br />
        Login
      </AuthHeadline>
      <AuthText>
        Enter your mobile number.
        <br />
        We'll text you a verification code.
      </AuthText>
    </AuthDescription>
  );
};

const AuthDescriptionCode = (callback: () => any) => {
  return (
    <AuthDescription id="code-description">
      <AuthHeadline>Verification Code</AuthHeadline>
      <AuthText css={{ margin: 0 }}>
        Enter the code we texted to:
        <br />
        {callback()}
      </AuthText>
    </AuthDescription>
  );
};

const Login = ({ flow = 'login' }: LoginProps = {}) => {
  const [state] = useLoginFlow();
  const replaceCode = () => {
    return state.phone
      .replace(/\D/g, '') // no non-digits
      .replace(
        /1?(\d{3})(\d{3})(\d{4})/, // replace for non-us phone numbers
        (_, $1, $2, $3) => `(${$1}) ${$2}-${$3}`
      );
  };
  return (
    <AuthPage>
      {state.step === STEP.ENTER_PHONE || state.step === STEP.VERIFY_PHONE
        ? AuthDescriptionPhone()
        : AuthDescriptionCode(replaceCode)}
      {state.step === STEP.ENTER_PHONE || state.step === STEP.VERIFY_PHONE ? (
        <PhoneNumberForm />
      ) : (
        ''
      )}
      {(state.step === STEP.ENTER_CODE || state.step === STEP.VERIFY_CODE) && (
        <CodeForm />
      )}
      <div id="auth-recaptcha" css={{ position: 'absolute' }} />
    </AuthPage>
  );
};

export default function LoginWithProvider(props) {
  return (
    <LoginFlowProvider>
      <Login {...props} />
    </LoginFlowProvider>
  );
}
