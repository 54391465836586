import { default as ReactSelect } from 'react-select';
import '@emotion/react';
import { SelectOption } from '../../../lib/models';
import { useTheme } from '@emotion/react';

type SelectProps = {
  handleChange: (option) => void;
  selectOptions: SelectOption[];
  defaultValue?: SelectOption;
};

type MultiSelectProps = {
  handleChange: (options) => void;
  selectOptions: SelectOption[];
  defaultValues?: SelectOption[];
};

const defaultSelectStyles = (
  color: Record<string, string>,
  colorMap: Record<string, Record<string, string>>
) => {
  return {
    option: (provided) => ({
      ...provided,
      padding: '0.5rem',
    }),
    multiValue: (provided) => ({
      ...provided,
      color: color.primaryGreen,
      background: colorMap.gray.gray5,
      borderRadius: '30px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: color.primaryGreen,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    },
  };
};

const MultiSelect: React.FC<MultiSelectProps> = ({
  handleChange,
  selectOptions,
  defaultValues,
}) => {
  const { color, colorMap } = useTheme();

  return (
    <ReactSelect
      defaultValue={defaultValues}
      onChange={(options) => handleChange(options.map((option) => option))}
      styles={defaultSelectStyles(color, colorMap)}
      isMulti={true}
      options={selectOptions}
    />
  );
};

const Select: React.FC<SelectProps> = ({
  handleChange,
  selectOptions,
  defaultValue,
}) => {
  const { color, colorMap } = useTheme();

  return (
    <ReactSelect
      defaultValue={defaultValue}
      onChange={(option) => option && handleChange(option)}
      styles={defaultSelectStyles(color, colorMap)}
      isMulti={false}
      options={selectOptions}
    />
  );
};

export { Select, MultiSelect };
