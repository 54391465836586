import { ListingDetailsModel } from '../../lib/models';
import { snakeCaseToDisplayName } from '../../lib/helpers';
import styled from '@emotion/styled';

export type ListingAddressProps = {
  listing: ListingDetailsModel;
};

const ListingAmenities = ({ listing }: ListingAddressProps) => {
  return (
    <ListingAmenitiesWrapper>
      {listing.amenities.map((amenity) => (
        <Amenity>{snakeCaseToDisplayName(amenity.amenityKey)}</Amenity>
      ))}
    </ListingAmenitiesWrapper>
  );
};

export default ListingAmenities;

const ListingAmenitiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Amenity = styled.span`
  flex-basis: 25%;
  margin-bottom: 0.1rem;
  white-space: nowrap;
`;
