import styled from '@emotion/styled';
import { RouteProps } from 'react-router';
import { useTikTokAuth } from '../../hooks';
import { queryParamsParser } from '../../lib/helpers';
import { tikTokAuthKeyByAccount } from '../../lib/indexed-db';
import { TikTokAuth } from '../../lib/models';

type TikTokOauthParams = {
  account_name: string;
  open_id: string;
  scope: string;
  access_token: string;
  expires_at: string;
  refresh_token: string;
  refresh_expires_at: string;
};

const TikTokAuthRedirect: React.FC<{ props: RouteProps }> = ({ props }) => {
  const { login } = useTikTokAuth();

  try {
    const queryParams = queryParamsParser(
      props.location?.search || ''
    ) as TikTokOauthParams;

    const data: TikTokAuth = {
      authKey: tikTokAuthKeyByAccount(queryParams.account_name),
      accountName: queryParams.account_name,
      openId: queryParams.open_id,
      scopes: queryParams.scope.split(','),
      accessToken: queryParams.access_token,
      expiresAt: queryParams.expires_at,
      refreshToken: queryParams.refresh_token,
      refreshExpiresAt: queryParams.refresh_expires_at,
    };

    login(data).then((isSuccess) => {
      if (isSuccess) {
      }
    });
    return (
      <Container>
        <h1>Success! You can close this tab now.</h1>
      </Container>
    );
  } catch {
    return (
      <Container>
        <h1>Error! Please try again.</h1>
      </Container>
    );
  }
};

export default TikTokAuthRedirect;

const Container = styled.div`
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
  }
`;
