import styled from '@emotion/styled/macro';
import React from 'react';

const RADIUS_BUFFER_PX = 50;

export interface MapMarkerProps {
  lat?: number;
  lng?: number;
}

export interface RadiusLayer {
  lat: number;
  lng: number;
  radiusPx: number;
}

const RadiusLayer: React.FC<RadiusLayer> = ({ lat, lng, radiusPx }) => {
  return <IconRadius padding={radiusPx}></IconRadius>;
};

export default RadiusLayer;

const IconRadius = styled.div<{ padding: number }>`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50% !important;

  &::before {
    content: '';
    position: absolute;
    padding: ${(props) => props.padding + RADIUS_BUFFER_PX}px;
    background: rgba(255, 112, 163, 0.2);
    border-radius: 50% !important;
  }
`;
