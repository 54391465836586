import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '../../hooks';
import { sortByRank, useMediaGallery } from '../../hooks/useMediaGallery';
import { Icon } from '../../components/UI/Icon';
import { Range } from '../../lib/helpers';
import { ListingMedia } from '../../lib/models';
import ImageRankSelect from './ImageRankSelect';
import React from 'react';
import AutoGenerateModal from './AutoGenerateModal';
import PreviewImagesModal from './PreviewImagesModal';
import PreviewVideoModal from './PreviewVideoModal';
import { FullscreenOutlined } from '@ant-design/icons';

export type ListingMediaGalleryProps = {
  listingId: string;
};

type DeleteIconProps = {
  callback: () => void;
};

const DeleteIcon = ({ callback }: DeleteIconProps) => {
  const Delete = Icon('delete');
  return (
    <DeleteIconWrapper onClick={() => callback()}>
      <Delete />
    </DeleteIconWrapper>
  );
};

const minImagesToShow = 12;

const ListingMediaGallery = ({ listingId }: ListingMediaGalleryProps) => {
  const { mediaQuery } = useTheme();
  const minWidthLarge = useMediaQuery(mediaQuery.large);
  const { images, video, handlers } = useMediaGallery(listingId);
  const [selectedMedia, setSelectedMedia] = useState<ListingMedia>();
  const [isVideoModalOpen, setVideoModalOpen] = useState<boolean>(false);
  const [defaultWidth, setDefaultWidth] = useState<number>();
  const [defaultHeight, setDefaultHeight] = useState<number>();
  const [imageLoadedById, setImageLoadedById] = useState<Map<string, boolean>>(
    new Map()
  );
  const numEmptyDivs: number = minWidthLarge
    ? Math.max(minImagesToShow, images.length) - images.length
    : 0;

  const setImageRank = (image, rank) => {
    const remainingRanked = images
      .filter((img) => img.id !== image.id)
      .sort((a: ListingMedia, b: ListingMedia) =>
        sortByRank(a, b, images.length)
      );
    const rankedImages = remainingRanked
      .slice(0, rank - 1)
      .concat([image])
      .concat(remainingRanked.slice(rank - 1, remainingRanked.length))
      .map((img, i) => {
        return { rank: i + 1, imageId: img.id };
      });

    handlers.updateImageRanks(rankedImages);
  };

  const dimensions = (id: string, elementId: string): number[] => {
    const image = document.getElementById(elementId) as HTMLImageElement;
    const dimens = [image?.naturalWidth, image?.naturalHeight];
    // Set default width/height for video
    if (images[0].id === id && !defaultWidth && !defaultHeight) {
      setDefaultWidth(dimens[0]);
      setDefaultHeight(dimens[1]);
    }
    return dimens;
  };

  return (
    <ListingGalleryWrapper numImages={images.length + numEmptyDivs}>
      <VideoWrapper>
        {video && (
          <React.Fragment>
            <DeleteIcon callback={() => handlers.deleteVideo(video.groupId)} />
            <VideoIconWrapper onClick={() => setVideoModalOpen(true)}>
              <VideoIcon />
            </VideoIconWrapper>
            <Video controls src={video.url} />
            <PreviewVideoModal
              isOpen={isVideoModalOpen}
              video={video}
              close={() => setVideoModalOpen(false)}
            />
          </React.Fragment>
        )}
        {!video && (
          <UploadVideoButtons>
            <AutoGenerateModal
              listingId={listingId}
              defaultWidth={defaultWidth}
              defaultHeight={defaultHeight}
            />
          </UploadVideoButtons>
        )}
      </VideoWrapper>
      {images.map((image, i) => {
        const elementId = `image-${image.id}`;
        return (
          <ImageWrapper key={image.id} i={i + 1}>
            <ImageRankSelect
              total={images.length}
              num={i + 1}
              selectRank={(rank) => setImageRank(image, rank)}
            />
            <DeleteIcon
              callback={() => handlers.deleteImage(images[i].groupId)}
            />
            <Image
              id={elementId}
              src={image.url}
              onLoad={() =>
                setImageLoadedById({
                  ...imageLoadedById,
                  ...{ [elementId]: true },
                })
              }
              onClick={() => setSelectedMedia(image)}
            />
            {imageLoadedById[elementId] && (
              <ImageDimensions>
                <span>{dimensions(image.id, elementId).join(',')}</span>
              </ImageDimensions>
            )}
          </ImageWrapper>
        );
      })}
      {numEmptyDivs !== 0 &&
        new Range(1, numEmptyDivs).map((_, i) => {
          return (
            <ImageWrapper
              key={`empty-wrapper-${i}`}
              i={i + 1 + images.length}
            ></ImageWrapper>
          );
        })}
      <UploadMediaButton onClick={handlers.openFileDialog}>
        Drop media here to upload...
      </UploadMediaButton>
      <input type="hidden" {...handlers.getInputProps()} />
      {selectedMedia && (
        <PreviewImagesModal
          close={() => setSelectedMedia(undefined)}
          isOpen={true}
          media={[
            selectedMedia,
            ...images.filter((img) => img.id !== selectedMedia.id),
          ]}
        />
      )}
    </ListingGalleryWrapper>
  );
};

export default ListingMediaGallery;

const ListingGalleryWrapper = styled.div<{ numImages: number }>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: 400px repeat(auto-fill, 200px);
  grid-row-end: 48px;
  grid-template-columns: repeat(auto-fit, 100%);
  grid-template-areas: ${(props) => gridMobile(props.numImages)};

  @media ${({ theme }) => theme.mediaQuery.large} {
    grid-gap: 0.5rem;
    grid-template-rows: repeat(auto-fit, 160px);
    grid-row-end: 48px;
    grid-template-columns: 280px repeat(auto-fit, 160px);
    grid-template-areas: ${(props) => grid(props.numImages)};
  }
`;

const maxVideoRow = 4;

const grid = (
  numImages: number,
  row: number = 0,
  i: number = 0,
  template: string = ''
) => {
  // Handle case where it's undefined;
  if (!numImages) return '';
  // End of grid
  if (i >= numImages) {
    template += `"button button button button";`;
    return template;
  } else {
    const areaNum = i + 1;
    template += `"${row < maxVideoRow ? 'video' : '.'} image-${areaNum} image-${
      areaNum + 1
    } image-${areaNum + 2}"`;
    return grid(numImages, (row += 1), (i += 3), template);
  }
};

const gridMobile = (numImages: number) => {
  // Handle case where it's undefined;
  if (!numImages) return '';
  // End of grid
  return (
    new Range(0, maxVideoRow).map((num) => `"video"`).join(' ') +
    new Range(0, numImages).map((num) => `"image-${num + 1}"`).join(' ') +
    `"button";`
  );
};

const VideoWrapper = styled.div`
  position: relative;
  grid-area: video;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

const ImageWrapper = styled.div<{ i: number }>`
  position: relative;
  grid-area: ${(props) => 'image-' + props.i};
  min-height: 160px;
  min-width: 160px;
  cursor: pointer;
`;

const Image = styled.img`
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;

const ImageDimensions = styled.div`
  position: absolute;
  bottom: 0.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: white;
  font-weight: 700;
`;

const DeleteIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0.25rem;
  cursor: pointer;
  z-index: 1;
`;

const UploadMediaButton = styled.button`
  grid-area: button;
  min-height: 48px;
  background: rgba(20, 99, 110, 0.25);
  border-radius: 1rem;
  font-size: 1rem;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.color.primaryGreen};
`;

const UploadVideoButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  background: black;
`;

const VideoIconWrapper = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 0.25rem;
  cursor: pointer;
  z-index: 2;
`;

const VideoIcon = styled(FullscreenOutlined)`
  cursor: pointer;
  color: ${({ theme }) => theme.colorMap.neutral.white};

  svg {
    width: 24px;
    height: 24px;
  }
`;
