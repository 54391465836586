/* @jsxImportSource @emotion/react */
import { ReactComponent as HomeSVG } from './home.svg';
import { ReactComponent as DashboardSVG } from './dashboard.svg';
import { ReactComponent as InfoCircleSVG } from './info-circle.svg';
import { ReactComponent as MessageSVG } from './message.svg';
import { ReactComponent as PinSVG } from './pin.svg';
import { ReactComponent as PlusSVG } from './plus.svg';
import { ReactComponent as ProfileSVG } from './profile.svg';
import { ReactComponent as SettingsSVG } from './settings.svg';
import { ReactComponent as MenuCloseSVG } from './menu-close.svg';
import { ReactComponent as DockSVG } from './dock.svg';
import { ReactComponent as RefreshLocationSVG } from './refresh-location.svg';
import { ReactComponent as ControlledAccessSVG } from './controlled-access.svg';
import { ReactComponent as CloseSVG } from './close.svg';
import { ReactComponent as DeleteSVG } from './delete.svg';
import { ReactComponent as LogoutSVG } from './logout.svg';
import { ReactComponent as VerifiedSVG } from './verified.svg';
import { ReactComponent as NucleusSVG } from './nucleus.svg';
import { ReactComponent as VaultedCeilingsSvg } from './vaulted-ceilings.svg';
import { ReactComponent as GlobeSvg } from './globe.svg';
import { ReactComponent as NewSvg } from './new.svg';
import { ReactComponent as CalendarSvg } from './calendar.svg';
import { ReactComponent as QrCodeSvg } from './qr-code.svg';
import { ReactComponent as EditSvg } from './edit.svg';
import { ReactComponent as DiscoverSvg } from './discover.svg';
import { ReactComponent as LandlordSvg } from './landlord.svg';
import { ReactComponent as ListingHouseSvg } from './listing-house.svg';
import { ReactComponent as LockSvg } from './lock.svg';
import { ReactComponent as PolarisSvg } from './polaris.svg';
import { ReactComponent as ScoutSvg } from './scout.svg';
import { ReactComponent as DealSvg } from './deal.svg';
import { ReactComponent as BountySvg } from './bounty.svg';
import { ReactComponent as ApplicationSvg } from './application.svg';
import { ReactComponent as VideoSvg } from './video.svg';

export type IconName =
  | 'home'
  | 'dashboard'
  | 'info-circle'
  | 'message'
  | 'pin'
  | 'plus'
  | 'profile'
  | 'settings'
  | 'menu-close'
  | 'dock'
  | 'refresh-location'
  | 'controlled-access'
  | 'close'
  | 'delete'
  | 'logout'
  | 'verified'
  | 'nucleus'
  | 'vaulted-ceilings'
  | 'globe'
  | 'new'
  | 'calendar'
  | 'qr-code'
  | 'edit'
  | 'discover'
  | 'landlord'
  | 'listing-house'
  | 'lock'
  | 'polaris'
  | 'scout'
  | 'deal'
  | 'bounty'
  | 'application'
  | 'video';

export const Icon = (name: IconName) => {
  switch (name) {
    case 'home':
      return HomeSVG;
    case 'dashboard':
      return DashboardSVG;
    case 'info-circle':
      return InfoCircleSVG;
    case 'message':
      return MessageSVG;
    case 'pin':
      return PinSVG;
    case 'plus':
      return PlusSVG;
    case 'profile':
      return ProfileSVG;
    case 'menu-close':
      return MenuCloseSVG;
    case 'settings':
      return SettingsSVG;
    case 'dock':
      return DockSVG;
    case 'refresh-location':
      return RefreshLocationSVG;
    case 'controlled-access':
      return ControlledAccessSVG;
    case 'close':
      return CloseSVG;
    case 'delete':
      return DeleteSVG;
    case 'logout':
      return LogoutSVG;
    case 'verified':
      return VerifiedSVG;
    case 'nucleus':
      return NucleusSVG;
    case 'vaulted-ceilings':
      return VaultedCeilingsSvg;
    case 'globe':
      return GlobeSvg;
    case 'new':
      return NewSvg;
    case 'calendar':
      return CalendarSvg;
    case 'qr-code':
      return QrCodeSvg;
    case 'edit':
      return EditSvg;
    case 'discover':
      return DiscoverSvg;
    case 'landlord':
      return LandlordSvg;
    case 'listing-house':
      return ListingHouseSvg;
    case 'lock':
      return LockSvg;
    case 'polaris':
      return PolarisSvg;
    case 'scout':
      return ScoutSvg;
    case 'deal':
      return DealSvg;
    case 'bounty':
      return BountySvg;
    case 'application':
      return ApplicationSvg;
    case 'video':
      return VideoSvg;
  }
};

export const DefaultIcon: React.FC<{ name: IconName }> = ({ name }) => {
  const RequestedIcon = Icon(name);

  return <RequestedIcon />;
};
