import React from 'react';
import { Button } from '../../components/UI/Button';
import styled from '@emotion/styled';
import { ListingStatus } from '../../lib/models';

type ApprovalButtonsProps = {
  status: ListingStatus;
  listingId: string;
  approve: (listingId: string) => void;
  close: (listingId: string) => void;
};

const rejectConfirmMessage = `Are you sure you want to reject this listing? 
  This action is final.
`;
const closeConfirmMessage = `
  Are you sure you want to close this listing? 
  This action is final and will remove the listing from our site.
`;

const ApprovalButtons: React.FC<ApprovalButtonsProps> = ({
  status,
  listingId,
  approve,
  close,
}) => {
  const handleClose = () => {
    if (window.confirm(closeConfirmMessage)) {
      close(listingId);
    }
  };

  const handleReject = () => {
    if (window.confirm(rejectConfirmMessage)) {
      close(listingId);
    }
  };

  const handleApprove = () => approve(listingId);

  return status === ListingStatus.PENDING ? (
    <ApprovalButtonsWrapper>
      <Button
        style={{ marginTop: '0.5rem' }}
        onClick={handleApprove}
        size="large"
        variant="primary"
        color="secondaryGreen"
      >
        Approve
      </Button>
      <Button
        style={{ marginTop: '0.5rem' }}
        onClick={handleClose}
        size="large"
        variant="primary"
        color="primaryPink"
      >
        Reject
      </Button>
    </ApprovalButtonsWrapper>
  ) : (
    <ApprovalButtonsWrapper>
      <Button
        size="large"
        variant="primary"
        color="primaryGreen"
        disabled={true} // Don't have functionality built to reopen a listing right now.
        // Need to add endpoint + change db model a bit for logic on what we use to check on the listing status
      >
        Publish
      </Button>
      <Button
        style={{ marginTop: '0.5rem' }}
        onClick={handleClose}
        size="large"
        variant="primary"
        color="primaryPink"
      >
        Close Listing
      </Button>
    </ApprovalButtonsWrapper>
  );
};

export default ApprovalButtons;

export const ApprovalButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;
