import styled from '@emotion/styled';
import { message } from 'antd';
import React, { useState } from 'react';
import ExternalListingContact from '../../components/ExternalListingContact';
import TikTokUploadVideo from '../../components/Tiktok/TikTokUploadVideo';
import { Button } from '../../components/UI/Button';
import { capitalize } from '../../lib/helpers';
import {
  ExternalListingContactResponse,
  ListingDetailsModel,
  ListingStatus,
} from '../../lib/models';
import ListingStatusTag from '../User/ListingsByUser/ListingStatusTag';
import { randomlyGenerateCaption } from './helpers';

const isValidContact = (contact: ExternalListingContactResponse): boolean => {
  return !!contact.email || !!contact.mobileNumber || !!contact.companyName;
};

const ListingHeader: React.FC<{
  listing: ListingDetailsModel;
  listingStatus: ListingStatus;
  contacts?: ExternalListingContactResponse[];
}> = ({ listing, listingStatus, contacts }) => {
  return (
    <React.Fragment>
      <ListingHeaderContainer>
        <FlexColumn>
          <span>
            Listing Source:{' '}
            {` ${capitalize(
              listing.listingSource ? listing.listingSource : 'Unicorn'
            )}`}
          </span>
          <span>
            Listing Status:{' '}
            <ListingStatusTag listingStatus={listingStatus}></ListingStatusTag>
          </span>
        </FlexColumn>
        <FlexRow>
          {contacts &&
            contacts.map((contact) => {
              return isValidContact(contact) ? (
                <ExternalListingContact listing={listing} contact={contact} />
              ) : (
                ''
              );
            })}
        </FlexRow>
      </ListingHeaderContainer>
      <ListingHeaderContainer>
        <SocialMediaSection>
          <Button
            style={{ marginRight: '8px' }}
            onClick={() => {
              window.open(listing.videoUrls[0], '_blank');
            }}
            size="medium"
            variant="primary"
            color="black"
          >
            Download
          </Button>
          {listing.videoUrls[0] && (
            <TikTokUploadVideo videoUrl={listing.videoUrls[0]} />
          )}
          <Button
            style={{ marginLeft: '8px' }}
            onClick={() => {
              const caption = randomlyGenerateCaption(listing);
              navigator.clipboard.writeText(caption);
              message.success('Copied to clipboard');
            }}
            size="medium"
            variant="primary"
            color="black"
          >
            Caption
          </Button>
        </SocialMediaSection>
      </ListingHeaderContainer>
    </React.Fragment>
  );
};

export default ListingHeader;

const ListingHeaderContainer = styled.div`
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    justify-content: center;
    align-items: flex-start;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 1rem;
    padding: 0.5rem 0;

    span {
      padding: 0.25rem 1rem;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  span {
    font-size: 1rem;
    padding: 0.5rem 0;

    span {
      padding: 0.25rem 1rem;
    }
  }
`;

const SocialMediaSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  span {
    font-size: 1rem;
    padding: 0.5rem 0;
    margin-right: 0.5rem;
  }
`;
