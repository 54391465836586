import { PaginatedResponse, UserModel } from '../../lib/models';

export type UserService = {
  byId: (id: string) => Promise<UserModel>;
  byIds: (ids: string[]) => Promise<UserModel[]>;
  byPagination: (
    offset: number,
    limit: number
  ) => Promise<PaginatedResponse<UserModel>>;
  update: (
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string
  ) => Promise<UserModel>;
};

export const userServiceFactory = (apiClient: any): UserService => ({
  byId: async (id: string): Promise<UserModel> => {
    return await apiClient(`atlas/user/by-id`, {
      method: 'GET',
      body: { id },
    });
  },
  byIds: async (ids: string[]): Promise<UserModel[]> => {
    return await apiClient(`atlas/user/by-ids?ids=${ids.join('&ids=')}`);
  },
  byPagination: async (
    offset: number,
    limit: number
  ): Promise<PaginatedResponse<UserModel>> => {
    return await apiClient(
      `atlas/user/by-pagination?offset=${offset}&limit=${limit}`
    );
  },
  update: async (
    id: string,
    firstName?: string,
    lastName?: string,
    email?: string
  ): Promise<UserModel> => {
    return await apiClient(`/atlas/user/update`, {
      method: 'PUT',
      body: {
        id,
        firstName,
        lastName,
        email,
      },
    });
  },
});
