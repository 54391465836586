import { TikTokAuth } from '../models';

type TikTokUploadVideoResponse = {
  rentalId: string;
  videoId: string;
  shareId: string;
};

export type TikTokService = {
  oauth: (requestUri: string) => Promise<TikTokRedirectOauth>;
  accessToken: (refreshToken: string) => Promise<TikTokAuth>;
  uploadVideo: (
    accountName: string,
    openId: string,
    accessToken: string,
    videoId: string
  ) => Promise<TikTokUploadVideoResponse>;
};

export type TikTokRedirectOauth = {
  redirectUrl: string;
};

export const tikTokServiceFactory = (apiClient: any): TikTokService => ({
  oauth: async (requestUri: string): Promise<TikTokRedirectOauth> => {
    return await apiClient(`atlas/tiktok/oauth`, {
      method: 'POST',
      body: {
        request_uri: requestUri,
      },
    });
  },
  accessToken: async (refreshToken: string): Promise<TikTokAuth> => {
    return await apiClient(`atlas/tiktok/refresh-access-token`, {
      method: 'POST',
      body: {
        refresh_token: refreshToken,
      },
    });
  },
  uploadVideo: async (
    accountName: string,
    openId: string,
    accessToken: string,
    videoId: string
  ): Promise<TikTokUploadVideoResponse> => {
    return await apiClient(`atlas/tiktok/upload-video`, {
      method: 'POST',
      body: {
        account_name: accountName,
        open_id: openId,
        access_token: accessToken,
        video_id: videoId,
      },
    });
  },
});
