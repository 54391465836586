import { ListingDetailsModel, UserModel } from '../../lib/models';
import {
  addDays,
  dateFormatFromString,
  toUSD,
  truncatedTextAtEndOfSentenceOrWord,
} from '../../lib/helpers';
import ListingAddress from './ListingAddress';
import styled from '@emotion/styled';
import ListingAmenities from './ListingAmenities';
import { Section, SectionRow, SectionLabel, SectionValue } from './components';
import { useState } from 'react';
import { color } from '../../lib/theme';

export type ListingMediaGalleryProps = {
  listing: ListingDetailsModel;
  user: UserModel;
};

const ListingDetails = ({ listing, user }: ListingMediaGalleryProps) => {
  const [showFullDescription, setShowFullDescription] =
    useState<boolean>(false);

  return (
    <ListingDetailsWrapper>
      {user && (
        <SectionRow>
          {/* Renter Name */}
          <Section>
            <SectionLabel>Renter</SectionLabel>
            <SectionValue>
              {user.firstName} {user.lastName}
            </SectionValue>
          </Section>
          {/* Mobile number */}
          <Section>
            <SectionLabel>Phone</SectionLabel>
            <SectionValue>{user.mobileNumber}</SectionValue>
          </Section>
        </SectionRow>
      )}
      <SectionRow>
        {/* Address */}
        <Section>
          <SectionLabel>Address</SectionLabel>
          <SectionValue>
            <ListingAddress listing={listing} />
          </SectionValue>
        </Section>
        {/* Title */}
        <Section>
          <SectionLabel>Title</SectionLabel>
          <SectionValue>{listing.label || 'N/A'}</SectionValue>
        </Section>
        {/* Rental Type */}
        <Section>
          <SectionLabel>Type</SectionLabel>
          <SectionValue>{listing.rentalType}</SectionValue>
        </Section>
      </SectionRow>

      <SectionRow>
        {/* Monthly Rent */}
        <Section>
          <SectionLabel>Price</SectionLabel>
          <SectionValue>{`${toUSD(listing.monthlyRent)}/mo`}</SectionValue>
        </Section>
        {/* Deposit */}
        <Section>
          <SectionLabel>Sqft</SectionLabel>
          <SectionValue>{listing.sqft || 'N/A'}</SectionValue>
        </Section>
        {/* Listing Type */}
        <Section>
          <SectionLabel>Listing Type</SectionLabel>
          <SectionValue>{listing.listingType}</SectionValue>
        </Section>
      </SectionRow>

      <SectionRow>
        {/* Available */}
        <Section>
          <SectionLabel>Available</SectionLabel>
          <SectionValue>
            {dateFormatFromString(listing.availableAt)}
          </SectionValue>
        </Section>
        {/* Lease Duration days */}
        <Section>
          <SectionLabel>End Date</SectionLabel>
          <SectionValue>
            {!!listing.leaseDurationDays &&
              dateFormatFromString(
                addDays(
                  new Date(listing.availableAt),
                  listing.leaseDurationDays
                ).toISOString()
              )}
          </SectionValue>
        </Section>
        {/* Description */}
        <Section>
          <SectionLabel>
            Description
            <a
              style={{ marginLeft: '0.5rem', color: color.primaryPink }}
              onClick={() => setShowFullDescription(!showFullDescription)}
            >
              {showFullDescription ? 'Hide' : 'Read more'}
            </a>
          </SectionLabel>
          <SectionValue>
            {showFullDescription
              ? listing.description
              : truncatedTextAtEndOfSentenceOrWord(
                  listing.description || '',
                  100
                )}
          </SectionValue>
        </Section>
      </SectionRow>

      <SectionRow>
        {/* Beds */}
        <Section>
          <SectionLabel>Beds</SectionLabel>
          <SectionValue>{listing.numBedrooms}</SectionValue>
        </Section>
        {/* Baths */}
        <Section>
          <SectionLabel>Bathrooms</SectionLabel>
          <SectionValue>{listing.numBathrooms}</SectionValue>
        </Section>
        {/* Parking */}
        <Section>
          <SectionLabel>Parking</SectionLabel>
          <SectionValue>{listing.numParkingSpots}</SectionValue>
        </Section>
      </SectionRow>

      <SectionRow>
        {/* Landlord Rating */}
        <Section>
          <SectionLabel>Landlord</SectionLabel>
          <SectionValue>{listing.ratings.landlordRating}</SectionValue>
        </Section>
        {/* Light Rating */}
        <Section>
          <SectionLabel>Light</SectionLabel>
          <SectionValue>{listing.ratings.naturalLight}</SectionValue>
        </Section>
        {/* Noise Level Rating */}
        <Section>
          <SectionLabel>Noise Level</SectionLabel>
          <SectionValue>{listing.ratings.noiseLevel}</SectionValue>
        </Section>
      </SectionRow>

      <SectionRow>
        <Section>
          <SectionLabel>Amenities</SectionLabel>
          <ListingAmenities listing={listing} />
        </Section>
      </SectionRow>
    </ListingDetailsWrapper>
  );
};

export default ListingDetails;

const ListingDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
