import React from 'react';
import { ReactComponent as Profile } from './images/profile.svg';
import { MenuItem, MenuList, StyledLink, StyledNavBar } from './components';

const NavBar: React.FC = () => {
  return (
    <StyledNavBar>
      <MenuList>
        <MenuItem>
          <StyledLink to="/settings">
            <Profile />
          </StyledLink>
        </MenuItem>
      </MenuList>
    </StyledNavBar>
  );
};

export default NavBar;
