import styled from '@emotion/styled';
import { ReactComponent as PlaceholderGraph } from './atlas-graph.svg';

const MetricContainerWidth = '270';

export const DashboardWrapper = styled.div`
  background: rgba(247, 255, 255, 0.5);
  position relative;
`;

export const DashboardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MetricsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-width: calc((${MetricContainerWidth}px + 20px) * 4);
`;

export const MetricContainer = styled.div`
  width: ${MetricContainerWidth}px;
  height: 136px;
  background: ${({ theme }) => theme.colorMap.neutral.white};
  box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.1);
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  margin: 1.25rem 1.25rem 0rem 0rem;
`;

export const Metric = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MetricIconContainer = styled.div<{ background: string }>`
  background: ${(props) =>
    ({ theme }) =>
      theme.color[props.background]};
  width: 60px;
  height: 60px;
  border-radius: 50%;
  min-width: 60px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MetricInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  min-width: 150px;
`;

export const MetricLabel = styled.div`
  font-size: 0.95rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorMap.gray.gray3};
`;

export const MetricValue = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const HighlightMetricValue = styled.div`
  background-color: #f3ec78;
  background-image: linear-gradient(270deg, #ff005e 27.65%, #3c096c 100%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: 1.25rem;
  font-weight: 700;
`;

export const Graph = styled(PlaceholderGraph)`
  background: white;
  box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.1);
  border-radius: 10px;
  margin-top: 1.25rem;
  margin-right: 1.25rem;
`;

export const InventorySnapshotContainer = styled.div`
  height: 370px;
  min-height: 370px;
  width: ${MetricContainerWidth}px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 1.25rem;
  background: ${({ theme }) => theme.colorMap.neutral.white};
`;

export const InventorySnapshotTitle = styled.div`
  background: ${({ theme }) => theme.color.primaryGreen};
  color: ${({ theme }) => theme.colorMap.neutral.white};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
  height: 70px;
  min-height: 70px;
  font-weight: 700;
`;

export const InventoryCity = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorMap.gray.gray4};
  display: flex;
`;

export const InventoryCityListingCount = styled.div`
  margin-left: auto;
`;

export const ModeIfame = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 80vh;
`;
