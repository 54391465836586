import { useService } from '../../hooks';
import {
  Alert,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { Button } from '../../components/UI/Button';
import styled from '@emotion/styled';
import { RentalSearchByAddressParams } from '../../lib/services/rental';
import { ListingDetailsModel, RentalModel } from '../../lib/models';
import { columns, listingDetailsResponseToDataSource } from './constants';
import { Table } from '../../components/UI/Table';
import { ColumnType } from 'antd/lib/table';
import { useQuery, useQueryClient } from 'react-query';
import { DefaultIcon } from '../../components/UI/Icon';
const { Option } = Select;

export type ListingsSearchProps = {};

const options = [
  {
    key: 'address1',
    label: 'Address 1',
  },
  {
    key: 'address2',
    label: 'Address 2',
  },
  {
    key: 'postalCode',
    label: 'Postal Code',
  },
];

const ListingSearch: React.FC<ListingsSearchProps> = ({}) => {
  const listingDetailsService = useService<'listingDetails'>('listingDetails');
  const rentalService = useService<'rental'>('rental');
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [results, setResults] = useState<RentalModel[]>();
  const [searchField, setSearchField] = useState<string>(options[0].key);
  const queryClient = useQueryClient();

  const onChange = async (change) => {
    try {
      await form.validateFields();
      setIsValidForm(true);
    } catch {
      // nothing
    }
  };

  const onFinish = async (values: any) => {
    setIsSubmitting(true);
    try {
      await form.validateFields();
    } catch {
      setIsSubmitting(false);
    }
    const params = values as RentalSearchByAddressParams;
    const results = await rentalService
      .searchByAddress(params)
      .catch((e) => {
        setResults(undefined);
        setShowAlert(true);
        setIsSubmitting(false);
      })
      .then((res) => {
        setResults(res || []);
      });

    setTimeout(() => {
      setIsSubmitting(false);
    }, 1000);
  };

  const { data: listingDetailsResponse } = useQuery<
    {},
    {},
    ListingDetailsModel[]
  >(
    [
      `listings-by-rental-ids`,
      { rentalIds: results?.map((rental) => rental.id) },
    ],
    () =>
      listingDetailsService.byRentalIds(
        results?.map((rental) => rental.id) || []
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: (results || []).length > 0,
    }
  );

  const dataSource = listingDetailsResponseToDataSource(
    listingDetailsResponse || []
  );

  return (
    <Spin className="unicorn-spinner" key={'ah'} spinning={isSubmitting}>
      {showAlert && (
        <Alert
          message="No results found"
          description=""
          type="error"
          closable
          onClose={() => setShowAlert(false)}
        />
      )}
      <StyledForm form={form} onFinish={onFinish} onChange={onChange}>
        <StyledSelect
          defaultValue={options[0].key}
          onChange={(value) => setSearchField(value as string)}
        >
          {options.map((option) => (
            <Option value={`${option.key}`}>{option.label}</Option>
          ))}
        </StyledSelect>

        <Form.Item name={searchField} rules={[{ required: false }]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <StyledButton
            disabled={!isValidForm}
            key="listing-create"
            size="small"
            variant="primary"
            color="primaryGreen"
          >
            Submit
          </StyledButton>
        </Form.Item>
      </StyledForm>

      <Table
        total={results?.length || 0}
        columns={columns as ColumnType<any>[]}
        dataSource={dataSource || []}
        rowKey={'rentalId'}
        showPagination={false}
      />
    </Spin>
  );
};

export default ListingSearch;

const StyledButton = styled(Button)`
  margin: 0 auto;
  width: 300px;
`;

const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0rem;

  .ant-form-item {
    margin: 0;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    padding: 1rem 0rem;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
`;
