import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ListingQueue from './pages/ListingQueue';
import Settings from './pages/Settings';
import Messaging from './pages/Messaging';
import ListingCreate from './pages/ListingCreate';
import Jobs from './pages/Jobs';
import Users from './pages/Users';
import User from './pages/User';
import Listings from './pages/Listings';
import Discover from './pages/Discover';
import QrCodes from './pages/QrCodes';
import MapView from './pages/Map';
import TikTokAuthRedirect from './pages/TikTokAuthRedirect';

const Router = () => (
  <Switch>
    <Route path="/dashboard" exact component={Dashboard} />
    <Route
      path="/messages/open"
      exact
      render={(props) => <Messaging {...props} />}
    />
    <Route
      path="/messages/archived"
      exact
      render={(props) => <Messaging {...props} />}
    />
    <Route
      path="/approvals"
      exact
      render={(props) => <ListingQueue {...props} />}
    />
    <Route path="/listings" exact component={Listings} />
    <Route path="/listings/add" exact component={ListingCreate} />
    <Route path="/users" exact component={Users} />
    <Route path="/users/:userId" exact component={User} />
    <Route path="/rentals" exact component={Dashboard} />
    <Route path="/settings" exact component={Settings} />
    <Route path="/jobs" exact component={Jobs} />
    <Route path="/qr-codes" exact component={QrCodes} />
    <Route path="/discover" exact component={Discover} />
    <Route path="/map" exact component={MapView} />
    <Route
      path="/tiktok/login"
      exact
      render={(props) => <TikTokAuthRedirect props={props} />}
    />
    <Route render={() => <Redirect to="/dashboard" />} />
  </Switch>
);

export default Router;
