import {
  ListingMedia,
  MediaType,
  RentalSocialMediaUpload,
} from '../../lib/models';
import { mimeTypeToMediaFormat } from '../../lib/helpers';
import { JobResponse } from './externalListing';
import { ApiClientProvider } from '../../hooks';

interface EnhancedFile extends File {}
export type ListingMediaService = {
  createMultiple: (
    files: Array<EnhancedFile>,
    listingId: string,
    userId: string
  ) => Promise<ListingMedia[]>;
  create: (
    file: EnhancedFile,
    listingId: string,
    userId: string
  ) => Promise<ListingMedia>;
  deleteByGroupId: (
    mediaType: MediaType,
    groupId: string
  ) => Promise<ListingMedia[]>;
  byListingId: (
    mediaType: MediaType,
    listingId: string
  ) => Promise<ListingMedia[]>;
  byUrl: (mediaType: MediaType, url: string) => Promise<ListingMedia>;
  updateRank: (
    mediaType: MediaType,
    id: string,
    rank: number
  ) => Promise<ListingMedia>;
  audioCategories: () => Promise<string[]>;
  generateFromImages: (
    listingId: string,
    audioCategory: string,
    videoWidth?: number,
    videoHeight?: number
  ) => Promise<JobResponse>;
  socialMediaUploadsById: (
    mediaType: MediaType,
    mediaId: string
  ) => Promise<RentalSocialMediaUpload[]>;
};

const getUploader = (apiClient: any, listing_id: string, user_id: string) => {
  return async (file: EnhancedFile): Promise<ListingMedia> => {
    const media_type = file.type.split('/')[0];
    const media_format = mimeTypeToMediaFormat(file.type);

    const {
      bucket,
      objectKey: object_key,
      method,
      url,
    } = await apiClient(`/atlas/listing/${media_type}/signed-url`, {
      method: 'POST',
      body: {
        media_type,
        media_format,
        file_name: file.name,
        listing_id,
      },
    });

    await fetch(url, {
      method,
      headers: {
        'Content-type': file.type,
      },
      body: file,
    });

    const media = apiClient(`/atlas/listing/${media_type}/create`, {
      method: 'POST',
      body: {
        media_type,
        media_format,
        user_id,
        created_by: user_id,
        bucket,
        object_key,
        listing_id,
      },
    });

    return media;
  };
};

export const listingMediaServiceFactory = (
  apiClient: any
): ListingMediaService => ({
  create: async (file, listingId, userId): Promise<ListingMedia> => {
    return await getUploader(apiClient, listingId, userId)(file);
  },
  createMultiple: async (files, listingId, userId): Promise<ListingMedia[]> => {
    const results: Promise<ListingMedia>[] = files.map(
      await getUploader(apiClient, listingId, userId)
    );
    return Promise.all(results);
  },
  deleteByGroupId: async (
    mediaType: MediaType,
    groupId: string
  ): Promise<ListingMedia[]> => {
    return await apiClient(`/atlas/listing/${mediaType}/delete-by-group-id`, {
      method: 'POST',
      body: {
        group_id: groupId,
      },
    });
  },
  byListingId: async (
    mediaType: MediaType,
    listingId: string
  ): Promise<ListingMedia[]> => {
    return await apiClient(
      `/atlas/listing/${mediaType}/by-listing-id?listing_id=${listingId}`,
      {
        method: 'GET',
      }
    );
  },
  byUrl: async (mediaType: MediaType, url: string): Promise<ListingMedia> => {
    return await apiClient(
      `/atlas/listing/${mediaType}/by-url?url=${encodeURIComponent(url)}`,
      {
        method: 'GET',
      }
    );
  },
  updateRank: async (
    mediaType: MediaType,
    id: string,
    rank: number
  ): Promise<ListingMedia> => {
    return await apiClient(`/atlas/listing/${mediaType}/update`, {
      method: 'PUT',
      body: {
        id: id,
        rank: rank,
      },
    });
  },
  audioCategories: (): Promise<string[]> => {
    return apiClient(`atlas/listing/video/audio-categories`);
  },
  generateFromImages: (
    listingId: string,
    audioCategory: string,
    videoWidth?: number,
    videoHeight?: number
  ): Promise<JobResponse> => {
    return apiClient(`atlas/listing/video/create-from-images`, {
      method: 'POST',
      body: {
        listingId: listingId,
        audioCategory: audioCategory,
        videoWidth: videoWidth,
        videoHeight: videoHeight,
      },
    });
  },
  socialMediaUploadsById: async (
    mediaType: MediaType,
    mediaId: string
  ): Promise<RentalSocialMediaUpload[]> => {
    return await apiClient(
      `/atlas/listing/${mediaType}/social-media-uploads-by-id`,
      {
        method: 'GET',
        body: {
          id: mediaId,
        },
      }
    );
  },
});
