import React from 'react';
import { useAuth } from './useAuth';
import { request, FetchOptions } from '../lib/api';

export type ApiClient<T> = (path: string, options?: FetchOptions) => Promise<T>;

const ApiClientContext = React.createContext<ApiClient<unknown>>(() =>
  Promise.resolve(null)
);

const ApiClientProvider = (props) => {
  const { token, userId } = useAuth();

  const apiClient = React.useCallback(
    function apiClient<T>(path: string, options?: FetchOptions): Promise<T> {
      return request(path, options, { token, userId });
    },
    [token, userId]
  );

  return <ApiClientContext.Provider value={apiClient} {...props} />;
};

const useApiClient = () => React.useContext(ApiClientContext);

export { ApiClientProvider, useApiClient };
