import { QrCode, TabProps } from '../../lib/models';
import { dateFieldFormat, sort } from '../../lib/helpers';

export enum QrCodeTab {
  ACTIVE = 'ACTIVE',
  NEW = 'NEW',
}

export const columns = [
  {
    title: 'Code',
    dataIndex: 'code',
    editable: false,
    key: 'code',
    sorter: (a, b) => sort(a.code, b.code),
  },
  {
    title: 'QR',
    dataIndex: 'qr',
    editable: false,
    key: 'qr',
  },
  {
    title: 'Link',
    dataIndex: 'redirectUrl',
    editable: true,
    key: 'redirectUrl',
    sorter: (a, b) => sort(a.redirectUrl, b.redirectUrl),
    required: false,
    message: 'Add redirectUrl',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    editable: true,
    key: 'location',
    required: false,
    placeholder: '34.0195, -118.4912',
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    editable: false,
    key: 'updatedAt',
    render: (value) => dateFieldFormat(value),
    sorter: (a, b) => sort(a.updatedAt, b.updatedAt),
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
    editable: false,
    key: 'edit',
  },
];

export const tabs: TabProps[] = [
  {
    label: 'Active',
    key: QrCodeTab.ACTIVE.toString(),
  },
  {
    label: 'New',
    key: QrCodeTab.NEW.toString(),
  },
];

export const QUERY_CACHE_KEY_QR_BY_PAGINATION = 'qr-codes-by-pagination';

export const QR_CODE_BASE_URL = `${process.env.REACT_APP_UNICORN_WEB_HOST}`;
