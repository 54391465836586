import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import GlobalStyles from './GlobalStyles';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import {
  AuthProvider,
  LoggedIn,
  NotLoggedIn,
  ConfigProvider,
  ApiClientProvider,
  SideBarProvider,
  ServicesProvider,
  TikTokAuthProvider,
} from './hooks';
import Auth from './pages/Auth';
import Router from './Router';
import getTheme from './lib/theme';
import NavBar from './components/NavBar';
import { SideBar } from './components/SideBar';
import { AtlasDatabase } from './lib/indexed-db';
import { initDB } from 'react-indexed-db';

const theme = getTheme();
const queryClient = new QueryClient();

initDB(AtlasDatabase);

AntdConfigProvider.config({
  theme: {
    primaryColor: '#FF70A3',
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles theme={theme} />
    <AuthProvider>
      <SideBarProvider>
        <NotLoggedIn>
          <Auth />
        </NotLoggedIn>
        <LoggedIn>
          <ApiClientProvider>
            <ServicesProvider>
              <TikTokAuthProvider>
                <QueryClientProvider client={queryClient}>
                  <ConfigProvider>
                    <NavBar />
                    <SideBar />
                    <Router />
                  </ConfigProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </TikTokAuthProvider>
            </ServicesProvider>
          </ApiClientProvider>
        </LoggedIn>
      </SideBarProvider>
    </AuthProvider>
  </ThemeProvider>
);

export default App;
