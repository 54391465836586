import React from 'react';
import { useQuery } from 'react-query';
import { UserModel } from '../lib/models';
import { useApiClient } from './index';

type AppConfig = {
  user?: UserModel;
};

const ConfigContext = React.createContext<AppConfig>({});

function ConfigProvider(props) {
  const apiClient = useApiClient();
  const { isLoading, data } = useQuery('appConfig', () => apiClient('/config'));

  return isLoading ? null : <ConfigContext.Provider value={data} {...props} />;
}

const useConfig = () => React.useContext(ConfigContext);

export { ConfigProvider, useConfig };
