import { ObjectStoreMeta } from 'react-indexed-db';

export enum AtlasNamespace {
  TIKTOK = 'tiktok',
}

export interface ObjectStoreMetadata {
  store: AtlasNamespace;
  storeConfig: {};
  storeSchema: StoreSchema[];
}

export interface StoreSchema {
  name: string;
  keypath: string;
  options: any;
}

export const tikTokAuthKeyByAccount = (accountName: string): string =>
  `tiktok:auth:${accountName}`;

const TikTokSchema: StoreSchema[] = [
  {
    name: 'accountName',
    keypath: 'accountName',
    options: { unique: true },
  },
];

const TikTokObjectStoreMetadata: ObjectStoreMeta = {
  store: AtlasNamespace.TIKTOK,
  storeConfig: { keyPath: 'authKey', autoIncrement: false },
  storeSchema: TikTokSchema,
};

const AtlasDatabaseSchemaVersion = 1;

export const AtlasDatabase = {
  name: 'atlasLocalStorageDb',
  version: 1,
  objectStoresMeta: [TikTokObjectStoreMetadata],
};
