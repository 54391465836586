import type { apiClient } from '../../hooks/useServices';

export type AmenityService = {
  amenityKeyOptions: () => Promise<string[]>;
};

export const amenityServiceFactory = (
  apiClient: apiClient
): AmenityService => ({
  amenityKeyOptions: (): Promise<string[]> =>
    apiClient<string[]>('/atlas/amenity/keys', {
      method: 'GET',
    }),
});
