import { Button } from '../../components/UI/Button';
import styled from '@emotion/styled';
import { useUserEditForm } from '../../hooks';
import { SettingsCol, SettingsSection, SectionLabel } from './components';

type FormSectionProps = {
  userId: string;
  label: string;
  name: string;
  value: any;
  toggleEditable: () => void;
};

const FormSection: React.FC<FormSectionProps> = ({
  userId,
  label,
  name,
  value,
  toggleEditable,
}) => {
  const { handlers } = useUserEditForm(userId);
  return (
    <SettingsSection>
      <SettingsCol>
        <SectionLabel>{label}</SectionLabel>
        <FormInput
          name={name}
          defaultValue={value}
          type="text"
          onChange={handlers.handleInputChange}
          {...handlers.register(name)}
        />
      </SettingsCol>
      <SettingsCol>
        <Button
          style={{ marginLeft: 'auto' }}
          size="small"
          variant="tertiary"
          onClick={async () => {
            handlers.submitForm();
            toggleEditable();
          }}
          color="primaryGreen"
        >
          Save
        </Button>
        <Button
          style={{ marginLeft: 'auto' }}
          size="small"
          variant="tertiary"
          onClick={() => toggleEditable()}
          color="primaryPink"
        >
          Cancel
        </Button>
      </SettingsCol>
    </SettingsSection>
  );
};

export default FormSection;

const FormInput = styled.input`
  border: none;
  height: 50px;
  font-size: 1rem;
  background: ${({ theme }) => theme.colorMap.gray.gray5};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 1rem;
  margin: 0.25rem 0rem;
`;
