import { useState } from 'react';
import AppPage from '../AppPage';
import { Card } from '../../components/UI/Card';
import ListingCreateForm from './ListingCreateForm';
import ListingMedia from './ListingMedia';
import { useService } from '../../hooks';
import { useQuery } from 'react-query';
import { ListingModel } from '../../lib/models';

const ListingCreate = () => {
  const [listingId, setListingId] = useState<string>();
  const title = listingId
    ? 'Create Listing - Add Media'
    : 'Create Listing - Add Details';

  const listingDetailsService = useService<'listingDetails'>('listingDetails');
  const { data: listing } = useQuery<{}, {}, ListingModel>(
    [`listing-by-id`],
    () => listingDetailsService.byId(listingId!!),
    {
      keepPreviousData: true,
      enabled: !!listingId,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <AppPage>
      <Card title={`${title}`}>
        {listingId ? (
          <ListingMedia listingId={listingId} />
        ) : (
          <ListingCreateForm
            onSubmitCallback={(listingId) => setListingId(listingId)}
          />
        )}
      </Card>
    </AppPage>
  );
};

export default ListingCreate;
