import { dateFieldFormat, toUSD } from '../../../lib/helpers';
import { ListingAddressModel, ListingDetailsModel } from '../../../lib/models';

export const messageColumns = [
  {
    title: 'Message Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    sorter: (a, b) => (a < b ? -1 : 1),
  },
  {
    title: 'Listing Id',
    dataIndex: 'listingId',
    key: 'listingId',
  },
  {
    title: 'Submitted At',
    dataIndex: 'submittedAt',
    key: 'submittedAt',
    render: (value) => (value ? dateFieldFormat(value) : ''),
    sorter: (a, b) => {
      return a.submmittedAt < b.submmittedAt ? -1 : 1;
    },
  },
  {
    title: 'Approved',
    dataIndex: 'approvedAt',
    key: 'approvedAt',
    render: (value?: string) => (value ? dateFieldFormat(value) : ''),
  },
  {
    title: 'Avail.',
    dataIndex: 'availableAt',
    key: 'availableAt',
    render: (value?: string) => (value ? dateFieldFormat(value) : ''),
  },
  {
    title: 'Closed',
    dataIndex: 'closedAt',
    key: 'closedAt',
    render: (value?: string) => (value ? dateFieldFormat(value) : ''),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: (value?: any) => `${value?.address1} ${value?.address2}`,
  },
  {
    title: 'City',
    dataIndex: 'address',
    key: 'city',
    render: (value?: any) => `${value?.city}`,
  },
  {
    title: 'State',
    dataIndex: 'address',
    key: 'state',
    render: (value?: any) => `${value?.state}`,
  },
  {
    title: 'Zip',
    dataIndex: 'address',
    key: 'postal_code',
    render: (value?: any) => `${value?.postalCode}`,
  },
];
