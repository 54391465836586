import ListBody from 'antd/lib/transfer/ListBody';
import type { apiClient } from '../../hooks/useServices';
import { RentalModel } from '../models';

export type RentalSearchByAddressParams = {
  address1?: string;
  address2?: string;
  postalCode?: string;
};

export type RentalService = {
  rentalTypeOptions: () => Promise<string[]>;
  searchByAddress: (
    params: RentalSearchByAddressParams
  ) => Promise<RentalModel[]>;
};

export const rentalServiceFactory = (apiClient: apiClient): RentalService => ({
  rentalTypeOptions: (): Promise<string[]> =>
    apiClient<string[]>('/atlas/rental/rental-types', {
      method: 'GET',
    }),
  searchByAddress: (
    params: RentalSearchByAddressParams
  ): Promise<RentalModel[]> =>
    apiClient<RentalModel[]>('/atlas/rental/search-by-address', {
      method: 'GET',
      body: { ...params },
    }),
});
