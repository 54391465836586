export const TikTokAccountNames = [
  'RentUnicorn',
  'RentUnicornBOS',
  'RentUnicornSF',
  'RentUnicornMIA',
  'RentUnicornATX',
  'RentUnicornCHI',
  'RentUnicornLA',
  'RentUnicornNYC',
  'UnicornHomes',
  'UnicornSublets',
];
