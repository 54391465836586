import { RouteProps } from 'react-router';
import AppPage from '../AppPage';
import { Card } from '../../components/UI/Card';
import { useEffect } from 'react';
import {
  MessagingWrapper,
  SectionWrapper,
  SectionTitle,
  SectionContent,
  SelectedMessageSectionTitle,
  SectionLink,
} from './components';
import {
  ExternalListingContactResponse,
  ListingDetailsModel,
  MessageResponseModel,
} from '../../lib/models';
import ListingDetails from './ListingDetails';
import MessagePreview from './MessagePreview';
import SelectedMessage from './SelectedMessage';
import { groupBy, queryParamsParser } from '../../lib/helpers';
import { useApiClient, useMessages, useService } from '../../hooks/index';
import { useQuery } from 'react-query';

const sms = (mobileNumber: string) => {
  const body = encodeURI(
    `Thanks for reaching out to Unicorn 🦄 LA’s most exclusive apartment rental platform.`
  );
  const url = `sms:?${mobileNumber}&body=${body}`;
  window?.open(url, '_blank')?.focus();
};

const email = (email: string) => {
  const subject = encodeURI(`Re: Listing on Unicorn 🦄`);
  const url = `mailto:?&subject=${subject}`;
  window?.open(url, '_blank')?.focus();
};

const Messaging = (props: RouteProps) => {
  const apiClient = useApiClient();
  const queryMessagesPath = props.location?.pathname.match('archive')
    ? 'archived'
    : 'open';
  const queryParams = queryParamsParser(props.location?.search || '');

  const {
    offset,
    limit,
    messages,
    selectedMessage,
    viewedMessageIds,
    listing,
    handlers,
  } = useMessages(queryParams);

  const queryMessages = async () =>
    (await apiClient(
      `/atlas/messages/${queryMessagesPath}?offset=${offset}&limit=${limit}`
    )) as Array<MessageResponseModel>;
  const listingById = async (id: string) =>
    (await apiClient(
      `/atlas/listing-details/by-id?id=${id}`
    )) as Array<ListingDetailsModel>;
  const reply = async (id: string) =>
    (await apiClient(`/atlas/messages/reply`, {
      method: 'PUT',
      body: { id },
    })) as MessageResponseModel;

  const externalListingContactService = useService<'externalListingContact'>(
    'externalListingContact'
  );

  const listingIds =
    messages?.map((message) => message.message.listingId) || [];

  const { data: contactsByListingId } = useQuery<
    {},
    {},
    Map<string, ExternalListingContactResponse[]>
  >(
    [`external-listing-contacts`, { listingIds: listingIds }],
    () =>
      externalListingContactService
        .byListingIds(listingIds)
        .then((contacts) => {
          const map: Map<string, ExternalListingContactResponse[]> = groupBy(
            contacts,
            'listingId'
          );
          return map;
        })
        .catch((e) => {
          return {};
        }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: listingIds.length > 0,
    }
  );

  const onMessageSelection = (messageId, listingId) => {
    handlers.selectMessage(messageId);
    listingById(listingId).then((listing) => handlers.handleListing(listing));
  };

  const markAsResponded = (messageId) => {
    reply(messageId).then(({ message, user }) =>
      handlers.archiveMessage(message.id)
    );
  };

  useEffect(() => {
    queryMessages().then((messages) => {
      handlers.handleMessages(messages);
    });
  }, [offset]);

  useEffect(() => {
    if (messages.length) {
      const firstMessage = messages[0].message;
      handlers.selectMessage(firstMessage.id);
      listingById(firstMessage.listingId).then((listing) =>
        handlers.handleListing(listing)
      );
    }
  }, [messages.length]);

  return (
    <AppPage>
      <Card padding="0">
        <MessagingWrapper>
          {/* Messages / Preview */}
          <SectionWrapper widthPerc="25%">
            <SectionTitle>Messages</SectionTitle>
            <SectionContent>
              {messages.map(({ message, user }) => (
                <MessagePreview
                  message={message}
                  user={user}
                  isSelected={selectedMessage?.message.id === message.id}
                  isNew={!viewedMessageIds.has(message.id)}
                  selectMessage={onMessageSelection}
                />
              ))}
            </SectionContent>
          </SectionWrapper>
          {/* Selected Message */}
          {selectedMessage && (
            <SectionWrapper widthPerc="50%">
              <SectionTitle>
                <SelectedMessageSectionTitle>
                  <div className="user-title">
                    <div className="main-title">
                      <a
                        href={`/users/${selectedMessage.user.id}?tab=messages`}
                        target="_blank"
                      >
                        {selectedMessage.user.firstName}{' '}
                        {selectedMessage.user.lastName}
                      </a>
                    </div>
                    <div className="sub-title">
                      <SectionLink
                        onClick={() => sms(selectedMessage.user.mobileNumber)}
                      >
                        {selectedMessage.user.mobileNumber || 'N/A'}
                      </SectionLink>
                      <SectionLink
                        onClick={() => email(selectedMessage.user.email || '')}
                      >
                        {selectedMessage.user.email || 'N/A'}
                      </SectionLink>
                    </div>
                  </div>
                </SelectedMessageSectionTitle>
              </SectionTitle>
              <SectionContent>
                <SelectedMessage
                  message={selectedMessage.message}
                  user={selectedMessage.user}
                  markAsResponded={markAsResponded}
                />
              </SectionContent>
            </SectionWrapper>
          )}
          {/* Listing Details */}
          {selectedMessage && listing && (
            <SectionWrapper widthPerc="25%">
              <SectionTitle>
                <span>Listing Details</span>
              </SectionTitle>
              <ListingDetails
                listing={listing}
                contacts={
                  contactsByListingId
                    ? contactsByListingId[listing.listingId]
                    : undefined
                }
              />
            </SectionWrapper>
          )}
        </MessagingWrapper>
      </Card>
    </AppPage>
  );
};

export default Messaging;
