import { ListingDetailsModel, ListingStatus } from '../../lib/models';
import { Range } from '../../lib/helpers';

export const deriveListingStatus = (
  listing: ListingDetailsModel
): ListingStatus => {
  if (!listing.approvedAt && !listing.closedAt) {
    return ListingStatus.PENDING;
  } else if (listing.closedAt) {
    return ListingStatus.CLOSED;
  } else {
    return ListingStatus.ACTIVE;
  }
};

const generateRandomHash = (): string => {
  return hashTags[Math.floor(Math.random() * hashTags.length)];
};

export const randomlyGenerateCaption = (
  listing: ListingDetailsModel
): string => {
  const bed =
    listing.numBedrooms === 0 ? 'Studio' : `${listing.numBedrooms} Bed`;
  const usedHashes: string[] = [];
  const randomHashes = new Range(1, 5).map((num) => {
    let hash = generateRandomHash();
    let isDuplicate = usedHashes.includes(hash);

    while (isDuplicate) {
      hash = generateRandomHash();
      isDuplicate = usedHashes.includes(hash);
    }
    usedHashes.push(hash);
    return hash;
  });
  return `🦄  ${bed} | ${listing.numBathrooms} Bath | ${
    listing.address.city
  } ${randomHashes.join(' ')}`;
};

const hashTags = [
  '#amazinghomes',
  '#apartment',
  '#apartmentcheck',
  '#apartmentgoals',
  '#apartmenthacks',
  '#apartments',
  '#apartmentsearch',
  '#apartmenttherapy',
  '#apartmenttour',
  '#apartmenttourla',
  '#apartmentvideo',
  '#apartmentwalkthrough',
  '#apttour',
  '#bedroom',
  '#california',
  '#cheapapartment',
  '#dreamhome',
  '#flathunting',
  '#forrent',
  '#forrentca',
  '#forrentla',
  '#foryoupage',
  '#fyp',
  '#hometour',
  '#housetour',
  '#ilovela',
  '#la',
  '#laapartment',
  '#laapartmentcheck',
  '#laapartmenthunting',
  '#laapartmenttour',
  '#lacaapartments',
  '#lacapartments',
  '#larealestate',
  '#larental',
  '#larentals',
  '#leasing',
  '#loft',
  '#losangeles',
  '#luxeryhomes',
  '#luxeryrentals',
  '#luxuryaf',
  '#luxuryapartmentsla',
  '#mycrib',
  '#myhome',
  '#myroom',
  '#realestate',
  '#rental',
  '#rentalhome',
  '#rentalproperty',
  '#rentals',
  '#rentalsearch',
  '#renters',
  '#rentershack',
  '#rentla',
  '#santamonica',
  '#venicebeach',
  '#viral',
];
