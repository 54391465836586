import styled from '@emotion/styled';
import { useState } from 'react';
import { Card } from '../../components/UI/Card';
import { Button } from '../../components/UI/Button';
import { Tabs } from 'antd';
import AppPage from '../AppPage';
import { QrCodeTab, tabs } from './constants';
import ActiveTab from './ActiveTab';
import CreateModal from './CreateModal';
import NewTab from './NewTab';
const { TabPane } = Tabs;

const QrCodes = () => {
  const [activeTab, setActiveTab] = useState<string>(
    QrCodeTab.ACTIVE.toString()
  );

  const buttons = [<CreateModal key="add-qr-code-button" />];

  const tabByKey = (key: string) => {
    if (key.toLocaleLowerCase() === QrCodeTab.ACTIVE.toLowerCase()) {
      return <ActiveTab />;
    } else {
      return <NewTab />;
    }
  };

  return (
    <AppPage>
      <Card title={'QR Codes'} buttons={buttons}>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
        >
          {tabs.map((tab) => (
            <TabPane tab={tab.label} key={tab.key.toLowerCase()}>
              {tabByKey(tab.key.toLowerCase())}
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </AppPage>
  );
};

export default QrCodes;

const StyledButton = styled(Button)`
  min-width: 150px;
`;
