import styled from '@emotion/styled';
import { TOP_NAV_BAR_HEIGHT } from '../../lib/constants';

export const MessagingWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: calc(
    var(--vh, 1vh) * 100 - ${TOP_NAV_BAR_HEIGHT}px
  ); // subtract 80 from top nav
`;

export const SectionWrapper = styled.div<{ widthPerc: string }>`
  flex-basis: ${(props) => props.widthPerc};
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-left: 1px solid ${({ theme }) => theme.colorMap.gray.gray5};
  height: 100%;
`;

export const SectionTitle = styled.div`
  text-align: start;
  font-size: 1.125rem;
  padding: 1rem;
  font-weight: 700;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid ${({ theme }) => theme.colorMap.gray.gray5};
`;

export const SelectedMessageSectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .user-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .sub-title {
    color: ${({ theme }) => theme.colorMap.gray.gray2};
    font-size: 0.75rem;
    font-weight: 500;

    span {
      &:last-of-type {
        margin-left: 1rem;
      }
    }
  }
`;
export const SectionContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const SectionLink = styled.a`
  margin-right: 1rem;

  &:hover {
    cursor: pointer;
  }
`;
