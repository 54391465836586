import { ListingDetailsModel } from '../../lib/models';
import styled from '@emotion/styled';

export type ListingAddressProps = {
  listing: ListingDetailsModel;
};

const ListingAddress = ({ listing }: ListingAddressProps) => {
  return (
    <ListingAddressWrapper>
      <span>{listing.address.address1}</span>
      <span>{listing.address.address2}</span>
      <span>{listing.address.city},</span>
      <span>{listing.address.state}</span>
      <span>{listing.address.postalCode}</span>
      <span>{listing.address.postalCode2}</span>
    </ListingAddressWrapper>
  );
};

export default ListingAddress;

const ListingAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
