import { Button } from '../../components/UI/Button';
import {
  SettingsCol,
  SettingsSection,
  SectionLabel,
  SectionValue,
} from './components';

type DetailsSectionProps = {
  label: string;
  value?: any;
  editable: boolean;
  editField?: () => void;
};

const DetailsSection: React.FC<DetailsSectionProps> = ({
  label,
  value,
  editable,
  editField,
}) => {
  return (
    <SettingsSection>
      <SettingsCol>
        <SectionLabel>{label}</SectionLabel>
        <SectionValue>{value}</SectionValue>
      </SettingsCol>

      {editable && (
        <SettingsCol>
          <Button
            style={{ marginLeft: 'auto' }}
            size="small"
            variant="tertiary"
            onClick={() => {
              if (editField) editField();
            }}
            color="primaryGreen"
          >
            Edit
          </Button>
        </SettingsCol>
      )}
    </SettingsSection>
  );
};

export default DetailsSection;
