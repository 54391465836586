import styled from '@emotion/styled';
import { MessageModel, UserModel } from '../../lib/models';
import { dateFormatFromString } from '../../lib/helpers';

type MessageProps = {
  message: MessageModel;
  user: UserModel;
  isSelected: boolean;
  isNew: boolean;
  selectMessage: (messageId: string, listingId: string) => void;
};

const truncateMessage = (message: string) => {
  const maxChars = 100;
  if (message.length < maxChars) {
    return message;
  } else {
    return message.substring(0, maxChars) + '...';
  }
};

const MessagePreview = ({
  message,
  user,
  isSelected,
  isNew,
  selectMessage,
}: MessageProps) => {
  return (
    <Message
      isSelected={isSelected}
      onClick={() => selectMessage(message.id, message.listingId)}
    >
      <div className="column" style={{ height: '100%', minWidth: '30px' }}>
        {isNew && <NewMessage />}
      </div>
      <div className="column">
        <Sender>
          {user.firstName} {user.lastName}
        </Sender>
        <Text>{truncateMessage(message.message)}</Text>
      </div>

      <div className="column">
        <Date>{dateFormatFromString(message.submittedAt)}</Date>
      </div>
    </Message>
  );
};

const Message = styled.div<{ isSelected: boolean }>`
  padding: 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colorMap.gray.gray5};
  border-bottom: 1px solid ${({ theme }) => theme.colorMap.gray.gray5};
  transition: all 0.2s ease;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  height: 100%;
  max-height: 64px;
  background: ${(props) => (props.isSelected ? 'rgba(20, 99, 110, 0.1)' : '')};

  &:first-of-type {
    border-top: none;
  }

  .column {
    display: flex;
    flex-direction: column;

    &:last-of-type {
      margin-left: auto;
    }
  }

  &:hover {
    background: #f2f2f2;
  }
`;

const NewMessage = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.primaryGreen};
  margin: auto;
  margin-right: 1rem;
`;

const Sender = styled.div`
  color: black;
  font-size: 0.9rem;
  padding-bottom: 0.25rem;
`;

const Text = styled.div`
  color: #828282;
  font-size: 0.75rem;
  max-height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Date = styled.div`
  color: #828282;
  font-size: 0.75rem;
  text-align: right;
`;

export const ViewedIcon = styled.div`
  width: 5px;
  height: 5px;
  padding: 5px;
  align-self: center;
  border-radius: 100px;
  background: rgba(255, 0, 94, 1);
`;

export default MessagePreview;
