import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { default as ReactSelect } from 'react-select';
import { Range } from '../../lib/helpers';
import { SelectOption } from '../../lib/models';
import { zIndices } from '../../lib/theme';

const customSelectStyles = (
  color: Record<string, string>,
  colorMap: Record<string, Record<string, string>>,
  isHeroImage: boolean = false
): {} => {
  return {
    container: () => ({
      position: 'absolute',
      left: '0.25rem',
      top: '0.25rem',
      padding: '0rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0rem',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      position: 'absolute',
      left: '1.25rem',
      padding: '0rem',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      outline: 'none',
      border: 'none',
      background: isHeroImage ? color.primaryPink : colorMap.neutral.black,
      borderRadius: '50%',
      height: '28px',
      width: '28px',
      minHeight: '28px',
      display: 'flex',
      color: colorMap.neutral.white,
    }),
    menu: (provided) => ({
      ...provided,
      background: 'none',
      borderRadius: '50%',
      width: '115px',
      height: '100%',
    }),
    option: (provided) => ({
      ...provided,
      background: colorMap.neutral.white,
      color: colorMap.neutral.black,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0',
      padding: '0.5rem',
      border: '1px solid white',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0rem',
      padding: '0rem',
      color: colorMap.neutral.white,
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        transition: 'opacity 300ms',
        background: 'none',
        padding: '0rem',
        color: colorMap.neutral.white,
      };
    },
    overlay: (provided) => ({
      ...provided,
      border: '20px solid red',
    }),
  };
};

type ImageRankSelectProps = {
  total: number;
  num: number;
  selectRank: (rank: number) => void;
};

const ImageRankSelect: React.FC<ImageRankSelectProps> = ({
  total,
  num,
  selectRank,
}) => {
  const { color, colorMap } = useTheme();
  const rankOptions = new Range(1, total).map((num) => {
    return { label: num, value: num };
  });
  const currentValue: SelectOption = { label: num.toString(), value: num };

  return (
    <ReactSelect
      onChange={(option) => option && selectRank(option.value)}
      defaultValue={currentValue}
      value={currentValue}
      options={rankOptions}
      isMulti={false}
      styles={customSelectStyles(color, colorMap, num === 1)}
    />
  );
};

export default ImageRankSelect;
