import { Alert, Form, InputNumber, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/UI/Button';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { useService } from '../../hooks';
import { DEFAULT_QUERY_CACHE_STALE_TIME } from '../../lib/constants';
import { useHistory } from 'react-router';

const timeoutMilli = 12000;

// FFMPEG default decoder must be visible by 2
const videoDimensionValidator = (
  rule: any,
  value: number,
  cb: (msg?: string) => void
) => {
  return divisbleBy2(value) ? cb() : cb('Must be divisible by 2');
};

const divisbleBy2 = (num: number) => num % 2 == 0;

const AudioGenerateModal: React.FC<{
  listingId: string;
  defaultWidth?: number;
  defaultHeight?: number;
}> = ({ listingId, defaultWidth, defaultHeight }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const listingMediaService = useService<'listingMedia'>('listingMedia');
  const history = useHistory();

  const { data: categories } = useQuery<{}, {}, string[]>(
    [`audio-categories`],
    () => listingMediaService.audioCategories(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_CACHE_STALE_TIME,
    }
  );

  const autoGenerate = async () => {
    setConfirmLoading(true);

    await listingMediaService.generateFromImages(
      listingId,
      form.getFieldValue('category'),
      form.getFieldValue('videoWidth'),
      form.getFieldValue('videoHeight')
    );

    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
      history.go(0);
    }, timeoutMilli);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const initialValues = {
    videoWidth: divisbleBy2(defaultWidth || 1) ? defaultWidth : undefined,
    videoHeight: divisbleBy2(defaultHeight || 1) ? defaultHeight : undefined,
  };

  useEffect(() => {
    setIsFormDisabled(!form.getFieldValue('category'));
  });

  return (
    <React.Fragment>
      <StyledButton
        key="listing-create"
        size="medium"
        variant="secondary"
        color="black"
        onClick={showModal}
      >
        Auto Generate
      </StyledButton>
      <Modal
        title="Auto Generate Video"
        visible={visible}
        onOk={autoGenerate}
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: isFormDisabled }}
        onCancel={handleCancel}
      >
        <StyledForm
          form={form}
          initialValues={initialValues}
          onFieldsChange={() => {
            console.log('ahdbajsbdjhsabd');
            setIsFormDisabled(
              form.getFieldsError().some((field) => field.errors.length > 0)
            );
          }}
        >
          <Alert
            style={{ marginBottom: '1.0rem' }}
            message="The poster url is generated from the first image ranked. The video will default to a 16:9 aspect ratio if dimensions below are not provided."
            type="info"
            showIcon
          />
          <Form.Item
            label="Category"
            name="category"
            rules={[{ required: true, message: 'Select audio category' }]}
          >
            <Select>
              {categories &&
                categories.map((cat) => (
                  <Select.Option value={cat}>{cat.toUpperCase()}</Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Width"
            name="videoWidth"
            rules={[
              {
                required: false,
                validator: (rule, value, cb) =>
                  videoDimensionValidator(rule, value, cb),
              },
            ]}
          >
            <InputNumber placeholder={`${defaultWidth || ''}`} />
          </Form.Item>
          <Form.Item
            label="Height"
            name="videoHeight"
            rules={[
              {
                required: false,
                validator: (rule, value, cb) =>
                  videoDimensionValidator(rule, value, cb),
              },
            ]}
          >
            <InputNumber placeholder={`${defaultHeight || ''}`} />
          </Form.Item>
        </StyledForm>
      </Modal>
    </React.Fragment>
  );
};

export default AudioGenerateModal;

const StyledButton = styled(Button)`
  width: 100%;
  min-width: 100%;
  height: 100%;
  text-align: center;
  background: black;
  color: white;
  cursor: pointer;
  font-size: 1.25rem;
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    display: flex;
    justify-content: flex-start;

    .ant-form-item-label {
      width: 100px;
      display: flex;
    }

    .ant-form-item-control {
      max-width: 400px;
    }
  }
`;
