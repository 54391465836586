import { dateFieldFormat, toUSD } from '../../../lib/helpers';
import ListingStatusTag from './ListingStatusTag';

export const listingQueueByUserColumns = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => {
      return <ListingStatusTag listingStatus={text} />;
    },
  },
  {
    title: 'Listing Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Rental Id',
    dataIndex: 'rentalId',
    key: 'rentalId',
  },
  {
    title: 'Monthly Rent',
    dataIndex: 'monthlyRent',
    key: 'monthlyRent',
    render: (value) => `${toUSD(value)}`,
  },
  {
    title: 'Available At',
    dataIndex: 'availableAt',
    key: 'availableAt',
    render: (value) => dateFieldFormat(value),
  },
  {
    title: 'Closed At',
    dataIndex: 'closedAt',
    key: 'closedAt',
    render: (value) => (value ? dateFieldFormat(value) : ''),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value) => dateFieldFormat(value),
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (value) => dateFieldFormat(value),
  },
  {
    title: 'Deleted At',
    dataIndex: 'deletedAt',
    key: 'deletedAt',
    render: (value) => (value ? dateFieldFormat(value) : ''),
  },
];
