import styled from '@emotion/styled';

const CopyDiv: React.FC<{ value: any }> = ({ value, children }) => {
  return (
    <CopyDivContainer onClick={() => navigator.clipboard.writeText(value)}>
      {children}
    </CopyDivContainer>
  );
};

const CopyDivContainer = styled.div`
  color: ${({ theme }) => theme.color.primaryPink};
  cursor: pointer;
  font-weight: 800;
`;

export default CopyDiv;
