import styled from '@emotion/styled';
import { Button } from '../../components/UI/Button';
import { UnicornAtlas } from '../../components/UI/Brand';
import { inputWidth } from './AuthPage';

export const AuthHomeWrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
`;

export const AuthHomeSection = styled.div`
  position: absolute;
  padding: 4.125rem 1.5rem 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${({ theme }) => theme.mediaQuery.large} {
    width: 50%;
    justify-content: center;
  }
`;

export const AuthVideoPoster = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 0;
`;

export const AuthVideo = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 0;
`;

export const HomeLogo = styled(UnicornAtlas)`
  width: 144px;
  margin: 0 auto 0.5rem;
  display: flex;
  color: ${({ theme }) => theme.colorMap.neutral.black};

  @media ${({ theme }) => theme.mediaQuery.large} {
    width: 206px;
    color: black;
  }
`;

export const HomeCta = styled.div`
  text-align: center;
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 500;
  letter-spacing: 0.18px;
  width: 144px;
  align-self: center;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQuery.large} {
    font-size: 22px;
    width: 206px;
    max-width: 413px;
    margin: 0 auto;
  }
`;

export const HomeActions = styled.div`
  @media ${({ theme }) => theme.mediaQuery.large} {
    margin: 4.3125rem auto 0;
    width: 100%;
    max-width: 327px;
  }
`;

// This reproduces a bunch of styling from buttons
// Because these screens aren't really "in" the app,
// and becuase of time pressure, this is a punt on the issue
// of button-styled <a> elements
export const HomeButton = styled.a`
  display: block;
  width: 100%;
  height: 40px;
  border-radius: 16px;

  text-align: center;
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;

  background-color: ${({ theme }) => theme.color.primaryPink};
  color: white;

  // override button styles
  &:visited {
    color: white;
  }
  &:hover {
    text-decoration: none;
  }

  &.secondary {
    background-color: ${({ theme }) => theme.color.secondary};
  }

  & + & {
    margin-top: 16px;
  }
`;

export const AuthError = styled.p`
  width: ${inputWidth};
  margin: 0.5em auto 0;
  font-size: 12px;
  color: ${({ theme }) => theme.color.error};
  text-align: left;
  background-color: #f2dede;
  border: 1px solid #fbeed5;
  border-radius: 4px;
  padding: 1vh;
  text-align: center;

  &:before {
    content: '!';
    display: inline-block;

    width: 17px;
    height: 17px;
    padding-left: 1px;
    border-radius: 50%;
    color: white;
    background-color: ${({ theme }) => theme.color.error};
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    margin-right: 8px;
  }
`;

export const AuthDescription = styled.div`
  width: ${inputWidth};
  margin: 90px auto 0;

  @media ${({ theme }) => theme.mediaQuery.large} {
    width: 100%;
    margin: 0;
  }
`;

export const AuthHeadline = styled.h1`
  width: 66%;
  color: ${({ theme }) => theme.colorMap.neutral.black};

  @media ${({ theme }) => theme.mediaQuery.large} {
    width: auto;
  }
`;

export const AuthText = styled.p`
  color: ${({ theme }) => theme.colorMap.neutral.black};
`;

export const BackButton = styled(Button)`
  padding: 0;
  text-align: left;
  @media ${({ theme }) => theme.mediaQuery.large} {
    text-align: center;
    margin: 0 auto;
  }
`;
