import { Descriptions } from 'antd';
import React from 'react';
import { dateFormatFromString } from '../../../lib/helpers';
import { UserModel } from '../../../lib/models';

export type DetailsProps = {
  user: UserModel;
};

const Details: React.FC<DetailsProps> = ({ user }) => {
  return (
    <Descriptions layout="vertical" title="" bordered>
      <Descriptions.Item label="First Name">{user.firstName}</Descriptions.Item>
      <Descriptions.Item label="Last Name">{user.lastName}</Descriptions.Item>
      <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
      <Descriptions.Item label="Mobile Number">
        {user.mobileNumber}
      </Descriptions.Item>
      <Descriptions.Item label="Created At">
        {user.createdAt ? dateFormatFromString(user.createdAt) : ''}
      </Descriptions.Item>
      <Descriptions.Item label="Updated At">
        {user.updatedAt ? dateFormatFromString(user.updatedAt) : ''}
      </Descriptions.Item>
      <Descriptions.Item label="Deleted At">
        {user.deletedAt ? dateFormatFromString(user.deletedAt) : ''}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default Details;
