import React from 'react';
import CopyDiv from '../../components/CopyDiv';
import { dateFieldFormat, toUSD } from '../../lib/helpers';
import {
  ExternalListingModel,
  ListingAddressModel,
  ListingDetailsModel,
  ListingStatus,
  TabProps,
} from '../../lib/models';

export enum ListingsTab {
  SEARCH = 'SEARCH',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  EXTERNAL_LISTINGS = 'EXTERNAL_LISTINGS',
}

export const tabToListingStatus = (str: string): ListingStatus => {
  switch (str) {
    case ListingsTab.CLOSED:
      return ListingStatus.CLOSED;
    case ListingsTab.PENDING:
      return ListingStatus.PENDING;
    case ListingsTab.ACTIVE:
      return ListingStatus.ACTIVE;
    case ListingsTab.CLOSED:
      return ListingStatus.CLOSED;
    case ListingsTab.DELETED:
      return ListingStatus.DELETED;
    default:
      return ListingStatus.ACTIVE;
  }
};

export const tabs: TabProps[] = [
  {
    label: 'Search',
    key: ListingsTab.SEARCH.toString(),
  },
  {
    label: 'Pending',
    key: ListingsTab.PENDING.toString(),
  },
  {
    label: 'Active',
    key: ListingsTab.ACTIVE.toString(),
  },
  {
    label: 'Closed',
    key: ListingsTab.CLOSED.toString(),
  },
  {
    label: 'Deleted',
    key: ListingsTab.DELETED.toString(),
  },
  {
    label: 'External',
    key: ListingsTab.EXTERNAL_LISTINGS.toString(),
  },
];

export const columns = [
  {
    title: 'Title',
    dataIndex: 'label',
    key: 'label',
  },
  {
    title: 'Listing Id',
    dataIndex: 'listingId',
    key: 'listingId',
  },
  {
    title: 'Rental Id',
    dataIndex: 'rentalId',
    key: 'rentalId',
  },
  {
    title: 'User Id',
    dataIndex: 'userId',
    key: 'userId',
  },
  {
    title: 'Type',
    dataIndex: 'rentalType',
    key: 'rentalType',
  },
  {
    title: 'Rent',
    dataIndex: 'monthlyRent',
    key: 'monthlyRent',
    render: (value: number) => toUSD(value),
  },
  {
    title: 'Avail.',
    dataIndex: 'availableAt',
    key: 'availableAt',
    render: (value: string) => (value ? dateFieldFormat(value) : ''),
    sorter: (a, b) => {
      return a.availableAt < b.availableAt ? -1 : 1;
    },
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Approved',
    dataIndex: 'approvedAt',
    key: 'approvedAt',
    render: (value: string) => (value ? dateFieldFormat(value) : ''),
    sorter: (a, b) => (a.approvedAt < b.approvedAt ? -1 : 1),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Closed',
    dataIndex: 'closedAt',
    key: 'closedAt',
    render: (value: string) => (value ? dateFieldFormat(value) : ''),
    sorter: (a, b) => (a.closedAt < b.closedAt ? -1 : 1),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Deleted',
    dataIndex: 'deletedAt',
    key: 'deletedAt',
    render: (value: string) => (value ? dateFieldFormat(value) : ''),
    sorter: (a, b) => (a.deletedAt < b.deletedAt ? -1 : 1),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Media',
    dataIndex: 'media',
    key: 'media',
  },
  // {
  //   title: 'Video',
  //   dataIndex: 'videoUrls',
  //   key: 'videoUrls',
  //   render: (value) => value[0] ? value[0] : false
  // },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: (value: ListingAddressModel) =>
      `${value.address1} ${value.address2}`,
  },
  {
    title: 'City',
    dataIndex: 'address',
    key: 'city',
    render: (value: ListingAddressModel) => `${value.city}`,
    sorter: (a: ListingDetailsModel, b: ListingDetailsModel) =>
      a.address.city < b.address.city ? -1 : 1,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'State',
    dataIndex: 'address',
    key: 'state',
    render: (value: ListingAddressModel) => `${value.state}`,
  },
  {
    title: 'Zip',
    dataIndex: 'address',
    key: 'postal_code',
    render: (value: ListingAddressModel) => `${value.postalCode}`,
    sorter: (a: ListingDetailsModel, b: ListingDetailsModel) =>
      a.address.postalCode < b.address.postalCode ? -1 : 1,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Beds',
    dataIndex: 'numBedrooms',
    key: 'numBedrooms',
  },
  {
    title: 'Baths',
    dataIndex: 'numBathrooms',
    key: 'numBathrooms',
  },
  {
    title: 'Sqft',
    dataIndex: 'sqft',
    key: 'sqft',
  },
];

export const externalListingColumns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Listing Id',
    dataIndex: 'listingId',
    key: 'listingId',
  },
  {
    title: 'Rental Id',
    dataIndex: 'rentalId',
    key: 'rentalId',
  },
  {
    title: 'External Listing Id',
    dataIndex: 'externalListingId',
    key: 'externalListingId',
  },
  {
    title: 'External Rental Id',
    dataIndex: 'externalRentalId',
    key: 'externalRentalId',
  },
  {
    title: 'Source',
    dataIndex: 'externalSource',
    key: 'externalSource',
  },
  {
    title: 'Matterport',
    dataIndex: 'matterportUrl',
    key: 'matterportUrl',
  },
  {
    title: 'Video',
    dataIndex: 'video',
    key: 'video',
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value: string) => (value ? dateFieldFormat(value) : ''),
    sorter: (a, b) => (a.closedAt < b.closedAt ? -1 : 1),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Deleted',
    dataIndex: 'deletedAt',
    key: 'deletedAt',
    render: (value: string) => (value ? dateFieldFormat(value) : ''),
    sorter: (a, b) => (a.deletedAt < b.deletedAt ? -1 : 1),
    sortDirections: ['descend', 'ascend'],
  },
];

export const listingDetailsResponseToDataSource = (
  listingDetails: ListingDetailsModel[]
) => {
  return (
    listingDetails.map((listingDetails) => {
      const listingId = (
        <CopyDiv value={listingDetails.listingId}>
          <a href={`/approvals?listingId=${listingDetails.listingId}`}>
            {listingDetails.listingId.slice(0, 8)}
          </a>
        </CopyDiv>
      );
      const userId = (
        <a href={`/users/${listingDetails.userId}`}>
          {listingDetails.userId.slice(0, 8)}
        </a>
      );
      const copyRentalId = (
        <CopyDiv value={listingDetails.rentalId}>
          {listingDetails.rentalId.slice(0, 8)}
        </CopyDiv>
      );
      const media = `${listingDetails.imageUrls.length} | ${listingDetails.videoUrls.length}`;
      return {
        ...listingDetails,
        listingId,
        userId,
        rentalId: copyRentalId,
        media,
      };
    }) || []
  );
};

export const externalListingToDataSource = (
  externalListings: ExternalListingModel[],
  listingsWithVideoById: Map<string, ListingDetailsModel>
) => {
  return (
    externalListings.map((listing) => {
      const id = <CopyDiv value={listing.id}>{listing.id.slice(0, 8)}</CopyDiv>;
      const listingId = (
        <CopyDiv value={listing.listingId}>
          <a href={`/approvals?listingId=${listing.listingId}`}>
            {listing.listingId.slice(0, 8)}
          </a>
        </CopyDiv>
      );
      const rentalId = (
        <CopyDiv value={listing.rentalId}>
          {listing.rentalId.slice(0, 8)}
        </CopyDiv>
      );
      const matterportUrl = listing.matterportUrl ? (
        <a
          target="_blank"
          href={`${listing.matterportUrl}`}
        >{`${listing.matterportUrl}`}</a>
      ) : (
        ''
      );

      const video = listingsWithVideoById[listing.listingId] ? (
        <a href={listingsWithVideoById[listing.listingId]?.videoUrls[0]}>
          Preview video
        </a>
      ) : (
        ''
      );

      return {
        ...listing,
        id,
        listingId,
        rentalId,
        matterportUrl,
        video,
      };
    }) || []
  );
};
