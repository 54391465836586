import React from 'react';
import styled from '@emotion/styled';
import { useSideNavBar } from '../../hooks';
import { TOP_NAV_BAR_HEIGHT } from '../../lib/constants';

const AppPageWrapper = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-top: ${TOP_NAV_BAR_HEIGHT}px;
  }
`;

const App = styled.div<{ sideBarWidth: string }>`
  height: 100%;
  margin-bottom: 100px;

  @media ${({ theme }) => theme.mediaQuery.medium} {
    margin-left: ${(props) => props.sideBarWidth};
  }
`;

const AppPage = ({ children }) => {
  const { sideBarWidth } = useSideNavBar();

  return (
    <AppPageWrapper>
      <App sideBarWidth={sideBarWidth}>{children}</App>
    </AppPageWrapper>
  );
};

export default AppPage;
