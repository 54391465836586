import React from 'react';
import { useAuth } from './useAuth';
import { request, FetchOptions } from '../lib/api';
import {
  ListingMediaService,
  listingMediaServiceFactory,
  listingDetailsServiceFactory,
  ListingDetailsService,
  ListingService,
  listingServiceFactory,
  AmenityService,
  amenityServiceFactory,
  rentalServiceFactory,
  RentalService,
  userServiceFactory,
  UserService,
  externalListingServiceFactory,
  ExternalListingService,
  externalListingContactServiceFactory,
  ExternalListingContactService,
  discoverServiceFactory,
  DiscoverService,
  QrCodeService,
  qrCodeServiceFactory,
  MessagingService,
  messagingServiceFactory,
  TikTokService,
  tikTokServiceFactory,
} from '../lib/services/index';

export type apiClient = <T>(
  path: string,
  options?: FetchOptions | undefined
) => Promise<T>;

type Services = {
  apiClient: apiClient;
  listing: ListingService;
  listingDetails: ListingDetailsService;
  listingMedia: ListingMediaService;
  amenity: AmenityService;
  rental: RentalService;
  user: UserService;
  externalListing: ExternalListingService;
  externalListingContact: ExternalListingContactService;
  discover: DiscoverService;
  qrCode: QrCodeService;
  messaging: MessagingService;
  tiktok: TikTokService;
};

const ServicesContext = React.createContext<Services>({} as Services);

const ServicesProvider = (props) => {
  const { token, userId } = useAuth();
  const apiClient: apiClient = React.useCallback(
    function apiClient(path, options) {
      return request(path, options, { token, userId });
    },
    [token, userId]
  );

  const services: Services = React.useMemo(
    () => ({
      apiClient,
      listing: listingServiceFactory(apiClient),
      listingDetails: listingDetailsServiceFactory(apiClient),
      listingMedia: listingMediaServiceFactory(apiClient),
      amenity: amenityServiceFactory(apiClient),
      rental: rentalServiceFactory(apiClient),
      user: userServiceFactory(apiClient),
      externalListing: externalListingServiceFactory(apiClient),
      externalListingContact: externalListingContactServiceFactory(apiClient),
      discover: discoverServiceFactory(apiClient),
      qrCode: qrCodeServiceFactory(apiClient),
      messaging: messagingServiceFactory(apiClient),
      tiktok: tikTokServiceFactory(apiClient),
    }),
    [apiClient]
  );
  return <ServicesContext.Provider value={services} {...props} />;
};

const useServices = (serviceIdentifiers: Array<keyof Services>) => {
  const services = React.useContext(ServicesContext);
  return serviceIdentifiers.map((s) => services[s]);
};
const useService = <T extends keyof Services>(identifier: keyof Services) =>
  React.useContext(ServicesContext)[identifier] as Services[T];

const useApiClient = () => React.useContext(ServicesContext).apiClient;

export { ServicesProvider, useServices, useService, useApiClient };
