import { useTheme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Tooltip } from 'antd';
import React from 'react';
import { DefaultIcon } from '../../components/UI/Icon';
import { MapMarkerProps } from './RadiusLayer';

export type QrCodeMarkerProps = MapMarkerProps & {
  title: string;
};

const QrCodeMapMarker: React.FC<QrCodeMarkerProps> = ({ lat, lng, title }) => {
  const { color, colorMap } = useTheme();

  const titleNode = (
    <span>
      <a style={{ color: colorMap.neutral.white }} href="/qr-codes">
        {title}
      </a>
    </span>
  );

  return (
    <div
      key={title}
      style={{ position: 'absolute', transform: 'translate(-50% -50%)' }}
    >
      <Tooltip
        arrowPointAtCenter
        color={color.primaryGreen}
        placement="top"
        trigger={['click']}
        title={titleNode}
      >
        <IconRadius className={'icon'}>
          <IconWrapper>
            <DefaultIcon name="qr-code" />
          </IconWrapper>
        </IconRadius>
      </Tooltip>
    </div>
  );
};

export default QrCodeMapMarker;

const IconRadius = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(20, 99, 110, 0.24);
  border-radius: 50% !important;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.primaryGreen};
  color: ${({ theme }) => theme.colorMap.neutral.white};
`;
