import styled from '@emotion/styled';
import { useState } from 'react';
import {
  DEFAULT_VIDEO_HEIGHT,
  DEFAULT_VIDEO_WIDTH,
  MAX_VIDEO_WIDTH,
  MIN_VIDEO_HEIGHT,
  MIN_VIDEO_WIDTH,
} from './constants';
import { VideoListingDetailsModel } from '../../lib/models';

type VideoPreviewProps = {
  video: VideoListingDetailsModel;
  className?: string;
};

const playVideo = (id) => {
  const videoEl = document.getElementById(id) as HTMLVideoElement;
  if (videoEl) {
    videoEl.play();
  }
};

const pauseVideo = (id) => {
  const videoEl = document.getElementById(id) as HTMLVideoElement;
  if (videoEl) {
    videoEl.pause();
    videoEl.currentTime = 0;
  }
};

const VideoPreview: React.FC<VideoPreviewProps> = ({
  video,
  className = 'discover-video-container',
}) => {
  const videoId = `discover-video-${video.videoUrl}`;
  const [showVideo, setShowVideo] = useState<boolean>();

  return (
    <VideoContainer className={className}>
      <Poster
        onMouseOver={() => {
          setShowVideo(true);
          playVideo(videoId);
        }}
        onMouseLeave={() => {
          setShowVideo(false);
          pauseVideo(videoId);
        }}
        src={video.posterUrl}
      />
      <Video
        className="discover-video"
        visibility={showVideo ? 'visible' : 'hidden'}
        id={`${videoId}`}
        onLoadedMetadata={() => setShowVideo(true)}
        onMouseOver={() => playVideo(videoId)}
        onMouseLeave={() => pauseVideo(videoId)}
        poster={video.posterUrl}
        src={video.videoUrl}
        muted={true}
        playsInline={true}
        autoPlay={false}
      />
      <ListingDetails>
        {video.numBedrooms || 'Studio'} in {video.city}
      </ListingDetails>
    </VideoContainer>
  );
};

export default VideoPreview;

const VideoContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: ${MIN_VIDEO_HEIGHT}px;
  min-width: ${MIN_VIDEO_WIDTH}px;
  max-width: ${MAX_VIDEO_WIDTH}px;
  aspect-ratio: ${DEFAULT_VIDEO_WIDTH} / ${DEFAULT_VIDEO_HEIGHT};
`;

const Video = styled.video<{ visibility: string }>`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  visibility: ${(props) => props.visibility};
  cursor: pointer;
`;

const Poster = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  cursor: pointer;
`;

const ListingDetails = styled.div`
  color: ${({ theme }) => theme.colorMap.neutral.white};
  font-weight: 700;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  font-size: 1.15rem;
`;
