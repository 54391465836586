import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Form, FormInstance, Input, Spin } from 'antd';
import { Button } from '../../components/UI/Button';

export type CreateFormProps = {
  form: FormInstance;
  onFinish?: (values: any) => void;
  showButton?: boolean;
};

const CreateForm: React.FC<CreateFormProps> = ({
  form,
  onFinish,
  showButton = true,
}) => {
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  const onChange = async (change) => {
    try {
      await form.validateFields();
      setIsValidForm(true);
    } catch {
      // nothing
    }
  };

  return (
    <StyledForm form={form} onFinish={onFinish} onChange={onChange}>
      <Form.Item
        label="Code"
        name="code"
        rules={[{ required: true, message: 'Must enter code' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Redirect Url"
        name="redirectUrl"
        rules={[{ required: true, message: 'Must enter redirect url' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Location" name="location" rules={[{ required: false }]}>
        <Input />
      </Form.Item>
      {showButton && (
        <Form.Item>
          <StyledButton
            disabled={!isValidForm}
            key="listing-create"
            size="medium"
            variant="primary"
            color="primaryGreen"
          >
            Submit
          </StyledButton>
        </Form.Item>
      )}
    </StyledForm>
  );
};

export default CreateForm;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    display: flex;
    justify-content: flex-start;

    .ant-form-item-label {
      width: 100px;
      display: flex;
    }

    .ant-form-item-control {
      max-width: 400px;
    }
  }
`;
