import { useState, useEffect } from 'react';
import { useService } from './useServices';
import {
  ListingCreateBody,
  ListingDetailsModel,
  RentalAmenityBody,
  RentalRatingType,
  SelectOption,
} from '../lib/models';
import {
  addHours,
  daysBetween,
  stringsToSelectOptions,
  toUTC,
} from '../lib/helpers';
import { useForm } from 'react-hook-form';

type UseListingCreateForm = {
  amenityOptions;
  rentalTypeOptions;
  listingTypeOptions;
  defaultAmenities;
  isValid;
  handlers: {
    handleInputChange;
    handleMultiSelect;
    handleLeaseDurationDays;
    submitForm;
    setValue;
    getValues;
    register;
    trigger;
  };
};

const DEFAULT_AMENITY_KEYS = [
  'WASHER_DRYER',
  'DISHWASHER',
  'HEATING',
  'REFRIGERATOR',
  'FREEZER',
  'MICROWAVE',
  'OVEN',
];

const useListingCreateForm = (): UseListingCreateForm => {
  const listingService = useService<'listing'>('listing');
  const listingDetailsService = useService<'listingDetails'>('listingDetails');
  const amenityService = useService<'amenity'>('amenity');
  const rentalService = useService<'rental'>('rental');

  const [amenityOptions, setAmenityOptions] = useState<SelectOption[]>();
  const [listingTypeOptions, setListingTypeOptions] = useState<SelectOption[]>(
    []
  );
  const [rentalTypeOptions, setRentalTypeOptions] = useState<SelectOption[]>(
    []
  );

  const {
    register,
    setValue,
    getValues,
    trigger,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setValue(name, value);
  };

  const handleMultiSelect = (name, values) => {
    setValue(name, values);
  };

  const handleLeaseDurationDays = (
    dateString1: string,
    dateString2: string
  ) => {
    const date1Utc = toUTC(dateString1);
    const date2Utc = toUTC(dateString2);
    const days = daysBetween(new Date(date1Utc), new Date(date2Utc));
    setValue('leaseDurationDays', days);
  };

  const submitForm = async (): Promise<ListingDetailsModel> => {
    const values = getValues();
    const ratings = [
      { ratingType: RentalRatingType.LIGHT_LEVEL, rating: values.naturalLight },
      { ratingType: RentalRatingType.LANDLORD, rating: values.landlordRating },
      { ratingType: RentalRatingType.NOISE_LEVEL, rating: values.noiseLevel },
    ];

    const amenities: RentalAmenityBody[] = values.amenities.map((a) => {
      return { amenityKey: a };
    });

    const availableAtUTC = addHours(
      new Date(values.availableAt),
      12
    ).toISOString();

    const body: ListingCreateBody = {
      createdBy: values.createdBy,
      userId: values.userId,
      firstName: values.firstName,
      lastName: values.lastName,
      address: values.address,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      postalCode2: values.postalCode2,
      countryCode: values.countryCode,
      title: values.title,
      monthlyRent: values.monthlyRent,
      availableAt: availableAtUTC,
      rentalType: values.rentalType,
      listingType: values.listingType,
      numBathrooms: values.numBathrooms,
      numBedrooms: values.numBedrooms,
      numParkingSpots: values.numParkingSpots,
      sqft: values.sqft,
      amenities: amenities,
      ratings: ratings,
      description: values.description,
      leaseDurationDays: values.leaseDurationDays,
    };
    const listing = await listingService.createListing(body);
    return await listingDetailsService.byId(listing.id);
  };

  useEffect(() => {
    Promise.all([
      amenityService.amenityKeyOptions(),
      rentalService.rentalTypeOptions(),
      listingService.listingTypeOptions(),
    ])
      .then((all) => all.map((options) => stringsToSelectOptions(options)))
      .then(([amenityOptions, rentalTypes, listingTypes]) => {
        setAmenityOptions(amenityOptions);
        setRentalTypeOptions(rentalTypes);
        setListingTypeOptions(listingTypes);
      });
  }, [amenityService, rentalService, listingService]);

  return {
    amenityOptions,
    rentalTypeOptions,
    listingTypeOptions,
    defaultAmenities: stringsToSelectOptions(
      DEFAULT_AMENITY_KEYS.map((k) => k.toLowerCase())
    ),
    isValid,
    handlers: {
      handleInputChange,
      handleLeaseDurationDays,
      submitForm,
      handleMultiSelect,
      setValue,
      getValues,
      register,
      trigger,
    },
  };
};

export { useListingCreateForm };
