import AppPage from '../AppPage';
import { Card } from '../../components/UI/Card';
import { tabs, userDisplayName, UserTab } from './constants';
import { useState } from 'react';
import { useParams } from 'react-router';
import ListingsByUser from './ListingsByUser';
import { Tabs } from 'antd';
import { queryParamsParser } from '../../lib/helpers';
import Details from './Details';
import { useService } from '../../hooks';
import { useQuery } from 'react-query';
import { UserModel } from '../../lib/models';
import MessagesByUser from './MessagesByUser';
const { TabPane } = Tabs;

const User = (props) => {
  const queryParams = queryParamsParser(props.location?.search || '') as {
    tab: string;
  };

  const [activeTab, setActiveTab] = useState<string>(
    queryParams.tab || UserTab.DETAILS.toString()
  );
  const { userId } = useParams<{ userId?: string }>();
  const userService = useService<'user'>('user');

  const { data: user } = useQuery<{}, {}, UserModel>(
    [`user-by-id`, { userId }],
    () => userService.byId(userId || ''),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      staleTime: 0,
    }
  );

  const cardTitle = user ? userDisplayName(user) : '';

  return (
    <AppPage>
      <Card title={cardTitle}>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
        >
          {tabs.map((tab) => (
            <TabPane tab={tab.label} key={tab.key.toLowerCase()}>
              {tab.key === UserTab.DETAILS.toString() && user && (
                <Details user={user} />
              )}
              {tab.key === UserTab.LISTINGS.toString() && (
                <ListingsByUser userId={userId || ''} />
              )}
              {tab.key === UserTab.MESSAGES.toString() && (
                <MessagesByUser userId={userId || ''} />
              )}
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </AppPage>
  );
};

export default User;
